import { useNavigate } from 'react-router-dom';

import {
  AirOceanType,
  ConsolType,
  ENDPOINTS,
  OriginDestinationType,
  QUERY_KEYS
} from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';

import { useMutationEnhancer } from '../core';

interface ICDS {
  bookingClient: any;
  bookingClientTaxId: string;
  chargeCodeUnit: string;
  consolType: ConsolType;
  customDeclarationSheet: any;
  customOffice: string;
  eta: string;
  etd: string;
  id: number;
  mode: AirOceanType;
  oblNo: string;
  polCode: string;
  polName: string;
  podCode: string;
  podName: string;
  serviceContainers: any[];
  totalGrossWeight: number;
  totalPacks: number;
  transportServiceType: OriginDestinationType;
  vesselOrFlight: string;
}

interface ISubmitEcusDataParams {
  serviceId?: string | number;
  customDeclarationSheets: ICDS[];
}

export const useSubmitEcusData = () => {
  const navigate = useNavigate();

  return useMutationEnhancer<any, ISubmitEcusDataParams>({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: ENDPOINTS.service('import-ecus'),
        body: mParams,
        method: 'POST',
        showToast: true
      });

      return res.data;
    },
    invalidateQueryKeys: [[QUERY_KEYS.service]],
    onSuccess: (data, variables) => {
      UIUtils.popup.dismiss();
      if (!!data?.id && !variables?.serviceId) {
        navigate(`/admin/service/${data.id}`, { replace: true });
      }
    }
  });
};

// export interface ISubmitEcusDataWithServiceParams {
//   transportServiceType: OriginDestinationType;
//   isNewClient: boolean;
//   isTransport?: boolean;
//   isHandling?: boolean;
//   type: ConsolType;
//   mode: AirOceanType;
//   onboardDate?: string;
//   refClientBookingNo?: string;
//   deliveryMode: DeliveryMode | DeliveryModeAir;
//   contMode: ContainerMode;
//   oblNo: string;
//   oblReleasedDate?: string;
//   polCode: string;
//   polEtd: string;
//   polAtd?: string;
//   podCode: string;
//   podEta: string;
//   podAta?: string;
//   customOffice: string;
//   bookingClient: any;
//   customDeclarationSheets: ICDSParams[];
//   vessel?: string;
//   flight?: string;
// }

// export const useSubmitEcusDataWithService = () => {
//   const navigate = useNavigate();

//   return useMutationEnhancer<any, ISubmitEcusDataWithServiceParams>({
//     mutationFn: async mParams => {
//       const res = await APIManager.request({
//         url: ENDPOINTS.service('import-ecus'),
//         body: mParams,
//         method: 'POST',
//         showToast: true
//       });

//       return res.data;
//     },
//     onSuccess: data => {
//       UIUtils.popup.dismiss();
//       if (!!data?.id) {
//         navigate(`/admin/service/${data.id}`, { replace: true });
//       }
//     }
//   });
// };
