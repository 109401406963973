import { useMemo } from 'react';

import {
  AirBasicContainerMode,
  AirOceanType,
  ClientType,
  DeliveryMode,
  DeliveryModeAir,
  generateOptions,
  OceanBasicContainerMode,
  ConsolType
} from '@constants';
import trans from 'translation';

export const useAirOceanParams = (mode?: AirOceanType) => {
  let params: {
    isAir?: boolean;
    isOcean?: boolean;
    isInland?: boolean;
  } = {
    isAir: undefined,
    isOcean: undefined,
    isInland: undefined
  };
  switch (mode) {
    case AirOceanType.Air:
      params = {
        isAir: true,
        isOcean: undefined,
        isInland: undefined
      };
      break;
    case AirOceanType.Ocean:
      params = {
        isAir: undefined,
        isOcean: true,
        isInland: undefined
      };
      break;
    case AirOceanType.Inland:
      params = {
        isAir: undefined,
        isOcean: true,
        isInland: true
      };
      break;
  }
  return params;
};

export const useAirOceanUIParams = ({
  mode,
  screen
}: {
  mode: AirOceanType;
  screen: 'job-order' | 'service';
}) => {
  const options = useMemo(() => {
    const ocean = {
      key: 'shippingLine',
      contModeOptions: generateOptions(OceanBasicContainerMode),
      deliveryModeOptions: generateOptions(DeliveryMode),
      clientTypeCodes: [ClientType.ShippingLine],
      isOcean: true,
      expressHidden: true,
      title: {
        pol: 'port_of_loading',
        pod: 'port_of_discharge',
        oblNo: 'mbl_no',
        oblPaymentTerm: 'mbl_payment_term',
        oblReleasedDate: 'mbl_released_date',
        oblReleasedType: 'mbl_released_type',
        hblNo: 'hbl_no',
        hblPaymentTerm: 'hbl_payment_term',
        hblReleasedDate: 'hbl_released_date',
        hblReleasedType: 'hbl_released_type',
        shippingLineCode: 'shipping_line_code',
        shippingLineName: 'shipping_line_name'
      }
    };
    const type = generateOptions(ConsolType).slice(0, 2);
    let data: any = {};
    switch (mode) {
      case AirOceanType.Inland:
        data = { ...ocean, type: generateOptions(ConsolType) };
        break;
      case AirOceanType.Ocean:
        data = { ...ocean, type };
        break;
      case AirOceanType.Air:
        data = {
          key: 'airline',
          contModeOptions: generateOptions(AirBasicContainerMode),
          deliveryModeOptions: generateOptions(DeliveryModeAir),
          type,
          clientTypeCodes: [ClientType.Airline],
          isAir: true,
          expressHidden: false,
          title: {
            pol: 'airport_of_loading',
            pod: 'airport_of_discharge',
            oblNo: 'mawb_no',
            oblPaymentTerm: 'mawb_payment_term',
            oblReleasedDate: 'mawb_released_date',
            oblReleasedType: 'mawb_released_type',
            hblNo: 'hawb_no',
            hblPaymentTerm: 'hawb_payment_term',
            hblReleasedDate: 'hawb_released_date',
            hblReleasedType: 'hawb_released_type',
            shippingLineCode: 'airline_code',
            shippingLineName: 'airline_name'
          }
        };
        break;
    }

    return data;
  }, [mode, screen]);

  return {
    ...options,
    title: Object.keys(options.title).reduce((acc, key) => {
      acc[key] = trans((options.title as any)[key]);
      return acc;
    }, {} as any)
  };
};
