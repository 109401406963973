import { GrowthBook } from '@growthbook/growthbook-react';

export const growthBook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_ENV !== 'production',
  subscribeToChanges: true
  // trackingCallback: (experiment, result) => {
  //   // TODO: Use your real analytics tracking system
  //   console.log('Viewed Experiment', {
  //     experimentId: experiment.key,
  //     variationId: result.key
  //   });
  // }
});

export const GROWTHBOOK_KEYS = {
  VERSION: 'ff_version'
};
