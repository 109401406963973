import { SearchCondition } from '@dto';

import { OperandTypes, Operators } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

export const noActionColumns = () => [' ', trans('action'), trans('download')];

export const normalizeFilterData = (v: any[]): SearchCondition[] => {
  const result = v.map(i => {
    // const r = omit(i, ['min', 'max', 'minDate', 'maxDate']);
    let rData = [Operators.BETWEEN, Operators.IN].includes(i.operatorType)
      ? undefined
      : i.data;
    let rDatas = [Operators.BETWEEN, Operators.IN].includes(i.operatorType)
      ? [OperandTypes.DECIMAL, OperandTypes.INTEGER].includes(i.operandType)
        ? [i.min, i.max]
        : [
            OperandTypes.DATE,
            OperandTypes.DATETIME,
            OperandTypes.TIME
          ].includes(i.operandType)
        ? [i.minDate, i.maxDate]
        : i.data.split(',').map((d: string) => d.trim())
      : undefined;

    if (rData) {
      rData =
        i.operandType === OperandTypes.DATE
          ? Prototype.date.formatDB(rData)
          : i.operandType === OperandTypes.DATETIME
          ? Prototype.date.formatDB(rData) +
            (i.operatorType === Operators.GREATER_EQUAL
              ? 'T00:00:00.000Z'
              : 'T23:59:59.000Z')
          : i.operandType === OperandTypes.TIME
          ? Prototype.date.toMoment(rData)
          : i.operandType === OperandTypes.BOOLEAN
          ? rData === 'YES'
          : rData;
    }
    if (rDatas) {
      rDatas =
        i.operandType === OperandTypes.DATE
          ? rDatas.map((d: any) => {
              return Prototype.date.formatDB(d);
            })
          : i.operandType === OperandTypes.TIME
          ? rDatas.map((d: any) => {
              return d ? Prototype.date.toMoment(d) : null;
            })
          : i.operandType === OperandTypes.DATETIME
          ? rDatas.map((d: any, idx: number) => {
              return d && Prototype.date.formatDB(d)
                ? Prototype.date.formatDB(d) +
                    (idx === 0 ? 'T00:00:00.000Z' : 'T23:59:59.000Z')
                : null;
            })
          : rDatas;
    }
    return {
      ...i,
      data: rData,
      datas: rDatas,
      operandType: i?.enums ? 'STRING' : i.operandType
    };
  });

  return result;
};
