import Alert from '@mui/material/Alert';
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo, useState } from 'react';

import {
  AirOceanType,
  NotificationLogSourceType,
  NotificationStatus
} from '@constants';
import { Prototype } from 'core';
import { useCUDNotificationLog } from 'hooks/notification';
import trans from 'translation';
import { KChip, KColors, KContainer, KGrid, KLabel, KLoading } from 'uikit';

import './styles.css';

const PREFIX: Record<AirOceanType, string> = {
  [AirOceanType.Ocean]: 'ocean-freight',
  [AirOceanType.Air]: 'air-freight',
  [AirOceanType.Inland]: ''
};

const onGenPrefix = ({
  source,
  sourceId,
  content,
  extraData
}: {
  source: NotificationLogSourceType;
  sourceId: number;
  content: string;
  extraData?: {
    consolId: string | null;
    serviceId: string | null;
    shipmentId: string;
    freightMode: 'OCEAN' | 'AIR';
  };
}) => {
  if (content.includes('Re-Assigned')) {
    return '';
  }

  let path = '';
  const oceanOrAirFreight = PREFIX[extraData?.freightMode as AirOceanType];

  switch (source) {
    case NotificationLogSourceType.JOB_ORDER:
      path = 'sales/job-order';
      break;
    case NotificationLogSourceType.SYSTEM:
      path = 'help-support/case';
      break;
    case NotificationLogSourceType.SERVICE:
      path = 'service';
      break;
    case NotificationLogSourceType.SHIPMENT:
      path = `${oceanOrAirFreight}/shipment`;
      break;
    case NotificationLogSourceType.AP_INVOICE:
      const _pathAp = `${oceanOrAirFreight}/consolidation/${extraData?.consolId}`;
      if (!extraData?.shipmentId) {
        return `/admin/${_pathAp}`;
      }
      path = `${_pathAp}/shipment/${extraData?.shipmentId}/billing/ap`;
      break;
    case NotificationLogSourceType.AR_INVOICE:
      const _pathAr = `${oceanOrAirFreight}/consolidation/${extraData?.consolId}`;
      if (!extraData?.shipmentId) {
        return `/admin/${_pathAr}`;
      }
      path = `${_pathAr}/shipment/${extraData?.shipmentId}/billing/ar`;
      break;
    case NotificationLogSourceType.ACCOUNTING_SPEND_MONEY:
      path = 'accounting/banking/money/spend/from-wip';
      break;
  }
  return `/admin/${path}/${sourceId}`;
};

const ItemNotification = ({ item }: IItemProps) => {
  const [showAlert, setShowAlert] = useState(false);

  const { mutate, isLoading } = useCUDNotificationLog();

  const redirectURL = useMemo(() => {
    return onGenPrefix({
      source: item?.source,
      sourceId: item.sourceId,
      content: item.content,
      extraData: item?.extraData
    });
  }, [item.content, item?.extraData, item?.source, item.sourceId]);

  const timeAgo = Prototype.date.toLocal(item?.createdAt)?.fromNow();

  const onRedirect = useCallback(() => {
    window.open(redirectURL, '_self');
  }, [redirectURL]);

  const onItemPress = useCallback(() => {
    if (item.readStatus !== NotificationStatus.READ) {
      mutate(
        {
          type: 'read',
          params: {
            id: item?.id
          }
        },
        {
          onSuccess: onRedirect
        }
      );
    } else {
      onRedirect();
    }
  }, [item?.id, item.readStatus, mutate, onRedirect]);

  const onUnreadOne = useCallback(
    (e: any) => {
      e.stopPropagation();
      mutate({
        type: 'un-read',
        params: {
          ids: [item?.id]
        }
      });
    },
    [item?.id, mutate]
  );

  return (
    <KContainer.Touchable
      className="item-notification"
      br="3x"
      onPress={onItemPress}
    >
      {isLoading && <KLoading />}

      <KGrid.Container
        marginY={'0rem'}
        marginLeft={'0rem'}
        style={{
          padding: '10px'
        }}
      >
        <KGrid.Item
          xs={3}
          style={{
            padding: '0'
          }}
        >
          <KChip
            size="md"
            brC={KColors.warning.normal}
            background={KColors.opacity.warning['16']}
            label={trans(`${item?.source}`.toLowerCase())}
            width="100%"
            textAlign="center"
          />
        </KGrid.Item>

        <KGrid.Item
          xs={8.2}
          style={{
            paddingTop: '0'
          }}
        >
          <KLabel.Paragraph typo="TextLgMedium">
            {item?.subject}
          </KLabel.Paragraph>
          <KLabel.Paragraph
            marginV={'0.25rem'}
            dangerouslySetInnerHTML={{
              __html: item?.content.replace(/\n/g, '<br>')
            }}
          ></KLabel.Paragraph>
          <KLabel.Paragraph typo="TextXsNormal">
            {` sent by ${item?.sentUsername} at ${timeAgo}`}
          </KLabel.Paragraph>
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={item?.readStatus !== NotificationStatus.READ}
          >
            <KGrid.Item xs={0.8}>
              <KContainer.View
                height={'0.8rem'}
                width={'0.8rem'}
                br={'2x'}
                background={KColors.primary.normal}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If
            isTrue={item?.readStatus === NotificationStatus.READ}
          >
            <KGrid.Item xs={0.8}>
              <KContainer.Touchable
                height={'0.8rem'}
                width={'0.8rem'}
                br={'2x'}
                brW={1}
                brC={KColors.primary.normal}
                onMouseEnter={() => setShowAlert(true)}
                onMouseLeave={() => setShowAlert(false)}
                onPress={onUnreadOne}
              >
                {showAlert && (
                  <KContainer.View position="relative">
                    <Alert
                      icon={false}
                      sx={{
                        color: KColors.white,
                        background: KColors.gray.normal,
                        position: 'absolute',
                        top: '-10px', // Đặt vị trí của Alert (ví dụ: ngay dưới thẻ)
                        left: '-60px',
                        transform: 'translateX(-50%)',
                        width: '100px',
                        height: 'max-content',
                        paddingY: 0,
                        paddingX: '11px',
                        marginY: 0,
                        fontSize: '11px',
                        fontWeight: '400'
                      }}
                    >
                      {trans('mark_as_unread')}
                    </Alert>
                  </KContainer.View>
                )}
              </KContainer.Touchable>
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KGrid.Container>
    </KContainer.Touchable>
  );
};

export default memo(ItemNotification);
