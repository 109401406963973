/* eslint-disable max-lines */
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { generatePath } from 'react-router-dom';

import { Prototype } from 'core';
import { IGenerateParams } from 'hooks/report';

import {
  ArApType,
  SRMSource,
  InvoiceCategory,
  ServiceType,
  TTypeAdvice
} from './enum';

export const TIMEOUT = 10 * 60 * 1000; // 10 minutes

export const QUERY_KEYS = {
  user: 'queries.user',
  staffBranchList: 'queries.staffBranchList',
  staffRoleList: 'queries.staffRoleList',
  staffPermissionList: 'queries.staffPermissionList',
  resourceGroupList: 'queries.resourceGroupList',
  role: 'queries.role',
  staff: 'queries.staff',
  staffGlobal: 'queries.staffGlobal',
  staffPermissionsGroup: 'queries.staffPermissionsGroup',
  client: 'queries.client',
  newClientCondition: 'queries.newClientCondition',
  clientTypeList: 'queries.clientTypeList',
  ecusClientTypeList: 'queries.ecusClientTypeList',
  address: 'queries.address',
  addressCapability: 'queries.addressCapability',
  salePerson: 'queries.salePerson',
  addressCapabilityList: 'queries.addressCapabilityList',
  contact: 'queries.contact',
  arAp: 'queries.arAp',
  chargeCodeList: 'queries.chargeCodeList',
  chargeCodePayment: 'queries.chargeCodePayment',
  chargeCodeDetails: 'queries.chargeCodeDetails',
  relatedPartyList: 'queries.relatedPartyList',
  ecusRelatedPartyList: 'queries.ecusRelatedPartyList',

  consol: 'queries.consol',
  consolSummary: 'queries.consolSummary',
  consolEdoc: 'queries.consolEdoc',

  shipmentSummary: 'queries.shipmentSummary',
  shipment: 'queries.shipment',
  shipmentId: 'queries.shipmentId',
  shipmentEdoc: 'queries.shipmentEdoc',
  shipmentWithOutConsolEdoc: 'queries.shipmentWithOutConsolEdoc',
  shipmentServiceOriDes: 'queries.shipmentServiceOriDes',

  consolRouting: 'queries.consolRouting',
  shipmentRouting: 'queries.shipmentRouting',

  listShipmentContainer: 'queries.listShipmentContainer',
  shipmentContainer: 'queries.shipmentContainer',
  shipmentService: 'queries.shipmentService',

  listConsolContainer: 'queries.listConsolContainer',
  consolContainer: 'queries.consolContainer',

  containerPackingId: 'queries.containerPackingId',
  containerConsolDate: 'queries.containerConsolDate',
  containerConsolPenalty: 'queries.containerConsolPenalty',

  attachShipment: 'queries.attachShipment',

  branchSetting: 'queries.branchSetting',
  branchId: 'queries.branchId',
  companyProfile: 'queries.companyProfile',

  apInvoice: 'queries.apInvoice',
  arInvoice: 'queries.arInvoice',
  apInvoiceThreshold: 'queries.apInvoiceThreshold',
  calculateQuantity: 'queries.calculateQuantity',

  currencyUplift: 'queries.currencyUplift',

  listForTransport: 'queries.listForTransport',
  buyRateTransfer: 'queries.buyRateTransfer',

  billingArInvoice: 'queries.billingArInvoice',
  billingApInvoice: 'queries.billingApInvoice',
  accountingStatistics: 'queries.accountingStatistics',

  service: 'queries.service',
  serviceCustoms: 'queries.serviceCustoms',
  customsServiceDeclarationSheet: 'queries.custom-declaration-sheet',

  shipmentCheckThreshold: 'queries.shipmentCheckThreshold',
  consolCheckThreshold: 'queries.consolCheckThreshold',
  serviceCheckThreshold: 'queries.shipmentCheckThreshold',

  serviceContainerDate: 'queries.serviceContainerDate',
  serviceContainer: 'queries.serviceContainer',

  bankTransaction: 'queries.bankTransaction',
  selectedTransaction: 'queries.selectedTransaction',
  listAddSelectedTransaction: 'queries.listAddSelectedTransaction',
  matchedTransaction: 'queries.matchedTransaction',

  statementOfAccount: 'queries.statementOfAccount',
  statementOfAccountList: 'queries.statementOfAccountList',
  accountingAdvice: 'queries.accountingAdvice',
  accountingInvoiceSummary: 'queries.accountingInvoiceSummary',
  accountingInvoiceOverdueSummary: 'queries.accountingInvoiceOverdueSummary',

  calculateQuantityWithUnit: 'queries.calculateQuantityWithUnit',
  linkedChartOfAccount: 'queries.linkedChartOfAccount',
  accountingLinkedChartOfAccount: 'queries.accountingLinkedChartOfAccount',
  accountingChartOfAccount: 'queries.accountingChartOfAccount',

  accountingDueDateData: 'queries.accountingDueDateData',
  invoiceDueDateData: 'queries.invoiceDueDateData',
  apThreshold: 'queries.apThreshold',

  issue: 'queries.issue',
  wip: 'queries.wip',
  wipTemplate: 'queries.wipTemplate',
  spendBankingPayment: 'queries.spendBankingPayment',
  receiveBankingPayment: 'queries.receiveBankingPayment',
  invoiceWip: 'queries.invoiceWip',
  sellingWip: 'queries.sellingWip',
  costingWip: 'queries.costingWip',
  wipStatistics: 'queries.wipStatistics',
  costingRate: 'queries.costingRate',
  sellingRate: 'queries.sellingRate',
  chargeCodeProfitAndLoss: 'queries.chargeCodeProfitAndLoss',

  container: 'queries.container',

  invoiceAll: 'queries.invoiceAll',
  statementPayment: 'queries.statementPayment',
  invoiceAdvice: 'queries.invoiceAdvice',
  statementInvoice: 'queries.statementInvoice',
  spendReceiveMoney: 'queries.spendReceiveMoney',
  notificationLog: 'queries.notificationLog',
  taxType: 'queries.taxType',
  jobOrder: 'queries.jobOrder',
  jobOrderReview: 'queries.jobOrderReview',
  jobOrderPicRequest: 'queries.jobOrderPicRequest',
  reportImport: 'queries.reportImport',
  reportExcelExport: 'queries.reportExcelExport',
  issueResolve: 'queries.issueResolve',
  spendMoneyRequest: 'queries.spendMoneyRequest',
  spendReceiveMoneyFromWip: 'queries.spendReceiveMoneyFromWip',
  containerTypeSizeWip: 'queries.containerTypeSizeWip'
};

export const COMMON_HEADERS = {
  responseType: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Zone-Id': moment.tz.guess(true)
};

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const apiInstanceWithoutBase = axios.create({
  baseURL: '',
  headers: COMMON_HEADERS,
  timeout: TIMEOUT
});

export const updateAuthorizationToken = (token?: string) => {
  if (token) {
    apiInstance.defaults.headers.common.Authorization = 'Bearer ' + token;
  } else {
    apiInstance.defaults.headers.common.Authorization = '';
  }
};

export const resource = {
  resourceURL: process.env.REACT_APP_RESOURCE_URL
};

export const GLOBAL_ENDPOINT_PREFIX = {
  ACCOUNTING: 'global-accounting'
};

export const GLOBAL_ENDPOINT_SUFFIX = {
  GLOBAL: 'global'
};

const generateServiceEndpoint = (
  serviceName: string,
  path: string,
  query?: any
) => {
  const _path = path ? `/${serviceName}/${path}` : `/${serviceName}`;

  try {
    if (
      isEmpty(query) ||
      (_path.includes(':') &&
        Object.keys(query).every(i =>
          Prototype.core.isNullOrUndefined(query?.[i])
        ))
    ) {
      return _path.toLowerCase();
    }

    return generatePath(_path, query).toLowerCase();
  } catch (error) {
    return _path;
  }
};

const generateServiceEndpointWithPrefix =
  (prefix: string) => (serviceName: string, path: string, query?: any) =>
    generateServiceEndpoint(`${prefix}/${serviceName}`, path, query);

const generateServiceEndpointWithSuffix =
  (suffix: string) => (serviceName: string, path: string, query?: any) =>
    generateServiceEndpoint(`${serviceName}/${suffix}`, path, query);

export const ENDPOINTS = {
  advanceSearch: (path: string = '', query?: any) =>
    generateServiceEndpoint('advance-search', path, query),

  ecus: (path: string = '', query?: any) =>
    generateServiceEndpoint('ecus', path, query),

  branch: (path: string = '', query?: any) =>
    generateServiceEndpoint('branch', path, query),
  branchSetting: (path: string = '', query?: any) =>
    generateServiceEndpoint('branch-setting', path, query),
  branchContact: (path: string = '', query?: any) =>
    generateServiceEndpoint('branch-contact', path, query),
  companyProfile: (path: string = '', query?: any) =>
    generateServiceEndpoint('company-profile', path, query),
  companyBankAccount: (path: string = '', query?: any) =>
    generateServiceEndpointWithPrefix(GLOBAL_ENDPOINT_PREFIX.ACCOUNTING)(
      'company-bank-account',
      path,
      query
    ),
  notification: (path: string = '', query?: any) =>
    generateServiceEndpoint('notification-log', path, query),

  // LIBRARY
  chargeCode: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code', path, query),
  chargeCodeCategory: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-category', path, query),
  chargeCodeSubCategory: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-sub-category', path, query),
  chargeCodeUnit: (path: string = '', query?: any) =>
    generateServiceEndpoint('charge-code-unit', path, query),

  containerTypeSize: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-type-size', path, query),
  taxType: (path: string = '', query?: any) =>
    generateServiceEndpointWithPrefix(GLOBAL_ENDPOINT_PREFIX.ACCOUNTING)(
      'tax-type',
      path,
      query
    ),
  paymentMode: (path: string = '', query?: any) =>
    generateServiceEndpoint('payment-mode', path, query),
  addressCapability: (path: string = '', query?: any) =>
    generateServiceEndpoint('branch-address-capability', path, query),
  clientType: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-type', path, query),
  clientCategory: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-category', path, query),
  clientStage: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-stage', path, query),
  ecusClientType: (path: string = '', query?: any) =>
    generateServiceEndpoint('ecus-client-type', path, query),
  vessel: (path: string = '', query?: any) =>
    generateServiceEndpoint('vessel', path, query),
  incoterm: (path: string = '', query?: any) =>
    generateServiceEndpoint('incoterm', path, query),
  serviceLevel: (path: string = '', query?: any) =>
    generateServiceEndpoint('service-level', path, query),
  freightPayment: (path: string = '', query?: any) =>
    generateServiceEndpoint('freight-payment', path, query),
  penaltyType: (path: string = '', query?: any) =>
    generateServiceEndpoint('penalty-type', path, query),
  exchangeRate: (path: string = '', query?: any) =>
    generateServiceEndpoint('exchange-rate', path, query),
  shipmentType: (path: string = '', query?: any) =>
    generateServiceEndpoint('shipment-type', path, query),
  carrierPolicy: (path: string = '', query?: any) =>
    generateServiceEndpoint('carrier-policy', path, query),
  handlingType: (path: string = '', query?: any) =>
    generateServiceEndpoint('handling-type', path, query),
  deliveryMode: (path: string = '', query?: any) =>
    generateServiceEndpoint('delivery-mode', path, query),
  quotationTemplate: (
    url: 'invoice' | 'quotation',
    path: string = '',
    query?: any
  ) => generateServiceEndpoint(`${url}-template`, path, query),

  invoiceTemplate: (path: string = '', query?: any) =>
    generateServiceEndpoint('invoice-template', path, query),

  arInvoiceStatus: (path: string = '', query?: any) =>
    generateServiceEndpoint('ar-invoice-status', path, query),
  apInvoiceStatus: (path: string = '', query?: any) =>
    generateServiceEndpoint('ap-invoice-status', path, query),
  invoicePaymentMode: (path: string = '', query?: any) =>
    generateServiceEndpoint('invoice-payment-mode', path, query),
  taxExemptCode: (path: string = '', query?: any) =>
    generateServiceEndpoint('tax-exempt-code', path, query),
  termsOfConditions: (path: string = '', query?: any) =>
    generateServiceEndpoint('terms-of-conditions', path, query),
  jobTitle: (path: string = '', query?: any) =>
    generateServiceEndpoint('job-title', path, query),
  department: (path: string = '', query?: any) =>
    generateServiceEndpoint('department', path, query),
  packageType: (path: string = '', query?: any) =>
    generateServiceEndpoint('package-type', path, query),
  legRoutingType: (path: string = '', query?: any) =>
    generateServiceEndpoint('leg-routing-type', path, query),
  containerDangerousPackingGroups: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-dangerous-packing-groups', path, query),
  containerDangerousClass: (path: string = '', query?: any) =>
    generateServiceEndpoint('container-dangerous-class', path, query),
  airline: (path: string = '', query?: any) =>
    generateServiceEndpoint('airline', path, query),
  flight: (path: string = '', query?: any) =>
    generateServiceEndpoint('flight', path, query),
  billLadingReleasedType: (path: string = '', query?: any) =>
    generateServiceEndpoint('bl-released-type', path, query),
  notesToClient: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting-note', path, query),

  // METADATA
  country: (path: string = '', query?: any) =>
    generateServiceEndpoint('country', path, query),
  city: (path: string = '', query?: any) =>
    generateServiceEndpoint('city', path, query),
  state: (path: string = '', query?: any) =>
    generateServiceEndpoint('state', path, query),
  suburb: (path: string = '', query?: any) =>
    generateServiceEndpoint('suburb', path, query),
  unloco: (path: string = '', query?: any) =>
    generateServiceEndpoint('unloco', path, query),
  nationality: (path: string = '', query?: any) =>
    generateServiceEndpoint('nationality', path, query),
  language: (path: string = '', query?: any) =>
    generateServiceEndpoint('language', path, query),
  currency: (path: string = '', query?: any) =>
    generateServiceEndpoint('currency', path, query),
  timezone: (path: string = '', query?: any) =>
    generateServiceEndpoint('timezone', path, query),

  restrictedClient: (path: string = '', query?: any) =>
    generateServiceEndpoint('client', path, query),
  client: (path: string = '', query?: any) =>
    generateServiceEndpointWithSuffix(GLOBAL_ENDPOINT_SUFFIX.GLOBAL)(
      'client',
      path,
      query
    ),
  clientAddress: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-address', path, query),

  address: (path: string = '', query?: any) =>
    generateServiceEndpoint('client-address', path, query),

  // CONSOL
  consol: (path: string = '', query?: any) =>
    generateServiceEndpoint('consol', path, query),
  consolRouting: (path: string, query?: any) =>
    generateServiceEndpoint('consol/:consolId/routing', path, query),
  consolContainer: (path: string, query?: any) =>
    generateServiceEndpoint('consol/:consolId/container', path, query),

  // SHIPMENT
  shipment: (path: string = '', query?: any) =>
    generateServiceEndpoint('shipment', path, query),
  shipmentRouting: (path: string, query?: any) =>
    generateServiceEndpoint('shipment/:shipmentId/routing', path, query),
  shipmentContainer: (path: string, query?: any) =>
    generateServiceEndpoint('shipment/:shipmentId/container', path, query),
  shipmentService: (path: string, query?: any) =>
    generateServiceEndpoint('shipment/:shipmentId/service', path, query),

  container: (path: string, query?: any) =>
    generateServiceEndpoint('container', path, query),
  containerPenalty: (path: string, query?: any) =>
    generateServiceEndpoint('container-penalty', path, query),

  source: (path: string = '', query?: any) =>
    generateServiceEndpoint('source', path, query),

  note: (path: string = '', query?: any) =>
    generateServiceEndpoint(':source/:id/note', path, query),
  noteType: (path: string = '', query?: any) =>
    generateServiceEndpoint('note-type', path, query),

  edoc: (path: string = '', query?: any) =>
    generateServiceEndpoint(':source/:id/edoc', path, query),
  edocType: (path: string = '', query?: any) =>
    generateServiceEndpoint('edoc-type', path, query),

  log: (path: string = '', query?: any) =>
    generateServiceEndpoint('log', path, query),

  termsCondition: (path: string = '', query?: any) =>
    generateServiceEndpoint('terms-category', path, query),

  email: (path: string = '', query?: any) =>
    generateServiceEndpoint(':source/:id/email-log', path, query),
  template: (path: string = '', query?: any) =>
    generateServiceEndpoint('template', path, query),
  importTemplate: (path: string = '', query?: any) =>
    generateServiceEndpoint('import-template', path, query),

  alert: (path: string = '', query?: any) =>
    generateServiceEndpoint(':source/:id/alert', path, query),

  staff: (path: string = '', query?: any) =>
    generateServiceEndpoint('staff', path, query),
  globalStaff: (path: string = '', query?: any) =>
    generateServiceEndpointWithSuffix(GLOBAL_ENDPOINT_SUFFIX.GLOBAL)(
      'staff',
      path,
      query
    ),

  staffJobTitle: (path: string = '', query?: any) =>
    generateServiceEndpoint('staff-job-title', path, query),

  wip: (category: InvoiceCategory, path: string = '', query?: any) =>
    generateServiceEndpoint(`${category}/wip`, path, query),
  consolChargeCode: (
    category: InvoiceCategory,
    path: string = '',
    query?: any
  ) => generateServiceEndpoint(`${category}/consol-charge-code`, path, query),
  statistics: (category: InvoiceCategory, path: string = '', query?: any) =>
    generateServiceEndpoint(`${category}/statistics`, path, query),
  wipStatistics: (
    category: InvoiceCategory,
    path: string = '',
    query: {
      serviceType?: ServiceType;
      jobOrderId?: string;
    }
  ) => {
    let serviceName = `${category}`;
    if (query.serviceType) {
      serviceName = `${category}/:serviceType`;
    }
    if (query.jobOrderId) {
      serviceName = `${category}/:jobOrderId`;
    }

    return generateServiceEndpoint(`${serviceName}/wip-statistics`, path, {
      serviceType: query.serviceType?.toLocaleLowerCase(),
      jobOrderId: query.jobOrderId
    });
  },
  wipStatistics2:
    (category: InvoiceCategory, suffix: string) =>
    (path: string = '', query?: any) => {
      const serviceName = suffix ? `${category}/${suffix}` : `${category}`;

      return generateServiceEndpoint(
        `${serviceName}/wip-statistics`,
        path,
        query
      );
    },

  wipAble: (
    category: InvoiceCategory,
    path: string | '/calculate-quantity' = '',
    query: {
      screen: ArApType;
      serviceType?: ServiceType;
    }
  ) => {
    const { serviceType, screen } = query || {};
    const serviceName = serviceType
      ? `${category}/:serviceType`
      : `${category}`;
    return generateServiceEndpoint(
      `${serviceName}/${screen.toLocaleLowerCase()}-wip`,
      path,
      {
        serviceType: serviceType?.toLocaleLowerCase()
      }
    );
  },

  invoiceWip: (
    category: InvoiceCategory,
    type: ArApType,
    path: string = '',
    query?: any
  ) => {
    const url = `${category}/${type}-invoice/${type}-wip`;
    return generateServiceEndpoint(url.toLocaleLowerCase(), path, query);
  },

  wip2: (
    category: InvoiceCategory,
    arApType: ArApType,
    path: string = '',
    query?: any
  ) => generateServiceEndpoint(`${category}/${arApType}-wip`, path, query),
  serviceWip2: (
    {
      category,
      arApType
    }: {
      arApType: ArApType;
      category: InvoiceCategory;
    },
    path: string = '',
    query?: any
  ) => {
    const url = `${category}/:serviceType/${arApType}-wip`;
    return generateServiceEndpoint(url, path, query);
  },
  serviceCustomDeclarationSheet: (path: string = '', query?: any) => {
    return generateServiceEndpoint(
      'service/:serviceId/custom-declaration-sheet',
      path,
      query
    );
  },
  bankingPayment: ({
    source,
    path = '',
    query
  }: {
    source: SRMSource;
    path?: string;
    query?: any;
  }) => generateServiceEndpoint(`${source}-banking-payment`, path, query),
  // INVOICE
  arInvoice: (category: InvoiceCategory, path: string = '', query?: any) =>
    generateServiceEndpoint(`${category}/ar-invoice`, path, query),

  apInvoice: (category: InvoiceCategory, path: string = '', query?: any) =>
    generateServiceEndpoint(`${category}/ap-invoice`, path, query),

  arInvoice2: (path: string = '', query?: any) =>
    generateServiceEndpoint('ar-invoice', path, query),

  apInvoice2: (path: string = '', query?: any) =>
    generateServiceEndpoint('ap-invoice', path, query),

  arInvoiceDraftVat: (path: 'ar-invoice' | 'statement-invoice', query?: any) =>
    generateServiceEndpoint('invoice-draft-vat', path, query),

  // SERVICE
  service: (path: string = '', query?: any) =>
    generateServiceEndpoint('service', path, query),

  serviceCustoms: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/customs', path, query),

  serviceTransport: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/transport', path, query),

  serviceHandling: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/handling', path, query),

  serviceContainer: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/container', path, query),
  serviceTransportLinkContainer: (path: string = '', query?: any) =>
    generateServiceEndpoint(
      'transport-service/transport-link-container',
      path,
      query
    ),

  // HANDLING
  handlingService: (path: string = '', query?: any) =>
    generateServiceEndpoint('handling-service', path, query),
  serviceCarrierPolicy: (path: string = '', query?: any) =>
    generateServiceEndpoint(
      '/service/container/carrier-policy/service/',
      path,
      query
    ),

  calculateQuantityWip: (
    type: InvoiceCategory,
    arApType: ArApType,
    path: string = '',
    query?: any
  ) =>
    generateServiceEndpoint(
      `${type}/${arApType}-wip/calculate-quantity`,
      path,
      query
    ),

  // ACCOUNTING PAYMENT
  advice: (category: TTypeAdvice, path: string = '', query?: any) =>
    generateServiceEndpoint(category, path, query),

  paymentAdvice: (path: string = '', query?: any) =>
    generateServiceEndpoint('payment-advice', path, query),

  receivableAdvice: (path: string = '', query?: any) =>
    generateServiceEndpoint('receivable-advice', path, query),

  //ACCOUNTING

  bankTransaction: (path: string = '', query?: any) =>
    generateServiceEndpoint('bank-transaction', path, query),

  bankReconcile: (path: string = '', query?: any) =>
    generateServiceEndpoint('bank-reconcile', path, query),

  classification: (path: string = '', query?: any) =>
    generateServiceEndpointWithPrefix(GLOBAL_ENDPOINT_PREFIX.ACCOUNTING)(
      'chart-of-account-classification',
      path,
      query
    ),

  chartOfAccount: (path: string = '', query?: any) =>
    generateServiceEndpointWithPrefix(GLOBAL_ENDPOINT_PREFIX.ACCOUNTING)(
      'chart-of-account',
      path,
      query
    ),

  linkedChartOfAccount: (path: string = '', query?: any) =>
    generateServiceEndpointWithPrefix(GLOBAL_ENDPOINT_PREFIX.ACCOUNTING)(
      'linked-chart-of-account',
      path,
      query
    ),

  dashboardSummary: (path: string = '', query?: any) =>
    generateServiceEndpoint('dashboard', path, query),

  accounting: (
    {
      category,
      type
    }: {
      category: InvoiceCategory;
      type: 'ar' | 'ap' | ArApType;
    },
    path: string = '',
    query?: any
  ) => {
    return generateServiceEndpoint(
      `${category}/${type.toLocaleLowerCase()}-invoice`,
      path,
      query
    );
  },

  accountingArInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting/ar-invoice', path, query),
  accountingApInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting/ap-invoice', path, query),
  accountingWip: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting/wip', path, query),
  accountingChargeCodeList: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting/consol-charge-code', path, query),

  billingArInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('billing/ar-invoice', path, query),
  billingApInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('billing/ap-invoice', path, query),
  billingWip: (path: string = '', query?: any) =>
    generateServiceEndpoint('billing/wip', path, query),
  billingChargeCodeList: (path: string = '', query?: any) =>
    generateServiceEndpoint('billing/consol-charge-code', path, query),

  serviceArInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/ar-invoice', path, query),
  serviceApInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/ap-invoice', path, query),
  serviceWip: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/wip', path, query),
  serviceChargeCodeList: (path: string = '', query?: any) =>
    generateServiceEndpoint('service/consol-charge-code', path, query),

  systemSettingMisa: (path: string = '', query?: any) =>
    generateServiceEndpoint('misa/system-setting', path, query),
  syncMisa: (path: string = '', query?: any) =>
    generateServiceEndpoint('misa', path, query),

  // help and support
  issue: (path: string = '', query?: any) =>
    generateServiceEndpoint('issue', path, query),

  faq: (path: string = '', query?: any) =>
    generateServiceEndpoint('faq', path, query),

  // accounting
  accountingTaxCode: (path: string = '', query?: any) =>
    generateServiceEndpoint('tax-type', path, query),
  accountingChartOfAccount: (path: string = '', query?: any) =>
    generateServiceEndpoint('chart-of-account', path, query),
  accountingChartOfAccountClassification: (path: string = '', query?: any) =>
    generateServiceEndpoint('chart-of-account-classification', path, query),
  accountingCompanyBankAccount: (path: string = '', query?: any) =>
    generateServiceEndpoint('company-bank-account', path, query),
  accountingLinkedChartOfAccount: (path: string = '', query?: any) =>
    generateServiceEndpoint('linked-chart-of-account', path, query),

  // statement
  statementOfAccount: (path: string = '', query?: any) =>
    generateServiceEndpoint('statement-of-account', path, query),
  statementPayment: (path: string = '', query?: any) =>
    generateServiceEndpoint('statement-payment', path, query),
  statementInvoice: (path: string = '', query?: any) =>
    generateServiceEndpoint('statement-invoice', path, query),
  spendReceiveMoney: (path: string = '', query?: any) =>
    generateServiceEndpoint('spend-receive-money', path, query),

  // jobOrder
  jobOrder: (path: string = '', query?: any) =>
    generateServiceEndpoint('job-order', path, query),

  // report
  report: ({
    type,
    path = '',
    query
  }: {
    type: IGenerateParams['type'];
    path?: string;
    query?: any;
  }) => {
    let serviceName = '';
    let prefix = 'report';
    switch (type) {
      case 'import-monthly-volume':
      case 'export-monthly-volume':
        prefix = 'report/shipment';
        break;
      case 'transport':
      case 'custom':
        prefix = 'report/service';
        break;
      case 'aging':
        serviceName = path ? 'report/:type' : 'report/:type/generate-report';
        break;
    }

    serviceName = path ? `${prefix}/:type` : `${prefix}/:type/generate-report`;
    return generateServiceEndpoint(serviceName, path, {
      type,
      ...query
    });
  },

  accountingReport: (path: string = '', query?: any) =>
    generateServiceEndpoint('accounting/report', path, query),

  issueResolve: (path: string = '', query?: any) =>
    generateServiceEndpoint('issue-resolve', path, query),

  spendMoneyRequest: (path: string = '', query?: any) =>
    generateServiceEndpoint('spend-money-request', path, query),
  spendBankingPayment: (path: string = '', query?: any) =>
    generateServiceEndpoint('spend-banking-payment', path, query),
  receiveBankingPayment: (path: string = '', query?: any) =>
    generateServiceEndpoint('receive-banking-payment', path, query)
};
