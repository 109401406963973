/* eslint-disable max-lines */
import { isEmpty } from 'lodash';
import { useState } from 'react';

import {
  ArApType,
  DATE_FORMAT_SERVER,
  ENDPOINTS,
  InvoiceCategory,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  ServiceType,
  WIP_INCLUDE_FIELDS
} from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchInvoiceWip = (
  category: InvoiceCategory,
  arApType: ArApType,
  mParams: any
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.invoiceWip, category, arApType, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoiceWip(category, arApType),
        body: {
          ...mParams,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });
      return res.data?.data ?? [];
    },
    cacheTime: 0,
    enabled: !!category && !!arApType
  });
};

export const useMutateFetchInvoiceWip = () => {
  const [data, setData] = useState<any[]>([]);

  const mutation = useMutationEnhancer<any[], any>({
    mutationFn: async mParams => {
      const { category, arApType, ...rest } = mParams;

      const res = await APIManager.request({
        url: ENDPOINTS.invoiceWip(category, arApType),
        body: {
          includeFields: WIP_INCLUDE_FIELDS,
          ...rest
        }
      });

      return res.data?.data ?? [];
    },
    onSuccess: d => {
      if (d) {
        setData(d);
      }
    }
  });

  return { ...mutation, data };
};

export const useFetchSellingWip = (
  category: InvoiceCategory,
  mParams: {
    consolId?: string | number;
    shipmentId?: string | number;
    serviceId?: string | number;
    jobOrderId?: string | number;
    serviceType?: ServiceType;
  }
) => {
  const { serviceType, consolId, shipmentId, serviceId, jobOrderId } =
    mParams || {};

  const arApType = ArApType.Ar;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.sellingWip, category, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              '',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType),
        body: {
          consolId: shipmentId ? undefined : consolId,
          shipmentId,
          serviceId,
          jobOrderId,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i,
        taxType: i.taxType ? { ...i.taxType, rate: i.taxRate ?? 0 } : undefined
      }));
    },
    cacheTime: 0,
    enabled: !!category
  });
};

export const useFetchCostingWip = (
  category: InvoiceCategory,
  bodyParams: {
    consolId?: string | number;
    shipmentId?: string | number;
    serviceId?: string | number;
    jobOrderId?: string | number;
    serviceType?: ServiceType;
  }
) => {
  const { serviceType, consolId, shipmentId, serviceId, jobOrderId } =
    bodyParams || {};

  const arApType = ArApType.Ap;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.costingWip, category, bodyParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              '',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType),
        body: {
          consolId: shipmentId ? undefined : consolId,
          shipmentId,
          serviceId,
          jobOrderId,
          includeFields: WIP_INCLUDE_FIELDS
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i,
        taxType: i.taxType ? { ...i.taxType, rate: i.taxRate ?? 0 } : undefined
      }));
    },
    cacheTime: 0,
    enabled: !!category
  });
};

export const useCUDSellingWip = ({
  category,
  serviceType
}: {
  category: InvoiceCategory;
  serviceType?: ServiceType;
}) => {
  return useCUDMutationEnhancer({
    mutationKeys: [
      [QUERY_KEYS.wipStatistics],
      [QUERY_KEYS.sellingWip],
      [QUERY_KEYS.costingWip]
    ],
    endPoint: serviceType
      ? ENDPOINTS.serviceWip2(
          {
            arApType: ArApType.Ar,
            category
          },
          '',
          {
            serviceType: serviceType.toLowerCase()
          }
        )
      : ENDPOINTS.wip2(category, ArApType.Ar)
  });
};

export const useCUDCostingWip = ({
  category,
  serviceType
}: {
  category: InvoiceCategory;
  serviceType?: ServiceType;
}) => {
  return useCUDMutationEnhancer({
    mutationKeys: [
      [QUERY_KEYS.wipStatistics],
      [QUERY_KEYS.costingWip],
      [QUERY_KEYS.sellingWip]
    ],
    endPoint: serviceType
      ? ENDPOINTS.serviceWip2(
          {
            arApType: ArApType.Ap,
            category
          },
          '',
          {
            serviceType: serviceType.toLowerCase()
          }
        )
      : ENDPOINTS.wip2(category, ArApType.Ap)
  });
};

interface ICalculateQuantityWipInitParams {
  category: InvoiceCategory;
  serviceType?: ServiceType;
  consolId?: number | string;
  shipmentId?: number | string;
  serviceId?: number | string;
}

interface ICalculateQuantityWipParams {
  chargeCodeUnitCode: string;
}

export interface ICalculateQuantityWipResponse {
  quantity: number | null;
}

export const useCalculateQuantityWip = ({
  category,
  serviceType,
  arApType,
  ...rest
}: ICalculateQuantityWipInitParams & { arApType: ArApType }) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType,
                category
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, arApType, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

export const useCalculateQuantitySellingWip = ({
  category,
  serviceType,
  ...rest
}: ICalculateQuantityWipInitParams) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                arApType: ArApType.Ar,
                category
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, ArApType.Ar, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

export const useCalculateQuantityCostingWip = ({
  category,
  serviceType,
  ...rest
}: ICalculateQuantityWipInitParams) => {
  return useMutationEnhancer<
    ICalculateQuantityWipResponse,
    ICalculateQuantityWipParams
  >({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: serviceType
          ? ENDPOINTS.serviceWip2(
              {
                category,
                arApType: ArApType.Ap
              },
              'calculate-quantity',
              {
                serviceType: serviceType.toLowerCase()
              }
            )
          : ENDPOINTS.wip2(category, ArApType.Ap, 'calculate-quantity'),
        body: {
          ...mParams,
          ...rest
        }
      });

      return res.data;
    }
  });
};

interface IWipTemplateParams {
  consolId?: string | number;
  shipmentId?: string | number;
  serviceId?: string | number;
}

export const useFetchWipTemplate = (
  arApType: ArApType,
  mParams: IWipTemplateParams
) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.wipTemplate, arApType, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.quotationTemplate('invoice'),
        body: {
          includeFields: ['chargeCodes'],
          size: PAGE_SIZE_DEFAULT_MAX,
          ...mParams,
          isSellingTemplate: arApType === ArApType.Ar
        }
      });

      return res.data?.data ?? [];
    }
  });
};

interface IUseFetchSpendReceiveMoneyFromWipListParams {
  isSpendMoneyRequest?: boolean;
  isSpendMoney?: boolean;
  mParams?: any;
}

export const useFetchSpendReceiveMoneyFromWipList = ({
  isSpendMoneyRequest,
  isSpendMoney,
  mParams
}: IUseFetchSpendReceiveMoneyFromWipListParams) => {
  const apiURL = () => {
    if (isSpendMoneyRequest) {
      return ENDPOINTS.spendMoneyRequest('ap-wip');
    } else if (isSpendMoney) {
      return ENDPOINTS.spendBankingPayment('ap-wip');
    } else {
      return ENDPOINTS.receiveBankingPayment('ar-wip');
    }
  };

  const isSpend = !!(isSpendMoneyRequest || isSpendMoney);

  const { clientId, ...rest } = mParams;

  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.spendReceiveMoneyFromWip,
      isSpendMoneyRequest,
      isSpendMoney,
      mParams
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: apiURL(),
        body: {
          includeFields: [
            'chargeCode',
            'apClient',
            'arClient',
            'addrFrom',
            'addrTo',
            'taxType',
            'containerTypeSize',
            'chargeCodeUnit',
            'onBehalfClient',
            'currency'
          ],
          apClientId: isSpend ? clientId : undefined,
          arClientId: isSpend ? undefined : clientId,
          size: PAGE_SIZE_DEFAULT_MAX,
          ...rest
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!clientId
  });
};

interface IUseSubmitSpendReceiveMoneyWipDataParams {
  data: any[];
  currencyId: number;
  isAr?: boolean;
  isVND?: boolean;
}

export const useSubmitSpendReceiveMoneyWipData = () => {
  const normalizeData = async (d: any, currencyId: number) => {
    const { currency } = d;

    const isMatched = currencyId === currency?.id;

    if (!currency?.id || isMatched) {
      return 1;
    } else {
      const res = await APIManager.request({
        url: ENDPOINTS.exchangeRate('buy-rate-transfer'),
        body: {
          fromCurrencyId: currency?.id,
          toCurrencyId: currencyId,
          createdDate: Prototype.date.now().format(DATE_FORMAT_SERVER)
        },
        showError: false
      });

      return res.data?.buyRateTransfer ?? 1;
    }
  };

  return useMutationEnhancer<any[], IUseSubmitSpendReceiveMoneyWipDataParams>({
    mutationFn: async mParams => {
      const { data, currencyId, isAr, isVND } = mParams;

      const mData = data.map(async i => {
        const {
          chargeCode,
          id,
          arClient,
          apClient,
          arClientId,
          apClientId,
          ...rest
        } = i;

        const exRate = await normalizeData(i, currencyId);

        const subTotal = Prototype.number.roundVND(isVND)(
          Number(i.estSubTotal) * Number(exRate) || 0
        );
        const taxAmount = Prototype.number.roundVND(isVND)(
          Number(i.taxAmount) * Number(exRate) || 0
        );
        const total = Prototype.number.roundVND(isVND)(subTotal + taxAmount);

        return {
          ...rest,
          wipId: id,
          chargeCode,
          chartOfAccountId: isAr
            ? chargeCode.revenueAccountId
            : chargeCode.costAccountId,
          chartOfAccount: isAr
            ? chargeCode.revenueAccount
            : chargeCode.costAccount,
          clientId: isAr ? arClientId : apClientId,
          client: isAr ? arClient : apClient,
          exchangeRate: exRate,
          subTotal: i.estSubTotal,
          paymentSubTotal: subTotal,
          paymentTaxAmount: taxAmount,
          paymentTotalAmount: total
        };
      });

      return await Promise.all(mData);
    }
  });
};

interface IUseFetchInvoiceFromWipListParams {
  isAr?: boolean;
  mParams?: any;
}

export const useFetchInvoiceFromWipList = ({
  isAr,
  mParams
}: IUseFetchInvoiceFromWipListParams) => {
  const category = !!mParams.serviceId
    ? InvoiceCategory.Service
    : !!mParams.shipmentId
    ? InvoiceCategory.Billing
    : InvoiceCategory.Accounting;
  const arApType = isAr ? ArApType.Ar : ArApType.Ap;

  const { clientId, ...rest } = mParams;

  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.spendReceiveMoneyFromWip, isAr, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.invoiceWip(category, arApType),
        body: {
          includeFields: [
            'chargeCode',
            'apClient',
            'arClient',
            'addrFrom',
            'addrTo',
            'taxType',
            'containerTypeSize',
            'chargeCodeUnit',
            'onBehalfClient',
            'currency'
          ],
          apClientId: !isAr ? clientId : undefined,
          arClientId: !isAr ? undefined : clientId,
          size: PAGE_SIZE_DEFAULT_MAX,
          ...rest
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!clientId
  });
};

interface IUseSubmitInvoiceWipDataParams {
  data: any[];
  currencyId: number;
  isAr?: boolean;
  exRateDate?: any;
  isVND?: boolean;
}

export const useSubmitInvoiceWipData = () => {
  const normalizeData = async (
    d: any,
    currencyId: number,
    exRateDate?: any
  ) => {
    const { currency } = d;

    const isMatched = currencyId === currency?.id;

    if (!currency?.id || isMatched) {
      return 1;
    } else {
      const res = await APIManager.request({
        url: ENDPOINTS.exchangeRate('buy-rate-transfer'),
        body: {
          fromCurrencyId: currency?.id,
          toCurrencyId: currencyId,
          createdDate: Prototype.date.formatDB(exRateDate)
        },
        showError: false
      });

      return res.data?.buyRateTransfer ?? 1;
    }
  };

  return useMutationEnhancer<any[], IUseSubmitInvoiceWipDataParams>({
    mutationFn: async mParams => {
      const { data, currencyId, isAr, exRateDate, isVND } = mParams;

      const mData = data.map(async i => {
        const { chargeCode, id, ...rest } = i;

        const exRate = await normalizeData(i, currencyId, exRateDate);

        const subTotal = Prototype.number.roundVND(isVND)(
          Number(i.estSubTotal) * Number(exRate) || 0
        );
        const taxAmount = Prototype.number.roundVND(isVND)(
          Number(i.taxAmount) * Number(exRate) || 0
        );
        const total = Prototype.number.roundVND(isVND)(subTotal + taxAmount);

        return {
          ...rest,
          chargeCode,
          // WipId
          arWipId: isAr ? id : undefined,
          apWipId: !isAr ? id : undefined,
          // ChartOfAccount
          revenueAccountId: isAr ? chargeCode.revenueAccountId : undefined,
          revenueAccount: isAr ? chargeCode.revenueAccount : undefined,
          costAccountId: isAr ? undefined : chargeCode.costAccountId,
          costAccount: isAr ? undefined : chargeCode.costAccount,
          // From - To
          arChargeCodeFromId: isAr ? i.addrFromId : undefined,
          arChargeCodeFrom: isAr ? i.addrFrom : undefined,
          arChargeCodeToId: isAr ? i.addrToId : undefined,
          arChargeCodeTo: isAr ? i.addrTo : undefined,
          apChargeCodeFromId: !isAr ? i.addrFromId : undefined,
          apChargeCodeFrom: !isAr ? i.addrFrom : undefined,
          apChargeCodeToId: !isAr ? i.addrToId : undefined,
          apChargeCodeTo: !isAr ? i.addrTo : undefined,
          // Amount
          exchangeRate: exRate,
          chargeableExchangeRate: exRate,
          subTotal: i.estSubTotal,
          paymentSubTotal: subTotal,
          paymentTaxAmount: taxAmount,
          paymentTotalAmount: total
        };
      });

      return await Promise.all(mData);
    }
  });
};

interface IOtherParams {
  id?: string | number;
  consolId?: string | number;
  shipmentId?: string | number;
  serviceId?: string | number;
  jobOrderId?: string | number;
  serviceType?: ServiceType;
  currencyId?: number;
}

export interface IUseFetchWipStatisticsParams {
  category: InvoiceCategory;
  otherParams: IOtherParams;
}

interface IUseFetchWipStatisticsResponse {
  dataWithTax: any;
  dataWithoutTax: any;
}

export const useFetchWipStatistics2 = ({
  category,
  otherParams
}: IUseFetchWipStatisticsParams) => {
  const { id, currencyId, serviceType, ...rest } = otherParams;

  return useQueryEnhancer<IUseFetchWipStatisticsResponse>({
    queryKey: [QUERY_KEYS.wipStatistics, id, currencyId, serviceType],
    queryFn: async () => {
      const suffix = serviceType
        ? `${serviceType}`
        : rest.jobOrderId
        ? `${id}`
        : '';
      const res = await APIManager.request({
        url: ENDPOINTS.wipStatistics2(category, suffix)(),
        body: {
          ...rest,
          secondCurrencyId: currencyId
        }
      });

      if (!isEmpty(res.data)) {
        return {
          dataWithTax: {
            primary: res.data?.primaryWithTax,
            secondary: res.data?.secondaryWithTax
          },
          dataWithoutTax: {
            primary: res.data?.primary,
            secondary: res.data?.secondary
          }
        };
      }

      return res.data;
    },
    enabled: !!id
  });
};
