import './App.css';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import loadable from '@loadable/component';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { compact } from 'lodash';
import pMinDelay from 'p-min-delay';
import React, { Suspense, memo, useMemo } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

import { growthBook } from '@constants';
import theme from 'assets/theme';
import OceanFreightProvider from 'context/OceanFreight';
import { useUser } from 'hooks';
import { setupAdminRoutes } from 'hooks/core/helpers';
import { useSyncGrowthbook } from 'hooks/core/useSync';
import Admin from 'layouts/Admin';
import Auth from 'pages/Auth';
import adminRoutes from 'routes';
import { useMount } from 'uikit-common';
import { GlobalDebug, overrideConsole } from 'utils/removeConsoles';

import NotificationManager from './notification';

const SignIn = loadable(() => pMinDelay(import('pages/Auth/SignIn'), 200));
const ForgotPassword = loadable(() =>
  pMinDelay(import('pages/Auth/ForgotPassword'), 200)
);
const ResetPassword = loadable(() =>
  pMinDelay(import('pages/Auth/ResetPassword'), 200)
);

const ResolveIssue = loadable(() =>
  pMinDelay(import('pages/Auth/ResolveIssue'), 200)
);

const Profile = loadable(() => pMinDelay(import('pages/Profile'), 200));
const Company = loadable(() => pMinDelay(import('pages/Company'), 200));
// const NotFound = loadable(() => pMinDelay(import('pages/404'), 200));

const App = () => {
  const userData = useUser();

  const { user, isAdmin, isBranchAdmin } = userData || {};

  useSyncGrowthbook();

  useMount(() => {
    // Init OneSignal
    const notification = new NotificationManager(user);
    notification.initialize();

    overrideConsole();

    if (process.env.REACT_APP_ENV === 'production') {
      GlobalDebug(false);
    }
  });

  const router = useMemo(() => {
    const d = setupAdminRoutes(adminRoutes, userData);

    return createBrowserRouter([
      {
        path: 'admin',
        element: <Admin />,
        children: compact([
          ...d,
          { path: 'profile', element: <Profile /> },
          isAdmin || isBranchAdmin
            ? { path: 'company', element: <Company /> }
            : undefined
        ])
      },
      { path: '/', element: <Navigate to="admin" /> },
      {
        path: 'auth',
        element: <Auth />,
        children: [
          {
            path: 'sign-in',
            element: <SignIn />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPassword />
          },
          {
            path: 'resolve-issue',
            element: <ResolveIssue />
          }
        ]
      },
      { path: '*', element: <Navigate to="admin" /> }
    ]);
  }, [isAdmin, isBranchAdmin, userData]);

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <OceanFreightProvider>
          <Suspense fallback={null}>
            {/* {routes} */}
            <RouterProvider router={router} />
          </Suspense>
        </OceanFreightProvider>
      </ThemeProvider>
    </GrowthBookProvider>
  );
};

export default memo(App);
