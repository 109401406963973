import React, { memo } from 'react';

import KText from './Text';

import { KColors } from '../../constants';
import KView from '../Container/View';
import KImage from '../Image';
import { KTextProps } from '../types';

interface IProps extends KTextProps {
  withIcon?: boolean;
  colorIcon?: any;
  alignItemsWrap?:
    | true
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline';
}

const KError = (props: IProps) => {
  const {
    withIcon,
    alignItemsWrap = 'flex-end',
    colorIcon = KColors.secondary.normal,
    ...rest
  } = props;

  if (withIcon) {
    return (
      <KView row alignItems={alignItemsWrap}>
        <KImage.MuiIcon icon="WarningAmber" color={colorIcon} size={20} />

        <KText
          typo="TextNmNormal"
          paddingH="0.5rem"
          italic
          color={KColors.secondary.normal}
          {...rest}
        />
      </KView>
    );
  }

  return (
    <KText typo="TextXsNormal" color={KColors.secondary.normal} {...rest} />
  );
};

KError.displayName = 'KParagraph';

export default memo(KError);
