import { set, filter, includes } from 'lodash';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  AdviceStatus,
  AdviceType,
  ARInvoiceStatus,
  ENDPOINTS,
  INVOICE_INCLUDE_FIELDS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';
import { kind } from 'pages/Accounting/Advice/helpers';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDAdvice = (adviceType: AdviceType, method?: 'DELETE') => {
  const isPayment = adviceType === AdviceType.Payment;

  return usePageCUDMutationEnhancer<any>({
    endPoint: isPayment
      ? ENDPOINTS.paymentAdvice()
      : ENDPOINTS.receivableAdvice(),
    webTable: isPayment
      ? WebTable.ACCOUNTING_PAYMENT_ADVICE
      : WebTable.ACCOUNTING_RECEIVABLE_ADVICE,
    mutationKeys: method ? [] : [[QUERY_KEYS.accountingAdvice]],
    queryKey: QUERY_KEYS.accountingAdvice
  });
};

export const useSaveAndCompleteAdvice = (adviceType: AdviceType) => {
  const navigate = useNavigate();
  const fields = kind[adviceType];
  const endPoint = fields.endPoint;

  return useMutationEnhancer<any, object>({
    mutationFn: async params => {
      let res = null;
      const resCreate = await APIManager.request({
        url: endPoint,
        method: 'POST',
        body: params
      });
      res = resCreate.data;
      res[fields.status] = AdviceStatus.Complete;

      const resUpdate = await APIManager.request({
        url: generatePath(endPoint + '/:id', res as any),
        method: 'PUT',
        showToast: true,
        body: res
      });
      return resUpdate.data ?? res;
    },
    invalidateQueryKeys: [[QUERY_KEYS.accountingAdvice]],
    onSuccess(_data) {
      if (_data?.id) {
        navigate(`../${_data.id}`, { replace: true });
      }
    }
  });
};

export const useFetchAdvice = (
  adviceType: AdviceType,
  id?: string | number
) => {
  const isPayment = adviceType === AdviceType.Payment;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.accountingAdvice, adviceType, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment
          ? ENDPOINTS.paymentAdvice(':id', { id })
          : ENDPOINTS.receivableAdvice(':id', { id })
      });

      if (res.data) {
        const { arInvoiceIds, apInvoiceIds, arChargeCodeIds, apChargeCodeIds } =
          res.data;
        const invoiceIds = (arInvoiceIds || apInvoiceIds) ?? [];
        const chargeCodeIds = (arChargeCodeIds || apChargeCodeIds) ?? [];

        let adviceDetails = [];
        let adviceDetailsClientSelected = [];

        if (invoiceIds.length > 0) {
          const adviceRes = await APIManager.request({
            url: isPayment
              ? ENDPOINTS.paymentAdvice('ap-charge-code')
              : ENDPOINTS.receivableAdvice('ar-charge-code'),
            body: {
              apInvoiceIds: isPayment ? invoiceIds : undefined,
              arInvoiceIds: isPayment ? undefined : invoiceIds
            }
          });

          adviceDetails = adviceRes.data?.data ?? [];
          adviceDetailsClientSelected = filter(adviceDetails, item => {
            return includes(chargeCodeIds, item.id);
          });
        }
        return {
          ...res.data,
          adviceDetails,
          adviceDetailsClientSelected
        };
      }
      return undefined;
    },
    enabled: !!id
  });
};

interface IOtherParams {
  clientId?: number;
  paymentCurrencyId?: number;
}

export const useFetchAdviceInvoiceList = (
  adviceType: AdviceType,
  mParams?: IOtherParams
) => {
  const isPayment = adviceType === AdviceType.Payment;

  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.invoiceAdvice, adviceType, JSON.stringify(mParams)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment ? ENDPOINTS.apInvoice2() : ENDPOINTS.arInvoice2(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: INVOICE_INCLUDE_FIELDS,
          isAdviceCreating: true,
          clientId: mParams?.clientId,
          paymentCurrencyId: mParams?.paymentCurrencyId
        }
      });

      return (res.data?.data ?? []).map((i: any) => {
        const invoiceStatus = {
          ...(isPayment ? i.apInvoiceStatus : i.arInvoiceStatus)
        };

        const now = Prototype.date.now().startOf('day');
        const dueDate = Prototype.date
          .toMoment(i.dueDate, 'YYYY-MM-DD')
          ?.startOf('day');

        const isOverdue =
          dueDate?.isBefore(now) &&
          invoiceStatus?.code !== ARInvoiceStatus.COMPLETE;

        if (isOverdue) {
          set(invoiceStatus, 'name', 'OVERDUE');
        }

        return {
          ...i,
          checked: false,
          amount: 0,
          invoiceStatus
        };
      });
    }
  });
};
