import React, { memo, useMemo, useState } from 'react';

import { Status, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import {
  useFetchChargeCodeQuotation,
  useGlobalTable,
  useLocalTable,
  useNewButton
} from 'hooks';
import trans from 'translation';
import { KColors, KContainer, KLabel } from 'uikit';
import { TableUtils } from 'utils';

import { UNIT_SECTION } from '../../Accounting/ChargeCode/helpers';

export interface IBodyField {
  status: Status.Active;
  isAir?: boolean;
  isOcean?: boolean;
  isImport?: boolean;
  isExport?: boolean;
  isDomestic?: boolean;
  excludeIds: number[];
  includeFields?: string[];
}

interface IProps {
  bodyField: IBodyField;
  onAddChargeCode: (v: any[]) => void;
}

const ChargeCodeList = ({ bodyField, onAddChargeCode }: IProps) => {
  const { data: dataChargeCode } = useFetchChargeCodeQuotation(bodyField);

  const [indexes, setIndexes] = useState<number[]>([]);

  const columns = useMemo(() => {
    return [
      {
        label: trans('charge_code'),
        name: 'code'
      },
      {
        label: trans('name'),
        name: 'name'
      },
      {
        label: trans('description'),
        name: 'description',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('category'),
        name: 'chargeCodeSubCategory.chargeCodeCategory.code'
      },
      {
        label: trans('sub_category'),
        name: 'chargeCodeSubCategory.code'
      },
      {
        label: trans('ocean'),
        name: 'isOcean',
        options: TableUtils.options.iconCheckCircle()
      },
      {
        label: trans('air'),
        name: 'isAir',
        options: TableUtils.options.iconCheckCircle()
      },
      {
        label: trans('dangerous'),
        name: 'isDangerous',
        options: TableUtils.options.iconCheckCircle()
      },
      {
        label: trans('refrigerated'),
        name: 'isRefrigerated',
        options: TableUtils.options.iconCheckCircle()
      },
      {
        label: trans('type'),
        name: 'type',
        options: {
          sort: false,
          customBodyRenderLite: (index: number) => {
            const item = dataChargeCode?.[index];
            if (item) {
              const { isImport, isExport, isDomestic } = item;
              return (
                <KContainer.View row center gap="0.25rem">
                  <KContainer.RenderWhen limit={3}>
                    <KContainer.RenderWhen.If isTrue={isImport}>
                      <KContainer.View
                        paddingH="0.25rem"
                        background={KColors.palette.blue.w50}
                        brW={1}
                        brC={KColors.palette.blue.w600}
                        width="fit-content"
                        br="x"
                      >
                        <KLabel.Text
                          textTransform="uppercase"
                          color={KColors.palette.blue.w600}
                        >
                          {trans('import')}
                        </KLabel.Text>
                      </KContainer.View>
                    </KContainer.RenderWhen.If>

                    <KContainer.RenderWhen.If isTrue={isExport}>
                      <KContainer.View
                        paddingH="0.25rem"
                        background={KColors.palette.blue.w50}
                        brW={1}
                        brC={KColors.palette.blue.w600}
                        width="fit-content"
                        br="x"
                      >
                        <KLabel.Text
                          textTransform="uppercase"
                          color={KColors.palette.blue.w600}
                        >
                          {trans('export')}
                        </KLabel.Text>
                      </KContainer.View>
                    </KContainer.RenderWhen.If>

                    <KContainer.RenderWhen.If isTrue={isDomestic}>
                      <KContainer.View
                        paddingH="0.25rem"
                        background={KColors.palette.blue.w50}
                        brW={1}
                        brC={KColors.palette.blue.w600}
                        width="fit-content"
                        br="x"
                      >
                        <KLabel.Text
                          textTransform="uppercase"
                          color={KColors.palette.blue.w600}
                        >
                          {trans('domestic')}
                        </KLabel.Text>
                      </KContainer.View>
                    </KContainer.RenderWhen.If>
                  </KContainer.RenderWhen>
                </KContainer.View>
              );
            }
            return null;
          }
        }
      },
      {
        label: trans('level_type'),
        name: 'levelType',
        options: TableUtils.options.chip({
          label: v => (v ? trans(`option.${v?.toLowerCase()}`) : ''),
          kind: 'info'
        })
      },
      {
        label: trans('cont_mode_unit'),
        name: 'contModeUnit',
        options: {
          sort: false,
          ...TableUtils.options.withMoreIcon({
            key: 'title',
            onNormalizeData: rowData => {
              const item = dataChargeCode?.[rowData.rowIndex];
              if (item) {
                const arr = [
                  {
                    isChecked: item.isBol,
                    title: trans('bill_of_lading')
                  },
                  ...UNIT_SECTION.OCEAN.map(i => ({
                    isChecked: !!item[`${i.toLowerCase()}UnitId`],
                    title: [i, item[`${i.toLowerCase()}Unit`]?.name]
                      .filter(a => a)
                      .join(' - ')
                  })),
                  ...UNIT_SECTION.AIR.map(i => ({
                    isChecked: !!item[`${i.toLowerCase()}UnitId`],
                    title: [i, item[`${i.toLowerCase()}Unit`]?.name]
                      .filter(a => a)
                      .join(' - ')
                  }))
                ].filter(a => a.isChecked);

                return arr;
              }
              return [];
            }
          })
        }
      },
      {
        label: trans('calculator_type'),
        name: 'calculatorType',
        options: TableUtils.options.chip({
          kind: 'info'
        })
      },
      {
        label: trans('remark'),
        name: 'remark',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('instruction'),
        name: 'instruction',
        options: TableUtils.options.withEllipsis
      },
      {
        label: trans('base_unit_price'),
        name: 'baseValue',
        options: TableUtils.options.number()
      },
      // {
      //   label: trans('min_selling_unit_price'),
      //   name: 'minSelling',
      //   options: TableUtils.options.number()
      // },
      // {
      //   label: trans('markup_type'),
      //   name: 'markupType',
      //   options: TableUtils.options.chip({
      //     kind: 'info'
      //   })
      // },
      // {
      //   label: trans('markup_level_1'),
      //   name: 'markupLevel1',
      //   options: TableUtils.options.number()
      // },
      // {
      //   label: trans('markup_level_2'),
      //   name: 'markupLevel2',
      //   options: TableUtils.options.number()
      // },
      // {
      //   label: trans('markup_level_3'),
      //   name: 'markupLevel3',
      //   options: TableUtils.options.number()
      // },
      {
        label: trans('is_system'),
        name: 'isSystem',
        options: TableUtils.options.iconCheckCircle()
      },
      {
        label: trans('status'),
        name: 'status',
        options: TableUtils.options.status()
      },
      {
        label: trans('created_by'),
        name: 'createdUsername'
      },
      {
        label: trans('updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('created_at'),
        name: 'createdAt'
      },
      {
        label: trans('updated_at'),
        name: 'updatedAt'
      }
    ];
  }, [dataChargeCode]);

  const table = useLocalTable({
    name: WebTable.CHARGE_CODE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    data: dataChargeCode,
    columns
  });

  useGlobalTable(table);

  const newButton = useNewButton({
    title: 'ADD',
    onPress: () => {
      const newDataChecked = indexes.map((i: number) => dataChargeCode[i]);
      onAddChargeCode(newDataChecked);
    }
  });

  return (
    <>
      <DataTable
        {...table}
        options={{
          ...table.options,
          rowsSelected: indexes,
          onRowSelectionChange: (_cur, _, rows) => {
            setIndexes(rows ?? []);
            table.setSelectedIndexes(rows as any);
          }
        }}
      />

      {newButton}
    </>
  );
};

export default memo(ChargeCodeList);
