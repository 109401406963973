import React, { memo, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { WrappedLink } from 'hocs/withLink';
import { useDataTable, useGlobalTable, useNewButton } from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KBreadcrumbs, KContainer } from 'uikit';
import { TableUtils, UIUtils } from 'utils';

import FormNewClient from './Details/FormNewClient';
import { useDeleteClientsHelper } from './helpers';

const Client = () => {
  const navigate = useNavigate();

  useDocumentTitle('Client | Apollogix');

  const { onDelete, isLoading } = useDeleteClientsHelper();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => navigate(`${item.id}`)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('is_corporate'),
          name: 'isPerson',
          options: TableUtils.options.iconCheckCircle({ revertKey: true })
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              return <WrappedLink to={`${item?.id}`}>{v}</WrappedLink>;
            }
          }
        },
        {
          label: trans('name'),
          name: 'name',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('country'),
          name: 'defaultAddress.countryName'
        },
        {
          label: trans('telephone'),
          name: 'defaultAddress.telephone',
          options: {
            customBodyRender: (v?: string, rowData?: any) => {
              if (v) {
                const item = data?.[rowData.rowIndex];
                return [item?.country?.phoneCountryCode?.trim(), v?.trim()]
                  .filter(i => i)
                  .join('');
              }
              return '';
            }
          }
        },
        {
          label: trans('email'),
          name: 'email'
        },
        {
          label: trans('tax_id'),
          name: 'taxId'
        },
        {
          label: trans('stage'),
          name: 'clientStageName',
          options: TableUtils.options.chip({
            kind: 'info'
          })
        },
        {
          label: trans('client_group'),
          name: 'clientGroup',
          options: TableUtils.options.chip({
            kind: 'info'
          })
        },
        {
          label: trans('category'),
          name: 'clientCategoryName',
          options: TableUtils.options.chip({
            kind: 'info'
          })
        },
        {
          label: trans('client_type'),
          name: 'clientTypes',
          options: TableUtils.options.withMoreIcon({
            key: 'name',
            kind: 'warning'
          })
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('create_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('last_update'),
          name: 'updatedAt'
        }
      ];
    },
    [navigate, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: ['country', 'clientTypes', 'defaultAddress']
    };
  }, []);

  const mappedFields = useMemo(() => {
    return {
      'defaultAddress.countryName': 'country',
      'defaultAddress.telephone': 'telephone',
      'defaultAddress.fullAddress': 'fullAddress',
      clientStageName: 'clientStage',
      clientCategoryName: 'clientCategory'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.CLIENT,
    tableName: TableName.CLIENT,
    apiURL: ENDPOINTS.restrictedClient(),
    columnsFunc,
    otherParams,
    mappedFields,
    otherOptions: {
      selectableRows: 'none'
    }
  });

  useGlobalTable(table);

  const callbackFnFormClient = useCallback(
    (v: any) => {
      navigate('new', {
        state: {
          dataClient: v
        }
      });
    },
    [navigate]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAddNewClient = () => {
    UIUtils.popup.open({
      title: trans('add_new_client'),
      maxWidth: 'xl',
      content: () => <FormNewClient callbackFn={callbackFnFormClient} />
    });
  };

  const onAdd = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs hasBackIcon={false} title={trans('routes.client')} />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={onAddNewClient}
          onDelete={onDelete}
          isModifying={isLoading}
        />
      </KContainer.Card>

      {newButton}

      <Outlet />
    </>
  );
};

export default memo(Client);
