import React from 'react';

import { KLabel, KColors } from 'uikit';

interface IProps {
  errMessage?: string;
}
const CheckBoxError = ({ errMessage }: IProps) => {
  return errMessage ? (
    <KLabel.Text color={KColors.secondary.normal} typo={'TextXsNormal'}>
      {errMessage}
    </KLabel.Text>
  ) : (
    <></>
  );
};

export default CheckBoxError;
