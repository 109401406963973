import { get } from 'lodash';
import moment, { Moment } from 'moment-timezone';

import { MAX_PRECISION } from '@constants';

class Prototype {
  string = {
    removeAllSpaces: (target: string) => target?.replace(/\s/g, ''),
    normalizePhoneString: (
      phone: string = '',
      options?: {
        countryCode?: string;
        ignoreSpaces?: boolean;
        ignoreBrackets?: boolean;
      }
    ): string => {
      const {
        countryCode = '',
        ignoreSpaces = false,
        ignoreBrackets = true
      } = options || {};

      const mCode = this.string.removeAllSpaces(
        ignoreBrackets ? countryCode : `(${countryCode})`
      );

      const mPhone = ignoreSpaces ? this.string.removeAllSpaces(phone) : phone;

      return `${mCode}${mPhone}`;
    },
    normalizeFullAddress: (options?: {
      country?: any;
      city?: any;
      address?: string;
      officeAddress?: string;
      state?: any;
      zipCode?: string;
      suburb?: any;
    }) => {
      const { address, country, city, state, zipCode, officeAddress, suburb } =
        options || {};
      const arr = [];
      if (address) {
        arr.push(address);
      }
      if (officeAddress) {
        arr.push(officeAddress);
      }
      if (suburb?.name) {
        arr.push(suburb.name);
      }
      if (city?.name) {
        arr.push(city.name);
      }
      if (state?.name) {
        arr.push(state.name);
      }
      if (country?.name) {
        arr.push(country.name);
      }
      if (zipCode) {
        arr.push(zipCode);
      }
      return arr.join(', ');
    }
  };
  number = {
    round: (v: number | string = 0, precision: number = 3) => {
      const sign = +v < 0 ? -1 : 1;

      if (precision === 0) {
        return +Math.round(Math.abs(+v)) * sign;
      }

      const e = Math.pow(10, precision);
      return +(Math.round(Math.abs(+v) * e) / e) * sign;
    },
    roundVND:
      (isVND?: boolean) =>
      (v: number | string = 0, precision: number = 3) => {
        return isVND
          ? this.number.round(v, 0)
          : this.number.round(v, precision);
      },
    formatNumber: (
      v: number | string = 0,
      options?: {
        ignoreUnit?: boolean;
        locale?: string;
        comma?: string;
        dot?: string;
        revert?: boolean;
        unit?: string;
        precision?: number;
        pad?: boolean;
        negative?: boolean;
        defaultValue?: string | number;
        maxPrecision?: boolean;
      }
    ) => {
      const {
        comma = ',',
        dot = '.',
        ignoreUnit = true,
        unit = '',
        precision = 3,
        pad = true,
        negative = true,
        maxPrecision = false
      } = options || {};
      const pattern = ignoreUnit ? '' : `${unit}`;
      let withComma = this.number
        .round(v, maxPrecision ? MAX_PRECISION : precision)
        .toLocaleString('fullwide', {
          useGrouping: false,
          maximumFractionDigits: 20
        });

      // .replace(/\B(?=(\d{3})+(?!\d))/g, comma);

      let parts = withComma.split(dot);
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, comma);
      withComma = parts.join(dot);

      let withPad = withComma;
      if (pad && precision) {
        const dotPos = withComma.indexOf(dot);
        if (dotPos === -1) {
          withPad +=
            '.' + Array.from({ length: precision }, () => '0').join('');
        } else {
          const [p1, p2] = withPad.split(dot);
          withPad = p1 + '.' + p2.padEnd(precision, '0');
        }
      }

      if (negative) {
        withPad =
          withPad.indexOf('-') === 0 ? `(${withPad.slice(1)})` : withPad;
      }

      return `${pattern}${withPad}`;
    },
    formatCurrency: (
      v: number | string = 0,
      options?: {
        ignoreUnit?: boolean;
        locale?: string;
        comma?: string;
        dot?: string;
        revert?: boolean;
        unit?: string;
        precision?: number;
        pad?: boolean;
        negative?: boolean;
      }
    ) => {
      const {
        comma = ',',
        dot = '.',
        ignoreUnit = false,
        unit = '$',
        precision = 0,
        pad = true,
        negative = false
      } = options || {};
      return this.number.formatNumber(v, {
        comma,
        dot,
        ignoreUnit,
        unit,
        precision,
        pad,
        negative
      });
    },
    pad2Digits: (v: number | string = 0) => {
      const x = parseInt(v as string);
      if (x >= 0 && x < 10) {
        return `0${x}`;
      }
      return `${x}`;
    },
    convertNumber(v: number | string) {
      return v?.toString()?.replace(/,/g, '');
    }
  };

  date = {
    tz: () => moment.tz.guess(),

    toMoment: (d?: string, f?: string) => (d ? moment(d, f) : null),

    toUtc: (d?: string) => (d ? moment.utc(d) : null),

    applyTz: (d?: string, tz?: string) =>
      d ? moment.tz(d.slice(0, 19), tz || 'Greenwich') : null,

    applyTzLocal: (d?: string) => this.date.applyTz(d, moment.tz.guess()),

    toTz: (d?: string, tz?: string) =>
      d
        ? d.charAt(d.length - 1) === 'Z'
          ? moment(moment.tz(d.slice(0, d.length - 1), 'Greenwich')).tz(
              tz || moment.tz.guess()
            )
          : moment(moment.tz(d, 'Greenwich')).tz(tz || moment.tz.guess())
        : null,

    toLocal: (d?: string) => (d ? moment.utc(d).local() : null),

    formatDB: (d?: string, isLocal: boolean = true) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format('YYYY-MM-DD')
        : null,

    formatD: (d?: string, defaultValue: any = null, isLocal: boolean = true) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format('DD/MM/YYYY')
        : defaultValue,

    formatDT: (d?: string, defaultValue: any = null, isLocal: boolean = true) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format(
            'DD/MM/YYYY HH:mm'
          )
        : defaultValue,

    formatT: (d?: string, isLocal: boolean = true) =>
      d ? (isLocal ? moment.utc(d).local() : moment(d)).format('HH:mm') : null,

    now: (d: any = undefined) => moment(d),

    nowDB: (d: any = undefined) => this.date.now(d).format('YYYY-MM-DD'),

    isTodayDST: (d: Moment) => d.clone().isDST(),

    isTomorrowDST: (d: Moment) => d.clone().add(1, 'days').isDST(),

    getDSTHour: (d: Moment) => {
      const isTodayDST = this.date.isTodayDST(d);
      const isTomorrowDST = this.date.isTomorrowDST(d);

      console.log('isTodayDST', isTodayDST, isTomorrowDST);

      if (isTodayDST) {
        return -1;
      }

      // if (isTodayDST === false && isTomorrowDST === true) {
      //   return 1;
      // }
      // if (isTodayDST === true && isTomorrowDST === false) {
      //   return -1;
      // }
      return 0;
    },

    applyDSTHour: (d?: Moment) => {
      if (!d) {
        return null;
      }

      const offset = this.date.getDSTHour(d);
      console.log('offset', offset);
      return d.clone().add(offset, 'hours');
    }
  };

  getEnumKeyByEnumValue = (myEnum: any, enumValue: number | string): string => {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : '';
  };

  getTimezoneOffset = () => {
    const offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    const absOffset = Math.abs(offset);
    return `GMT${sign}${this.number.pad2Digits(
      absOffset / 60
    )}:${this.number.pad2Digits(absOffset % 60)}`;
  };

  core = {
    isNullOrUndefined: (v: any) =>
      v === null || v === undefined || v === 'undefined'
  };

  response = {
    normalizeData: (v: any, key: string) => {
      if (!this.core.isNullOrUndefined(v)) {
        return {
          code: get(v, key),
          ...v
        };
      }
      return v;
    }
  };
}

export default new Prototype();
