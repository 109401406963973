/* eslint-disable max-lines */
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import {
  CalculatorType,
  ENDPOINTS,
  LevelType,
  MarkupType,
  Status,
  WIPSourceType,
  WipType,
  generateOptions
} from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import {
  useResolverForm,
  useCUDChargeCode,
  useFetchChargeCodeDetails
} from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KForm, KListItem } from 'uikit';
import { ValidationUtils } from 'utils';

import FormAccount from './Form.Account';
import FormInstruction from './Form.Instruction';
import FormKind from './Form.Kind';
import FormMode from './Form.Mode';
import FormSubChargeCodes from './Form.SubChargeCodes';
import FormTax from './Form.Tax';
import FormType from './Form.Type';
import FormUnit from './Form.Unit';
import { IFormData, UNIT_SECTION } from './helpers';

import {
  useDeleteChargeCodeHelper,
  onShowChargeCodeCategory,
  onShowChargeCodeSubCategory
} from '../../helpers';

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  chargeCodeCategory: ValidationUtils.requiredAny(),
  chargeCodeSubCategory: ValidationUtils.requiredAny(),
  mode: yup
    .array(yup.boolean())
    .test('min1', trans('validation.select_at_least_one'), function (v) {
      return v?.some(i => i);
    }),

  // account: yup
  //   .array(yup.mixed().nullable())
  //   .test('min1', trans('validation.select_at_least_one'), function (v) {
  //     return v?.some(i => i);
  //   }),
  type: yup
    .array(yup.boolean())
    .test('min1', trans('validation.select_at_least_one'), function (v) {
      return v?.some(i => i);
    }),
  kind: yup
    .array(yup.boolean())
    .test('max1', trans('validation.select_at_most_one'), function (v) {
      return !v?.every(i => i);
    }),
  unit: yup
    .array()
    .test('min1', trans('validation.select_at_least_one'), function (v) {
      return v?.some(i => i.isChecked);
    })
    .test('withUnit', trans('validation.unit_must_not_be_empty'), function (v) {
      const data = v?.slice(1) ?? [];
      return data?.every(i => (i.isChecked ? !!i.data : true));
    }),
  costingCurrency: ValidationUtils.requiredAny(),
  sellingCurrency: ValidationUtils.requiredAny(),
  costingTaxType: ValidationUtils.requiredAny(),
  sellingTaxType: ValidationUtils.requiredAny(),
  baseValue: ValidationUtils.nullableNum().when(
    'calculatorType',
    ([calculatorType], _schema) => {
      return calculatorType === CalculatorType.Composite
        ? _schema.required().min(0)
        : _schema;
    }
  ),
  wipType: yup
    .string()
    .nullable()
    .when('isNoCost', ([isNoCost], _schema) => {
      return !isNoCost ? _schema.required() : _schema;
    }),
  wipValue: ValidationUtils.nullableNum().when(
    ['isNoCost', 'wipType'],
    ([isNoCost, wipType], _schema) => {
      return !isNoCost
        ? wipType === WipType.Percentage
          ? _schema.required().min(0).max(100)
          : _schema.required().min(0)
        : _schema;
    }
  ),
  // minSelling: ValidationUtils.requiredNum().min(0),
  // markupLevel1: ValidationUtils.requiredNum().when(
  //   ['markupType'],
  //   ([markupType], _schema) => {
  //     return markupType === MarkupType.Percentage
  //       ? _schema.min(0).max(100)
  //       : _schema.min(0);
  //   }
  // ),
  // markupLevel2: ValidationUtils.nullableNum().when(
  //   ['markupType'],
  //   ([markupType], _schema) => {
  //     return markupType === MarkupType.Percentage
  //       ? _schema.min(0).max(100)
  //       : _schema.min(0);
  //   }
  // ),
  // markupLevel3: ValidationUtils.nullableNum().when(
  //   ['markupType'],
  //   ([markupType], _schema) => {
  //     return markupType === MarkupType.Percentage
  //       ? _schema.min(0).max(100)
  //       : _schema.min(0);
  //   }
  // ),
  subChargeCodes: yup
    .array()
    .when('calculatorType', ([calculatorType], _schema) => {
      return calculatorType === CalculatorType.Composite
        ? _schema
            .required()
            .min(1)
            .of(
              yup.object().shape({
                ratio: yup.number().nullable().required().min(0).max(100),
                subChargeCode: yup.mixed<any>().nullable().required()
              })
            )
        : _schema;
    })
    .test(
      'isExist',
      trans('validation.some_charge_codes_are_duplicated'),
      function (v) {
        const data = v?.reduce((acc, cur) => {
          if (cur.subChargeCode?.code) {
            if (acc.hasOwnProperty(cur.subChargeCode.code)) {
              acc[cur.subChargeCode.code] = acc[cur.subChargeCode.code] + 1;
            } else {
              acc[cur.subChargeCode.code] = 1;
            }
          }
          return acc;
        }, {} as any);
        const chargeCodes = Object.keys(data).filter(i => data[i] > 1);
        if (chargeCodes.length > 0) {
          return this.createError({
            message: trans('validation.some_charge_codes_are_duplicated', {
              chargeCodes: chargeCodes.join(' - ')
            })
          });
        }
        return true;
      }
    )
});

const Form = ({ item: localItem }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDChargeCode();

  const { onDelete, deleteLoading } = useDeleteChargeCodeHelper();

  const { data: dataChargeCodeDetails } = useFetchChargeCodeDetails(
    localItem?.id
  );

  const item = useMemo(() => {
    return dataChargeCodeDetails ? dataChargeCodeDetails : localItem;
  }, [dataChargeCodeDetails, localItem]);

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        chargeCodeCategory: item?.chargeCodeSubCategory?.chargeCodeCategory,
        chargeCodeSubCategory: item?.chargeCodeSubCategory,

        code: item?.code ?? '',
        name: item?.name ?? '',
        status: item?.status ?? Status.Active,
        description: item?.description ?? '',
        remark: item?.remark ?? '',
        levelType: item?.levelType ?? LevelType.LevelType1,

        mode: [!!item?.isAir, !!item?.isOcean],
        type: [!!item?.isImport, !!item?.isExport, !!item?.isDomestic],
        kind: [!!item?.isDangerous, !!item?.isRefrigerated],

        unit: [
          {
            isChecked: !!item?.isBol,
            containerMode: ''
          },
          ...UNIT_SECTION.OCEAN.map(i => ({
            mode: 'ocean' as any,
            isChecked: !!item?.[`${i.toLowerCase()}Unit`],
            data: item?.[`${i.toLowerCase()}Unit`],
            containerMode: i
          })),
          ...UNIT_SECTION.AIR.map(i => ({
            mode: 'air' as any,
            isChecked:
              !!item?.[
                `${
                  i.includes('_FAK') ? 'oth' : i.split('_')[0].toLowerCase()
                }Unit`
              ],
            data: item?.[
              `${
                i.includes('_FAK') ? 'oth' : i.split('_')[0].toLowerCase()
              }Unit`
            ],
            containerMode: i
          }))
        ],

        containerTypeSizes: item?.containerTypeSizes ?? [],

        costingCurrency: item?.costingCurrency,
        sellingCurrency: item?.sellingCurrency,
        costingTaxType: item?.costingTaxType,
        sellingTaxType: item?.sellingTaxType,

        account: [item?.revenueAccount, item?.costAccount],

        calculatorType: item?.calculatorType ?? CalculatorType.Value,
        instruction: item?.instruction ?? '',
        baseValue: item?.baseValue,
        // isNoCost: !!item?.isNoCost,
        isNoCost: true,
        wipType: item?.wipType,
        wipValue: item?.wipValue,
        minSelling: item?.minSelling ?? '0',
        markupType: item?.markupType ?? MarkupType.Value,
        wipSourceType: item?.wipSourceType ?? '',
        markupLevel1: item?.markupLevel1 ?? '0',
        markupLevel2: item?.markupLevel2 ?? '0',
        markupLevel3: item?.markupLevel3 ?? '0',
        subChargeCodes: item?.subChargeCodes ?? [],

        isSystem: !!item?.isSystem
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const {
        mode,
        type,
        kind,
        unit,
        costingCurrency,
        sellingCurrency,
        subChargeCodes,
        chargeCodeCategory,
        chargeCodeSubCategory,
        containerTypeSizes,
        costingTaxType,
        sellingTaxType,
        account,
        ...rest
      } = data;

      const mParams = {
        id: item?.id,
        ...rest,
        costingCurrencyId: costingCurrency.id,
        sellingCurrencyId: sellingCurrency.id,
        chargeCodeCategoryId: chargeCodeCategory.id,
        chargeCodeSubCategoryId: chargeCodeSubCategory.id,
        costingTaxTypeId: costingTaxType?.id,
        sellingTaxTypeId: sellingTaxType?.id,
        revenueAccountId: account[0]?.id,
        costAccountId: account[1]?.id,

        isAir: mode[0],
        isOcean: mode[1],

        isImport: type[0],
        isExport: type[1],
        isDomestic: type[2],

        isDangerous: kind[0],
        isRefrigerated: kind[1],

        isBol: unit[0].isChecked,
        ...unit.slice(1).reduce((acc, cur) => {
          return {
            ...acc,
            [`${
              cur.containerMode.includes('_FAK')
                ? 'oth'
                : cur.containerMode.split('_')[0].toLowerCase()
            }UnitId`]: cur.data?.id
          };
        }, {}),

        subChargeCodes: subChargeCodes
          .filter(i => !!i.subChargeCode)
          .map(i => ({
            subChargeCodeId: i.subChargeCode.id,
            ratio: i.ratio
          })),

        containerTypeSizeIds: containerTypeSizes.map(i => i.id)
      };

      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, item?.id, updateMutation]
  );

  const [
    chargeCodeCategory,
    mode,
    // _sellingCurrency,
    // markupType,
    kind,
    isSystem
  ] = useWatch({
    control: methods.control,
    name: [
      'chargeCodeCategory',
      'mode',
      // 'sellingCurrency',
      // 'markupType',
      'kind',
      'isSystem'
    ]
  });

  // const suffix = ` (${_sellingCurrency?.code ?? ''})`;

  // const markupSuffix = markupType === MarkupType.Percentage ? ' (%)' : suffix;

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={3}>
            <Controller
              name="code"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('code')}
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={7}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('name')}
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <KListItem.Radio
              name="isSystem"
              label={trans('is_system')}
              checked={isSystem}
              disabled
              marginT="0.75rem"
            />
          </KGrid.Item>

          <KGrid.Item xs={4}>
            <Controller
              name="chargeCodeCategory"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('charge_code_category')}
                    onChange={(v: any) => {
                      methods.setValue('chargeCodeCategory', v);
                      methods.setValue('chargeCodeSubCategory', null);
                    }}
                    inputProps={{
                      message: error?.message,
                      required: true
                    }}
                    apiURL={ENDPOINTS.chargeCodeCategory()}
                    hasEdit
                    onEdit={onShowChargeCodeCategory}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={4}>
            <Controller
              name="chargeCodeSubCategory"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('charge_code_sub_category')}
                    inputProps={{
                      message: error?.message,
                      required: true
                    }}
                    apiURL={ENDPOINTS.chargeCodeSubCategory()}
                    apiParams={{
                      chargeCodeCategoryId: chargeCodeCategory?.id
                    }}
                    disabled={!chargeCodeCategory || isSystem}
                    hasEdit
                    onEdit={onShowChargeCodeSubCategory}
                  />
                );
              }}
            />
          </KGrid.Item>
          <KGrid.Item xs={4}>
            <Controller
              name="wipSourceType"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('wip_type')}
                    options={generateOptions(WIPSourceType)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <FormMode />
          </KGrid.Item>

          <KGrid.Item xs={5}>
            <FormType />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <FormKind />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="calculatorType"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('calculator_type')}
                    options={generateOptions(CalculatorType)}
                    onChange={e => {
                      field.onChange(e);
                      const v = e.target.value;
                      if (v === CalculatorType.Composite) {
                        methods.setValue('subChargeCodes', [
                          { subChargeCode: undefined, ratio: 0 }
                        ]);
                      } else {
                        methods.setValue('baseValue', undefined);
                        methods.setValue('subChargeCodes', []);
                        if (v === CalculatorType.Value) {
                          methods.setValue('instruction', '');
                        }
                      }
                    }}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>
          {/* ---------------------------------------------------------- */}
          <KGrid.Item xs={12}>
            <FormUnit />
          </KGrid.Item>
          {/* ---------------------------------------------------------- */}
          <KGrid.Item xs={12}>
            <Controller
              name="containerTypeSizes"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('container_size')}
                    apiURL={ENDPOINTS.containerTypeSize()}
                    apiParams={{
                      isAir:
                        mode[0] && mode[1] ? undefined : mode[0] || undefined,
                      isOcean:
                        mode[0] && mode[1] ? undefined : mode[1] || undefined,
                      isRefrigerated: kind[1] || undefined
                    }}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    disabled={!mode.some(i => i) || isSystem}
                    multiple
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('description')}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="remark"
              control={methods.control}
              render={({ field }) => {
                return <KInput.TextField {...field} label={trans('remark')} />;
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={1.7}>
            <Controller
              name="costingCurrency"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('costing_currency')}
                    apiURL={ENDPOINTS.currency()}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={1.7}>
            <Controller
              name="sellingCurrency"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('selling_currency')}
                    apiURL={ENDPOINTS.currency()}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={1.6}>
            <Controller
              name="levelType"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('level_type')}
                    options={generateOptions(LevelType)}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>
          {/* Chart of account */}
          <KGrid.Item xs={7}>
            <FormAccount />
          </KGrid.Item>

          <FormTax name="costingTaxType" />

          <FormTax name="sellingTaxType" />

          <FormInstruction />

          <FormSubChargeCodes />

          {/* <KGrid.Item xs={12}>
            <KGrid.Container>
              <KGrid.Item xs={2.4}>
                <Controller
                  name="isNoCost"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KListItem.Checkbox
                        {...field}
                        label={trans('no_cost_required')}
                        checked={field.value}
                        onAfterChange={v => {
                          if (v) {
                            methods.setValue('wipType', null);
                            methods.setValue('wipValue', null);
                          }
                        }}
                        disabled={isSystem}
                        containerStyle={{
                          marginTop: '0.75rem'
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KContainer.RenderWhen>
                <KContainer.RenderWhen.If isTrue={!isNoCost}>
                  <KGrid.Item xs={2.4}>
                    <Controller
                      name="wipType"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.TextField
                            {...field}
                            label={trans('default_profit_type')}
                            options={generateOptions(WipType)}
                            required
                            message={error?.message}
                            onChange={e => {
                              field.onChange(e);
                              methods.trigger('wipValue');
                            }}
                            disabled={isSystem}
                          />
                        );
                      }}
                    />
                  </KGrid.Item>

                  <KGrid.Item xs={2.4}>
                    <Controller
                      name="wipValue"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.TextField
                            {...field}
                            label={trans('default_profit_margin') + wipSuffix}
                            type="number"
                            required
                            message={error?.message}
                            disabled={isSystem}
                          />
                        );
                      }}
                    />
                  </KGrid.Item>
                </KContainer.RenderWhen.If>
              </KContainer.RenderWhen>
            </KGrid.Container>
          </KGrid.Item> */}
          {/* 
          <KGrid.Item xs={2.4}>
            <Controller
              name="minSelling"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('min_selling_unit_price') + suffix}
                    type="number"
                    required
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="markupType"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('markup_type')}
                    options={generateOptions(MarkupType)}
                    required
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="markupLevel1"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('markup_level_1') + markupSuffix}
                    type="number"
                    required
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="markupLevel2"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('markup_level_2') + markupSuffix}
                    type="number"
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="markupLevel3"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('markup_level_3') + markupSuffix}
                    type="number"
                    message={error?.message}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item> */}

          <KGrid.Item xs={2.4}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('status')}
                    options={generateOptions(Status)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdBy"
              label={trans('created_by')}
              value={item?.createdUsername ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdAt"
              label={trans('created_at')}
              value={Prototype.date.formatDT(item?.createdAt, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedBy"
              label={trans('updated_by')}
              value={item?.updatedUsername ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedAt"
              label={trans('updated_at')}
              value={Prototype.date.formatDT(item?.updatedAt, '')}
              disabled
            />
          </KGrid.Item>
        </KGrid.Container>

        <BottomActions
          showDelete={isEdit}
          btnProps={[
            {
              onPress: () => onDelete?.([item]),
              disabled: item?.isSystem,
              isLoading: deleteLoading
            },
            {
              isLoading: modifyLoading
            }
          ]}
        />
      </KForm>
    </FormProvider>
  );
};

export default memo(Form);
