import { IBranch } from '@dto';
import React, { memo, useState, useEffect } from 'react';

import { useUser, useSwitchBranch, useFetchUserStaffBranch } from 'hooks';
import trans from 'translation';
import { KButton, KContainer, KInput } from 'uikit';

import Item from './Item';

interface Props {
  feature?: 'login' | 'switch' | 'manual';
  dismiss: () => void;
  onSubmit?: (item: IBranch) => void;
}

const BranchList = ({ dismiss, feature = 'switch', onSubmit }: Props) => {
  const [keyword, setKeyword] = useState('');
  const [branchIndex, setBranchIndex] = useState(0);

  const { branch } = useUser();
  const { branchList = [] } = useFetchUserStaffBranch();

  const switchBranch = useSwitchBranch();

  useEffect(() => {
    if (branchList && branch?.id) {
      setBranchIndex(branchList.findIndex(i => i.id === branch?.id));
    }
  }, [branchList, branch?.id]);

  const handleSubmit = () => {
    switch (feature) {
      case 'switch':
        switchBranch.mutate(branchList[branchIndex]);
        break;

      case 'manual':
        onSubmit?.(branchList[branchIndex]);
        break;

      default:
        break;
    }
  };

  const isCheckSearchTerms = (dataSearch: string, keySearch: string) =>
    dataSearch.toUpperCase().includes(keySearch.toUpperCase());

  const handleSearchBranch = (event: any) => {
    setKeyword(event.target.value);
  };

  return (
    <KContainer.View>
      <KContainer.View marginB="1.25rem">
        <KInput.Search
          name="keyword"
          onChange={handleSearchBranch}
          placeholder={trans('search_placeholder')}
        />
      </KContainer.View>

      <KContainer.View overflowY="auto" maxH={'50vh'}>
        <KContainer.View
          justifyContent="space-between"
          flexW="wrap"
          row
          gap="1rem"
        >
          {branchList
            .filter(i => {
              return (
                isCheckSearchTerms(i.name, keyword) ||
                isCheckSearchTerms(i.fullAddress, keyword)
              );
            })
            .map(i => {
              const bIndex = branchList.findIndex(b => b.id === i.id);
              const isChecked = branchIndex === bIndex;
              return (
                <Item
                  key={i.id}
                  index={bIndex}
                  item={i}
                  onPress={setBranchIndex}
                  isChecked={isChecked}
                />
              );
            })}
        </KContainer.View>
      </KContainer.View>

      <KContainer.View
        dp="inline-flex"
        justifyContent="flex-end"
        width="100%"
        marginT="2rem"
      >
        <KButton.Solid
          icon="CancelOutlined"
          kind="secondary"
          onPress={dismiss}
          title={trans('cancel')}
        />

        <KButton.Solid
          marginL="0.5rem"
          icon="DoneAllOutlined"
          onPress={handleSubmit}
          title={trans('choose')}
          isLoading={switchBranch.isLoading}
        />
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(BranchList);
