import { BaseResponse } from '@dto';
import { AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { EventName, apiInstance } from '@constants';
import AppEvent from 'core/event';
import trans from 'translation';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

interface IParams {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  url: string;
  configs?: AxiosRequestConfig;
  body?: any;
  showError?: boolean;
  showToast?: boolean;
}

apiInstance.interceptors.request.use((config: any) => {
  config.url = config.baseURL + config.url;

  if (!config.headers.Authorization) {
    const localUser = StorageEnhance.get(STORAGE_KEYS.user);
    if (localUser?.token) {
      config.headers.Authorization = 'Bearer ' + localUser.token;
    }
  }

  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4()
  };

  return config;
});

class APIManager {
  static prepareParams = (params: Record<string, any>) => {
    return params ? `?${qs.stringify(params)}` : '';
  };

  static request = async <T = any>({
    method = 'GET',
    url,
    configs,
    body,
    showToast = false,
    showError = true
  }: IParams): Promise<BaseResponse<T>> => {
    try {
      const res =
        method === 'GET' || method === 'DELETE'
          ? await apiInstance[method.toLowerCase()](
              body ? `${url}?${qs.stringify(body)}` : url,
              configs
            )
          : await apiInstance[method.toLowerCase()](url, body, configs);

      const isPdfOrWord = url.includes('/render') || body?.renderFileExtension;

      if (!res || (!res.data?.success && !isPdfOrWord)) {
        throw {
          ...(res?.data ?? {}),
          code: (res as any).code,
          status: res.status
        };
      } else {
        if (isPdfOrWord) {
          return Promise.resolve({
            data: res.data,
            error: undefined
          });
        } else {
          if (showToast) {
            toast.success(res.data.message);
          }
          return Promise.resolve({
            data: res.data.payload,
            error: undefined,
            success: res.data.success
          });
        }
      }
    } catch (e: any) {
      const { response, message } = e || {};
      if (!response) {
        toast.error(message, {
          toastId: 'api-status-network'
        });

        return Promise.resolve({
          data: undefined,
          error: e,
          eData: undefined
        });
      } else {
        if (response.data?.code === 401) {
          toast.error(trans('session_expired'), {
            toastId: 'api-status-401'
          });

          AppEvent.dispatch(EventName.LOGOUT);
        } else {
          if (showError) {
            toast.error(
              response.data?.message ?? trans('something_went_wrong'),
              {
                toastId: `api-status-${response.data?.code || '500'}`
              }
            );
          }
        }
        return Promise.resolve({
          data: undefined,
          error: e,
          eData: response?.data?.data
        });
      }
    }
  };
}

export default APIManager;

export * from './config';
