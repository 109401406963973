import { compact } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { useUser } from 'hooks';
import trans from 'translation';
import { KTab } from 'uikit';

import { TabsWithPerKey } from './helpers';

interface IProps {
  onChangeTab?: (key: string) => void;
}

const ImpExpTab = ({ onChangeTab }: IProps) => {
  const { isExp, isImp } = useUser();

  const [index, setIndex] = useState(0);

  const tabs = useMemo(() => {
    return compact([
      // { key: 'ALL', label: trans('all') },
      !isExp
        ? { key: TabsWithPerKey.Import, label: trans('import') }
        : undefined,
      !isImp
        ? { key: TabsWithPerKey.Export, label: trans('export') }
        : undefined,
      { key: TabsWithPerKey.Domestic, label: trans('domestic') }
    ]);
  }, [isExp, isImp]);

  const _onChangeTab = useCallback(
    (idx: number) => {
      setIndex(idx);
      onChangeTab?.(tabs[idx].key);
    },
    [onChangeTab, tabs]
  );

  return (
    <KTab.White
      tabs={tabs}
      tabIndex={index}
      onChangeTab={_onChangeTab}
      kind="background"
      marginH="0.75rem"
      paddingH="0rem"
      spacing="0rem"
      marginT="0rem"
    />
  );
};

export default memo(ImpExpTab);
