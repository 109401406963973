import { useQueryClient } from '@tanstack/react-query';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AirOceanType,
  ConsolStatus,
  ENDPOINTS,
  QUERY_KEYS,
  TableName,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import { useImpExpOptions } from 'containers/TabsWithPer/helpers';
import ImpExpTab from 'containers/TabsWithPer/ImpExpTab';
import { WrappedLink } from 'hocs/withLink';
import { useDataTable, useGlobalTable, useNewButton } from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KBreadcrumbs, KChip, KContainer } from 'uikit';
import { TableUtils, mappedConsolStatusData } from 'utils';
import { onShowNoteContent } from 'utils/common';

import { useConsolActions } from './helpers';

const AFConsolidation = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { onAlert, isLoading } = useConsolActions();

  const { tabKey: _tabKey } = useImpExpOptions();

  const [tabKey, setTabKey] = useState(_tabKey);

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () =>
                navigate(`${item.id}`, {
                  state: {
                    code: item.consolNumber
                  }
                })
            },
            {
              title: trans('duplicate'),
              icon: 'ContentCopyOutlined',
              onPress: () => onAlert({ key: 'DUPLICATE', data: [item] })
            },
            {
              title: trans('copy_to_another_branch'),
              icon: 'InputOutlined',
              onPress: () => onAlert({ key: 'COPY', data: [item] })
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onAlert({ key: 'DELETE', data: [item] })
            }
          ])
        },
        {
          label: trans('consol_no'),
          name: 'consolNumber',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              return <WrappedLink to={`${item?.id}`}>{v}</WrappedLink>;
            }
          }
        },
        {
          label: trans('carrier_booking_no'),
          name: 'airlineBookingNo'
        },
        {
          label: trans('co_loader_booking_no'),
          name: 'coLoaderBookingNo'
        },
        {
          label: trans('mawb_no'),
          name: 'oblNo'
        },
        {
          label: trans('status'),
          name: 'consolStatus',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const value = item?.isBookingCancel ? ConsolStatus.Cancel : v;
              return (
                <KChip
                  textTransform={'uppercase'}
                  minW={'112px'}
                  width={'max-content'}
                  padding={'0.25rem'}
                  {...mappedConsolStatusData()[value as ConsolStatus]}
                />
              );
            }
          }
        },
        {
          label: trans('type'),
          name: 'consolType',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('agent_type'),
          name: 'agentType',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('service'),
          name: 'serviceLevelName'
        },
        {
          label: trans('1st_loading'),
          name: 'firstLoadingCode'
        },
        {
          label: trans('last_discharge'),
          name: 'lastDischargeCode'
        },
        {
          label: trans('flight'),
          name: 'flight',
          options: TableUtils.options.baseMd
        },
        {
          label: trans('is_linked'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle({ revertKey: true })
        },
        {
          label: trans('is_charter'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('express'),
          name: 'isCargoOnly',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('sending_agent'),
          name: 'sendingAgentName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('receiving_agent'),
          name: 'receivingAgentName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('airline'),
          name: 'airline.name',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('co_loader'),
          name: 'coLoaderName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('last_update'),
          name: 'updatedAt'
        },
        {
          label: trans('container_no'),
          name: 'containerNumberList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1
          })
        },
        {
          label: trans('total_packs'),
          name: 'totalPacks',
          options: TableUtils.options.numberWoPad()
        },
        {
          label: trans('with_unit.gross_weight', {
            unit: 'Kg'
          }),
          name: 'totalGrossWeight',
          options: TableUtils.options.numberWoPad()
        },
        {
          label: trans('with_unit.chargeable_weight', {
            unit: 'Kg'
          }),
          name: 'totalChargeableWeightVolume',
          options: TableUtils.options.numberWoPad()
        },
        {
          label: trans('onboard_date'),
          name: 'onboardDate',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('etd'),
          name: 'firstEtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('atd'),
          name: 'firstAtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('eta'),
          name: 'lastEta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('ata'),
          name: 'lastAta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('general_note'),
          name: 'noteContent',
          options: TableUtils.options.contentMore((item: any) => {
            onShowNoteContent(item);
          }, data)
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        }
      ];
    },
    [navigate, onAlert]
  );

  const otherParams = useMemo(() => {
    return {
      freightMode: AirOceanType.Air,
      includeFields: ['airline'],
      consolType: tabKey
    };
  }, [tabKey]);

  const mappedFields = useMemo(() => {
    return {
      'airline.name': 'airline',
      sendingAgentName: 'sendingAgent',
      receivingAgentName: 'receivingAgent',
      coLoaderName: 'coLoader',
      serviceLevelName: 'serviceLevel',
      firstLoadingCode: 'firstLoading',
      lastDischargeCode: 'lastDischarge'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.AF_CONSOL,
    tableName: TableName.CONSOL,
    columnsFunc,
    showTimeRange: true,
    apiURL: ENDPOINTS.consol(),
    mappedFields,
    otherParams,
    otherOptions: {
      selectableRows: 'none'
    }
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    queryClient.removeQueries([QUERY_KEYS.consol]);
    navigate('new');
  }, [navigate, queryClient]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  useDocumentTitle('Air Freight | Consolidation');

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.air_freight'),
          trans('routes.consolidation')
        ]}
      />

      <ImpExpTab onChangeTab={setTabKey} />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={onAdd}
          onCopy={data => onAlert({ key: 'DUPLICATE', data })}
          onDelete={data => onAlert({ key: 'DELETE', data })}
          showStatus={true}
          isModifying={isLoading}
        />
      </KContainer.Card>

      {newButton}

      <Outlet />
    </>
  );
};

export default memo(AFConsolidation);
