import { useMemo } from 'react';

import { ConsolType, ExpConsolType, ImpConsolType } from '@constants/enum';
import { useUser } from 'hooks';

export enum TabsWithPerKey {
  Import = 'IMP',
  Export = 'EXP',
  Domestic = 'DOM'
}

export const useImpExpOptions = () => {
  const { isExp, isImp } = useUser();

  const tabOptions = useMemo(() => {
    return isImp ? ImpConsolType : isExp ? ExpConsolType : ConsolType;
  }, [isExp, isImp]);

  const tabKey = useMemo(() => (!isExp ? 'IMP' : 'EXP'), [isExp]);

  return { tabOptions, tabKey };
};
