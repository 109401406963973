import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import React, { memo, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { Prototype } from 'core';
import {
  useDownloadSampleFile,
  useImportExchangeRate,
  useResolverForm,
  useValidateImportedFile
} from 'hooks';
import trans from 'translation';
import { KButton, KContainer, KForm, KGrid, KInput, KPicker } from 'uikit';
import { ValidationUtils } from 'utils';

import ErrorList from './Import.ErrorList';

interface IProps {
  createdDate: any;
}

const Import = ({ createdDate }: IProps) => {
  const { mutate, isLoading } = useImportExchangeRate();
  const { mutate: mutateValidate, isLoading: validateLoading } =
    useValidateImportedFile();
  const { mutate: mutateDownload, isLoading: downloadLoading } =
    useDownloadSampleFile();

  const [file, setFile] = useState<any>(undefined);
  const [data, setData] = useState<any[]>([]);

  const methods = useResolverForm<IProps>({
    schema: yup.object().shape({
      createdDate: ValidationUtils.date().test(
        'createdDate',
        trans('message.created_date_less_than_equal_current_date'),
        function (v) {
          const dateToCompare = moment(v);
          const today = moment();
          const isSameOrBefore = dateToCompare.isSameOrBefore(today, 'day');
          return isSameOrBefore;
        }
      )
    }),
    configs: {
      values: {
        createdDate: Prototype.date.toLocal(createdDate) ?? ''
      }
    }
  });

  const onChange = useCallback((files: any[], onSuccess: any) => {
    if (isArray(files)) {
      if (!isEmpty(files)) {
        const f = files[0];
        setFile(f);
      } else {
        setFile(undefined);
        setData([]);
      }
      onSuccess?.(files);
    }
  }, []);

  const handleSubmit = useCallback(
    ({ createdDate: _createdDate }: IProps) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'createdDate',
        Prototype.date.formatDB(_createdDate) as any
      );
      mutateValidate(formData, {
        onSuccess: _data => {
          if (_data?.length > 0) {
            setData(_data);
          } else {
            mutate(formData);
          }
        }
      });
    },
    [file, mutate, mutateValidate]
  );

  const onDownload = useCallback(() => {
    mutateDownload({ importTemplateType: 'EXCHANGE_RATE' });
  }, [mutateDownload]);

  return (
    <KForm onSubmit={methods.handleSubmit(handleSubmit)}>
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <Controller
            name="createdDate"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KPicker.Date
                  {...field}
                  label={trans('created_date')}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <KInput.Dropzone
            maxFiles={1}
            onChange={onChange}
            accept={{
              'application/vnd.ms-excel': ['.xls'], // For older Excel formats
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'] // For modern Excel formats
            }}
          />

          <KContainer.RenderWhen>
            <KContainer.RenderWhen.If isTrue={data.length > 0}>
              <ErrorList data={data} />
            </KContainer.RenderWhen.If>
          </KContainer.RenderWhen>
        </KGrid.Item>
      </KGrid.Container>

      <KContainer.View
        row
        alignItems
        justifyContent="flex-end"
        marginT="0.75rem"
      >
        <KButton.Outline
          icon="FileDownloadOutlined"
          kind="primary"
          title={trans('download_sample_file')}
          hasShadow
          marginR="0.75rem"
          isLoading={downloadLoading}
          onPress={onDownload}
        />

        <KButton.Solid
          icon="Save"
          type="submit"
          isLoading={isLoading || validateLoading}
          disabled={!file || data.length > 0}
          title={trans('submit')}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(Import);
