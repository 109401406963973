import {
  ContainerStatus,
  generateOptions,
  LocalAllStatus1,
  LocalCompleteStatus1,
  LocalPendingStatus1
} from '@constants';
import { useAlertMutationEnhancer, useCUDService } from 'hooks';

export const useDeleteService = () => {
  const { deleteMutation: mutation } = useCUDService();

  return useAlertMutationEnhancer({ mutation });
};

export const checkComplete = (containerStatus: ContainerStatus) => {
  return containerStatus === ContainerStatus.Complete;
};

// export enum LocalAllStatus {
//   Draft = 'DRF',
//   Active = 'ACT',
//   Complete = 'COM'
// }

// export enum LocalCompleteStatus {
//   Active = 'ACT',
//   Complete = 'COM'
// }

// export enum LocalDraftStatus {
//   Draft = 'DRF',
//   Active = 'ACT'
// }

// enum LocalAllStatus1 {
//   PEN = 'PEN',
//   PRO = 'PRO',
//   COM = 'COM'
// }

// enum LocalPendingStatus1 {
//   PEN = 'PEN',
//   PRO = 'PRO'
// }

// enum LocalCompleteStatus1 {
//   PRO = 'PRO',
//   COM = 'COM'
// }

// export enum HandlingStatus {
//   PEN = 'PEN',
//   COM = 'COM'
// }

export const optionStatus = (status: LocalAllStatus1) => {
  if (status === LocalAllStatus1.COM) {
    return generateOptions(LocalCompleteStatus1);
  }

  if (status === LocalAllStatus1.PEN) {
    return generateOptions(LocalPendingStatus1);
  }

  return generateOptions(LocalAllStatus1);
};
