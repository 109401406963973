import { useMutation, useQueryClient } from '@tanstack/react-query';
/* eslint-disable max-lines */
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  APInvoiceStatus,
  ArApType,
  ARInvoiceStatus,
  CHARGE_CODE_INCLUDE_FIELDS,
  ENDPOINTS,
  InvoiceCategory,
  InvoiceType,
  PaymentStatus,
  QUERY_KEYS,
  StatementInvoiceStatus,
  tableRef,
  UIDataType,
  VATStatus,
  WebTable
} from '@constants';
import APIManager from '@services';
import {
  IPrintVatInvoice,
  onPrintVatInvoice
} from 'containers/WIP/Invoice/helpers';
import { useFetchSettings } from 'hooks';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchApInvoice = (
  category: InvoiceCategory,
  id?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.apInvoice(category, ':id', {
          id
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchArInvoice = (
  category: InvoiceCategory,
  id?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.arInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(category, ':id', {
          id
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useCUDArInvoice = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ar
    }),
    webTable: WebTable.CONSOL_AR_INVOICES,
    queryKey: QUERY_KEYS.arInvoice,
    mutationKeys: [[QUERY_KEYS.arInvoice]],
    dependentWebTable: [
      WebTable.SHIPMENT_AR_INVOICES,
      WebTable.SERVICE_AR_INVOICES
    ]
  });
};

interface IUseStatusMutationEnhancerParams {
  suffix?: string;
  category: InvoiceCategory;
  arApType: ArApType;
}

const MUTATION_MAPPED_DATA = {
  [ArApType.Ar]: {
    queryKey: QUERY_KEYS.arInvoice,
    webTables: [
      WebTable.CONSOL_AR_INVOICES,
      WebTable.SHIPMENT_AR_INVOICES,
      WebTable.SERVICE_AR_INVOICES
    ]
  },
  [ArApType.Ap]: {
    queryKey: QUERY_KEYS.apInvoice,
    webTables: [
      WebTable.CONSOL_AP_INVOICES,
      WebTable.SHIPMENT_AP_INVOICES,
      WebTable.SERVICE_AP_INVOICES
    ]
  }
};

const useStatusMutationEnhancer = (d: IUseStatusMutationEnhancerParams) => {
  const { suffix, category, arApType } = d;

  const queryClient = useQueryClient();

  const mData = MUTATION_MAPPED_DATA[arApType];

  return useMutationEnhancer<boolean | undefined>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.accounting(
          {
            category,
            type: arApType
          },
          `:id/${suffix}`,
          data
        ),
        method: 'PUT',
        showToast: true,
        body: data
      });
      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        mData.webTables?.forEach(i => {
          tableRef?.[i]?.init?.();
        });
        if (mData.queryKey) {
          queryClient.invalidateQueries([mData.queryKey], {
            type: 'active'
          });
        }
      }
    }
  });
};

export const useCUDStatusArInvoice = (category: InvoiceCategory) => {
  const mutationEnhancer = (
    d: Pick<IUseStatusMutationEnhancerParams, 'suffix'>
  ) =>
    useStatusMutationEnhancer({
      category,
      arApType: ArApType.Ar,
      ...d
    });

  const [nextMutation, backMutation, cancelMutation] = [
    'next-status',
    'previous-status',
    'cancel'
  ].map(i => mutationEnhancer({ suffix: i }));

  return {
    nextMutation,
    backMutation,
    cancelMutation,
    isLoading:
      nextMutation.isLoading ||
      backMutation.isLoading ||
      cancelMutation.isLoading
  };
};

export const useCUDApInvoice = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ap
    }),
    webTable: WebTable.CONSOL_AP_INVOICES,
    queryKey: QUERY_KEYS.apInvoice,
    mutationKeys: [[QUERY_KEYS.apInvoice]],
    dependentWebTable: [
      WebTable.SHIPMENT_AP_INVOICES,
      WebTable.SERVICE_AP_INVOICES
    ]
  });
};

export const useCUDStatusApInvoice = (category: InvoiceCategory) => {
  const mutationEnhancer = (
    d: Pick<IUseStatusMutationEnhancerParams, 'suffix'>
  ) =>
    useStatusMutationEnhancer({
      category,
      arApType: ArApType.Ap,
      ...d
    });

  const [nextMutation, backMutation, cancelMutation] = [
    'next-status',
    'previous-status',
    'cancel'
  ].map(i => mutationEnhancer({ suffix: i }));

  return {
    nextMutation,
    backMutation,
    cancelMutation,
    isLoading:
      nextMutation.isLoading ||
      backMutation.isLoading ||
      cancelMutation.isLoading
  };
};

interface IUseInvoiceActions {
  category: InvoiceCategory;
  arApType: ArApType;
  id?: string | number;
  status?: ARInvoiceStatus | APInvoiceStatus;
  nextStatus?: ARInvoiceStatus | APInvoiceStatus;
}

type IInvoiceAction = 'NEW' | 'NEXT' | 'BACK' | 'CANCEL' | 'PRINT_VAT_INVOICE';

export const useArInvoiceStatusForActions = (
  status?: ARInvoiceStatus | APInvoiceStatus
): IInvoiceAction[] => {
  switch (status) {
    case ARInvoiceStatus.DRAFT:
    case ARInvoiceStatus.CONFIRMED:
    case ARInvoiceStatus.CONFIRM_REQUEST:
    case ARInvoiceStatus.INVOICE_REQUEST:

    case APInvoiceStatus.DRAFT:
    case APInvoiceStatus.PAYMENT_REQUEST:
    case APInvoiceStatus.VERIFIED:
      return ['NEW', 'NEXT', 'BACK', 'CANCEL'];

    case ARInvoiceStatus.ISSUED:
    case APInvoiceStatus.APPROVED:
      return ['NEW', 'CANCEL'];

    case ARInvoiceStatus.COMPLETE:
    case ARInvoiceStatus.CANCEL:

    case APInvoiceStatus.COMPLETE:
    case APInvoiceStatus.CANCEL:
      return ['NEW'];

    default:
      return [];
  }
};

const MAPPED_INVOICE_ACTION: Record<IInvoiceAction, any> = {
  ['NEXT']: {
    title: 'send_invoice_status',
    icon: 'ArrowUpward'
  },
  ['BACK']: {
    title: 'back_invoice_status',
    icon: 'ArrowDownward'
  },
  ['CANCEL']: {
    title: 'cancel',
    icon: 'CancelOutlined',
    color: KColors.secondary.normal
  },
  ['NEW']: {
    title: 'new',
    icon: 'AddBox'
  },
  ['PRINT_VAT_INVOICE']: {
    title: 'print_vat_invoice',
    icon: 'Print'
  }
};

export const useInvoiceActions = ({
  arApType,
  category,
  id,
  status,
  nextStatus
}: IUseInvoiceActions) => {
  const navigate = useNavigate();

  const arStatusMutation = useCUDStatusArInvoice(category);

  const apStatusMutation = useCUDStatusApInvoice(category);

  const isAr = arApType === ArApType.Ar;

  const {
    nextMutation: { mutate: nextMutation },
    backMutation: { mutate: backMutation },
    cancelMutation: { mutate: cancelMutation },
    isLoading
  } = isAr ? arStatusMutation : apStatusMutation;

  const setAction = useCallback(
    (action: IInvoiceAction) => {
      let onPress: () => void;

      switch (action) {
        case 'NEXT':
          onPress = () => {
            if (
              nextStatus &&
              [ARInvoiceStatus.ISSUED, APInvoiceStatus.APPROVED].includes(
                nextStatus
              )
            ) {
              UIUtils.alert.open({
                message: trans('with_unit.next_status', {
                  unit: nextStatus
                }),
                buttons: [
                  {
                    title: trans('ok'),
                    kind: 'primary',
                    color: KColors.warning.normal,
                    onPress: () => nextMutation({ id })
                  },
                  {
                    title: trans('cancel')
                  }
                ]
              });
            } else {
              nextMutation({ id });
            }
          };
          break;

        case 'BACK':
          onPress = () => backMutation({ id });
          break;

        case 'CANCEL':
          onPress = () => cancelMutation({ id });
          break;
        case 'PRINT_VAT_INVOICE':
        default:
          onPress = () => navigate('../new');
          break;
      }

      const {
        title,
        icon,
        color = KColors.primary.normal
      } = MAPPED_INVOICE_ACTION[action] || {};

      return {
        title: trans(title),
        icon: {
          name: icon,
          color: color
        },
        onPress
      };
    },
    [backMutation, cancelMutation, id, navigate, nextMutation, nextStatus]
  );

  const actionKeys = useArInvoiceStatusForActions(status);

  const actions = useMemo(() => {
    return actionKeys.map(i => setAction(i));
  }, [actionKeys, setAction]);

  return { actions, isLoading };
};

export const useArInvoiceRequest = (item: any, CATEGORY: InvoiceCategory) => {
  const v1 =
    item?.arInvoiceStatus?.code !== ARInvoiceStatus.CANCEL &&
    item?.arInvoiceStatus?.code !== ARInvoiceStatus.DRAFT; // Invoice status khác Cancelled và Draft

  const v2 = !item?.vatInvoiceNo; // field VATInvoiceNo chưa có giá trị

  const v3 =
    item?.vatInvoiceStatus === VATStatus.REQUEST || !item?.vatInvoiceStatus; //VAT Status = null hoặc REQUEST

  const isShowAction = v1 && v2 && v3;

  const {
    updateVATStatusMutation: { mutate: updateVATStatusMutation },
    isLoading
  } = useCUDArInvoice(CATEGORY);

  if (isShowAction) {
    return {
      sendVAT: [
        {
          title: trans('send_vat_request'),
          icon: {
            name: 'SendOutlined',
            color: KColors.primary.normal
          },
          onPress: () => {
            updateVATStatusMutation({ id: item?.id });
          }
        }
      ],
      vatLoading: isLoading
    };
  }

  return {
    sendVAT: [],
    vatLoading: isLoading
  };
};

export const usePrintVatInvoice = ({
  vatInvoiceNo,
  invoiceType,
  status,
  ...params
}: IPrintVatInvoice & {
  invoiceType: InvoiceType;
  status: ARInvoiceStatus | StatementInvoiceStatus;
  vatInvoiceNo: string;
  hasDraftVat?: boolean;
  screen: 'statement-invoice' | 'ar-invoice';
}) => {
  const isShowPrintVatInvoice =
    invoiceType === InvoiceType.ORDINARY &&
    status !== ARInvoiceStatus.CANCEL &&
    !vatInvoiceNo;

  if (isShowPrintVatInvoice) {
    return {
      printVatInvoice: [
        {
          title: trans('print_vat_invoice'),
          icon: {
            name: 'Print',
            color: KColors.primary.normal
          },
          onPress: () => onPrintVatInvoice(params)
        }
      ]
    };
  }

  return {
    printVatInvoice: []
  };
};

export const useApInvoiceRequest = (item: any, CATEGORY: InvoiceCategory) => {
  const v1 =
    item?.apInvoiceStatus?.code !== APInvoiceStatus.CANCEL &&
    item?.apInvoiceStatus?.code !== APInvoiceStatus.COMPLETE &&
    item?.apInvoiceStatus?.code !== APInvoiceStatus.DRAFT; // Invoice status khác Cancelled và Draft

  const v2 = item?.paymentStatus !== PaymentStatus.PAID || item?.balance !== 0;

  const isShowAction = v1 && v2;

  const {
    updatePaymentRequestStatusMutation: {
      mutate: updatePaymentRequestStatusMutation
    },
    isLoading
  } = useCUDApInvoice(CATEGORY);

  if (isShowAction) {
    return {
      sendPayment: [
        {
          title: trans('send_payment_request'),
          icon: {
            name: 'SendOutlined',
            color: KColors.primary.normal
          },
          onPress: () => {
            updatePaymentRequestStatusMutation({ id: item?.id });
          }
        }
      ],
      paymentLoading: isLoading
    };
  }

  return {
    sendPayment: [],
    paymentLoading: isLoading
  };
};

export const useCheckApThreshold = (
  category: InvoiceCategory,
  id?: string | number // CONSOL | SHIPMENT | SERVICE ID
) => {
  const apiURL = () => {
    const apiPath = ':id/check-threshold';

    if (category === InvoiceCategory.Service) {
      return ENDPOINTS.service(apiPath, { id });
    } else if (category === InvoiceCategory.Billing) {
      return ENDPOINTS.shipment(apiPath, { id });
    } else {
      return ENDPOINTS.consol(apiPath, { id });
    }
  };

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apInvoiceThreshold, category, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: apiURL()
      });

      return res.data ?? null;
    },
    enabled: !!id
  });
};

export const useApInvoiceWithThresholdHooks = (
  category: InvoiceCategory,
  id?: string | number, // CONSOL | SHIPMENT | SERVICE ID
  invoiceId?: string | number
) => {
  const { data: threshold } = useCheckApThreshold(category, id);

  const { data, refetch, isLoading } = useFetchApInvoice(category, invoiceId);

  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDApInvoice(category);

  return {
    data, // INVOICE
    threshold, // THRESHOLD
    isLoading: isLoading || modifyLoading,

    refetch,
    createMutation,
    updateMutation
  };
};

export const useFetchAccountingInvoiceSummary2 = (
  arApType = ArApType.Ar,
  dayRange?: number
) => {
  const { data: settings } = useFetchSettings();

  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.accountingInvoiceSummary,
      arApType,
      dayRange,
      settings
    ],
    queryFn: async () => {
      const res = await APIManager.request<any[]>({
        url:
          arApType === ArApType.Ar
            ? ENDPOINTS.arInvoice2('invoice-summary')
            : ENDPOINTS.apInvoice2('invoice-summary'),
        body: { dayRange: dayRange || '' }
      });

      const newData = (res.data ?? []).reduce<Record<string, any>>(
        (acc, cur) => {
          acc[cur.currencyCode] = cur;
          return acc;
        },
        {}
      );

      const currencyCodes = {
        primary: settings?.primaryCurrency?.code,
        secondary: settings?.secondaryCurrency?.code
      };

      const arr = [
        currencyCodes,
        {
          title: 'total_amount',
          primary: newData[currencyCodes.primary]?.totalAmount ?? 0,
          secondary: newData[currencyCodes.secondary]?.totalAmount ?? 0
        },
        {
          title: 'balance_due',
          primary: newData[currencyCodes.primary]?.balance ?? 0,
          secondary: newData[currencyCodes.secondary]?.balance ?? 0
        },
        {
          title: 'overdue',
          primary: newData[currencyCodes.primary]?.overdueBalance ?? 0,
          secondary: newData[currencyCodes.secondary]?.overdueBalance ?? 0,
          color: KColors.secondary.dark
        }
      ];

      return arr;
    },
    enabled: !!dayRange || dayRange === 0
  });
};

export const useFetchChargeCodeProfitAndLoss = (mParams: {
  category: UIDataType;
  otherParams: any;
}) => {
  const { category, otherParams } = mParams;

  const apiURL = () => {
    switch (category) {
      case UIDataType.Cs:
        return ENDPOINTS.accountingChargeCodeList();

      case UIDataType.Sm:
        return ENDPOINTS.billingChargeCodeList();

      case UIDataType.Sv:
        return ENDPOINTS.serviceChargeCodeList();

      default:
        return ENDPOINTS.accountingChargeCodeList();
    }
  };

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.chargeCodeProfitAndLoss, category, otherParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: apiURL(),
        body: {
          ...otherParams,
          includeFields: CHARGE_CODE_INCLUDE_FIELDS
        }
      });

      return res.data;
    }
  });
};

export const useCancelAndRevise = (
  item: any,
  CATEGORY: InvoiceCategory,
  type: ArApType
): { cancelRevise: any[]; loadingCancelRevise: boolean } => {
  const navigate = useNavigate();
  const endPoint = ENDPOINTS.accounting({
    category: CATEGORY,
    type
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (params: { id: number | string }) => {
      const res = await APIManager.request({
        url: `${endPoint}/${item?.id}/cancel-and-revise`,
        body: {
          id: params.id
        },
        method: 'PUT'
      });
      return res.data;
    }
  });

  return {
    cancelRevise: [
      {
        title: trans('cancel_and_revise'),
        icon: {
          name: 'SendOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {
          mutate(
            { id: item?.id },
            {
              onSuccess: data => {
                if (!!data) {
                  navigate(`../${data?.id}`);
                }
              }
            }
          );
        }
      }
    ],
    loadingCancelRevise: isLoading
  };
};
