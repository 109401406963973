import { ICountry, IMetadata, ITimezone } from '@dto';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OceanContainerMode,
  Gender,
  ShipmentType,
  Status,
  WorkingDay,
  ClientGroup
} from '@constants';
import {
  useCUDAddress,
  useCUDBankAccount,
  useCUDClientSaleCommission,
  useCUDContact,
  useCUDCurrencyUplift,
  useDeleteClients,
  useDeleteHelper
} from 'hooks';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

export interface IFormData {
  id?: string | number;
  name: string;
  vnName?: string;
  fullAddress?: string;
  vnFullAddress?: string;
  country?: ICountry;
  isPerson?: boolean;
  website?: string;
  emails?: string;
  fax?: string;
  ext?: string;
  telephone?: string;
  taxId?: string;
  clientCategory?: IMetadata;
  clientStage?: IMetadata;
  status?: Status;
  timezone?: ITimezone;

  contactName?: string;
  contactDob?: any;
  contactIsMale?: boolean;
  contactDept?: IMetadata;
  contactJobTitle?: IMetadata;
  contactCountry?: ICountry;
  contactMobile?: string;
  contactTelephone?: string;
  contactExt?: string;
  contactLanguage?: IMetadata;
  contactEmail?: string;
  contactPurpose?: string;

  // clientTypeIds: number[];
  clientGroup?: ClientGroup;
}

export interface IFormDataClients {
  id?: string | number;
  clientTypeIds: number[];
}

export interface IAddressFormData {
  code: string;
  workingDays: WorkingDay[];
  name: string;
  startWorkingHour?: any;
  endWorkingHour?: any;
  country?: ICountry;
  taxId: string;
  telephone?: string;
  ext?: string;
  email?: string;
  zipCode?: string;
  fax?: string;
  fullAddress?: string;
  vnFullAddress?: string;
  status: Status;
  isDefault: boolean;
  timezone?: ITimezone;

  branchAddressCapabilityIds: number[];
}

export interface IContactFormData {
  name: string;
  gender: Gender;
  birthdate?: any;
  department?: IMetadata | null;
  jobTitle?: IMetadata | null;
  country?: ICountry | null;
  mobilePhone?: string;
  telephone?: string;
  ext?: string;
  nationality?: IMetadata | null;
  firstLanguage?: IMetadata | null;
  email?: string;
  purpose?: string;
  status: Status;
  isDefault: boolean;
}

export interface IBankAccountFormData {
  accountNumber: string;
  accountName: string;
  bankName: string;
  bankBranch: string;
  bsbNumber?: string;
  currency?: IMetadata;
  swiftCode?: string;
  status: Status;
  isDefault: boolean;
}

export interface ICurrencyUpliftFormData {
  containerMode: OceanContainerMode;
  shipmentType: ShipmentType;
  chargeCodeCategory?: IMetadata;
  chargeCodeSubCategory?: IMetadata;
  currency?: IMetadata;
  cfx?: number;
  status: Status;
  chargeCodeIds: number[];
  clientId: string | number;
}

export interface ISalePersonItem {
  staffId: number;
  client?: string;
  salePerson?: string;
  id: number;
  title: string;
}

export const useDeleteClientsHelper = (isBack?: boolean) => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    if (isBack) {
      navigate(-1);
    }
  }, [isBack, navigate]);

  const { mutate, ...rest } = useDeleteClients(onSuccess);

  const onDelete = useCallback(
    (data: any[]) => {
      const ids = data.map(i => i.id);
      const message = trans('message.delete_client', { count: data.length });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids)
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [mutate]
  );

  return { onDelete, ...rest };
};

export const useDeleteAddressesHelper = (id?: string | number) => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDAddress(id);

  const onDelete = useCallback(
    (data: any[], onSuccess?: () => void) => {
      const ids = data.map(i => i.id);
      const message = trans('message.delete_address', {
        count: data.length
      });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids, { onSuccess })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [mutate]
  );

  return { onDelete, ...rest };
};

export const useDeleteContactsHelper = (addressId?: number) => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDContact(addressId);

  const onDelete = useDeleteHelper({
    name: 'contact',
    mutate
  });

  return { onDelete, ...rest };
};

export const useDeleteBankAccountsHelper = (
  id?: string | number,
  isAr?: boolean
) => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDBankAccount(id, isAr);

  const onDelete = useDeleteHelper({
    name: 'bank_account',
    mutate
  });

  return { onDelete, ...rest };
};

export const useDeleteCurrencyUpliftsHelper = (id?: string | number) => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDCurrencyUplift(id);

  const onDelete = useDeleteHelper({
    name: 'currency_uplift',
    mutate
  });

  return { onDelete, ...rest };
};

export const useDeleteCommissionsHelper = (id?: string | number) => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDClientSaleCommission(id);

  const onDelete = useDeleteHelper({
    name: 'commission',
    mutate
  });

  return { onDelete, ...rest };
};

export const requireTaxId = (country: any, isPerson?: boolean) => {
  const isVn = country?.code === 'VN';
  const isCorporate = isPerson === false;
  return isVn && isCorporate;
};

export const requireClientName = (country: any, isPerson: boolean) => {
  const isVn = country?.code === 'VN';
  const isPersonal = isPerson === true;
  return !isVn || (isVn && isPersonal);
};
