import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect } from 'react';

import { EventName, GROWTHBOOK_KEYS } from '@constants';
import AppEvent from 'core/event';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

export const useAppEvent = (
  event: keyof typeof EventName,
  callback: EventListenerOrEventListenerObject
) => {
  useEffect(() => {
    AppEvent.subscribe(EventName[event], callback);

    return () => {
      AppEvent.unsubscribe(EventName[event], callback);
    };
  }, [callback, event]);
};

export const useAppVersionCheck = () => {
  const remoteVersion = useFeatureValue(GROWTHBOOK_KEYS.VERSION, {
    version: 1,
    isImportant: false
  });

  useEffect(() => {
    const currentVersion = Number(process.env.REACT_APP_VERSION) || 1;

    if (currentVersion < (remoteVersion?.version ?? 1)) {
      const message = !!remoteVersion?.isImportant
        ? 'remote_version_important_message'
        : 'remote_version_message';

      UIUtils.alert.open({
        status: 'info',
        message: trans(message),
        buttons: !!remoteVersion?.isImportant
          ? [
              {
                title: trans('refresh_now'),
                kind: 'primary',
                color: KColors.danger.normal,
                onPress: () => {
                  window.location.href = `${window.location.origin}${window.location.pathname}?v=${remoteVersion.version}`;
                }
              }
            ]
          : [
              {
                title: trans('ok'),
                kind: 'primary',
                color: KColors.warning.normal,
                onPress: () => {
                  window.location.href = `${window.location.origin}${window.location.pathname}?v=${remoteVersion.version}`;
                }
              },
              {
                title: trans('later')
              }
            ]
      });
    }
  }, [remoteVersion]);
};
