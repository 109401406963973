import { ColDef } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';

import { WebTable } from '@constants';
import { BottomActions } from 'components';
import AgTable from 'containers/AgTable';
import useAgTable from 'hooks/core/useAgTable';
import { useFetchInvoiceFromWipList, useSubmitInvoiceWipData } from 'hooks/wip';
import trans from 'translation';
import { UIUtils } from 'utils';
import AgUtils from 'utils/ag';

export interface IProps {
  isAr?: boolean;
  mParams: any;
  cb?: (d: any[]) => void;
}

const FromWip = ({ isAr = true, mParams, cb }: IProps) => {
  const colDefs = useMemo<ColDef<any>[]>(() => {
    return [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        filter: false,
        sortable: false,
        lockVisible: true,
        maxWidth: 51,
        colId: 'isChecked'
      },
      {
        headerName: trans('charge_code'),
        field: 'chargeCode.code'
      },
      {
        headerName: trans('description'),
        field: 'description',
        minWidth: 180
      },
      {
        colId: 'clientCode',
        headerName: trans(!isAr ? 'ap_code' : 'ar_code'),
        field: !isAr ? 'apClient' : 'arClient',
        type: 'code'
      },
      {
        colId: 'clientName',
        headerName: trans(!isAr ? 'ap_name' : 'ar_name'),
        field: !isAr ? 'apClient' : 'arClient',
        type: 'name'
      },
      {
        headerName: trans('cont_size'),
        field: 'containerTypeSize.code',
        minWidth: 120
      },
      {
        headerName: trans('level_type'),
        field: 'chargeCode.levelType',
        hide: true,
        ...AgUtils.options.chip()
      },
      {
        headerName: trans('calculator_type'),
        field: 'chargeCode.calculatorType',
        width: 117,
        hide: true,
        ...AgUtils.options.chip()
      },
      {
        headerName: trans('currency'),
        field: 'currency.code',
        minWidth: 110,
        initialWidth: 110
      },
      {
        headerName: trans('unit'),
        field: 'chargeCodeUnit.code',
        minWidth: 110,
        initialWidth: 110
      },
      {
        headerName: trans('unit_price'),
        field: 'unitPrice',
        type: 'numberAlignRight'
      },
      {
        headerName: trans('quantity'),
        field: 'quantity',
        type: 'numberWoPad'
      },
      {
        headerName: trans('est_sub_total'),
        field: 'estSubTotal',
        type: 'numberAlignRight'
      },
      {
        headerName: trans('with_unit.tax'),
        field: 'taxType.defaultRate',
        minWidth: 100,
        initialWidth: 100
      },
      {
        headerName: trans('tax_amount'),
        field: 'taxAmount',
        type: 'numberAlignRight'
      },
      {
        headerName: trans('est_total_amount'),
        field: 'estTotal',
        type: 'numberAlignRight'
      },
      {
        headerName: trans('created_by'),
        field: 'createdUsername'
      },
      {
        headerName: trans('updated_by'),
        field: 'updatedUsername'
      },
      {
        headerName: trans('created_at'),
        field: 'createdAt',
        type: 'vnDateTime'
      },
      {
        headerName: trans('updated_at'),
        field: 'updatedAt',
        type: 'vnDateTime'
      },
      {
        headerName: trans('note'),
        field: 'note',
        type: 'note'
      }
    ];
  }, [isAr]);

  const { data, refetch, isLoading } = useFetchInvoiceFromWipList({
    isAr,
    mParams
  });

  const table = useAgTable({
    name: WebTable.LOCAL_INVOICE_FROM_WIP,
    data,
    colDefs,
    onAddParams: {
      hidden: true
    }
  });

  const { mutateAsync, isLoading: submitLoading } = useSubmitInvoiceWipData();

  const onFormValid = useCallback(async () => {
    const d = table.onGetSelectedData?.() ?? [];
    if (d.length > 0) {
      const mData = await mutateAsync({
        data: d,
        currencyId: mParams.currencyId,
        isAr,
        exRateDate: mParams.exRateDate,
        isVND: mParams.isVND
      });

      if (!isEmpty(mData)) {
        cb?.(mData);
        UIUtils.popup.dismiss();
      }
    }
  }, [
    cb,
    isAr,
    mParams.currencyId,
    mParams.exRateDate,
    mParams.isVND,
    mutateAsync,
    table
  ]);

  return (
    <>
      <AgTable
        {...table}
        showSearch
        onRefresh={refetch}
        isLoading={isLoading}
      />

      <BottomActions
        btnProps={[
          {
            title: trans('submit'),
            onPress: onFormValid,
            isLoading: submitLoading,
            disabled: isLoading
          }
        ]}
      />
    </>
  );
};

export default memo(FromWip);
