import { IConsol } from '@dto';
import {
  IModifyLoadingDischargeDetails,
  IModifyConsolTransport
} from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager, { END_POINTS } from '@services';
import { Prototype } from 'core';
import { formatSummary } from 'utils/common';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './shipment';
export * from './routing';
export * from './container';
export * from './accounting';

export const useFetchConsol = (id?: number | string) => {
  return useQueryEnhancer<IConsol | undefined>({
    queryKey: [QUERY_KEYS.consol, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id', { id }),
        body: {
          includeFields: ['freightPayment', 'flight', 'vessel']
        }
      });

      return Prototype.response.normalizeData(res.data, 'consolNumber');
    },
    enabled: !!id
  });
};

export const useCUDConsol = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.consol(),
    webTable: WebTable.OF_CONSOL,
    dependentWebTable: [WebTable.AF_CONSOL],
    queryKey: QUERY_KEYS.consol
  });
};

export const useModifyLoadingDischargeDetails = (isConsol?: boolean) => {
  const queryClient = useQueryClient();

  return useMutationEnhancer<any, IModifyLoadingDischargeDetails>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: isConsol
          ? ENDPOINTS.consol(':id/routing-info', { id: data.id })
          : ENDPOINTS.shipment(':id/routing-info', { id: data.id }),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          {
            queryKey: [isConsol ? QUERY_KEYS.consol : QUERY_KEYS.shipment],
            type: 'active'
          },
          data
        );
      }
    }
  });
};

export const useFetchConsolDataForBreadcrumbs = (options: {
  id?: string | number;
  containerId?: string | number;
  shipmentId?: string | number;
}) => {
  const { id, containerId, shipmentId } = options;

  const { data: consol } = useQueryEnhancer<IConsol | undefined>({
    queryKey: [QUERY_KEYS.consol],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });

  const { data: shipment } = useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipment],
    queryFn: async () => {
      if (shipmentId) {
        const res = await APIManager.request({
          url: ENDPOINTS.shipment(':id', { id: shipmentId })
        });
        return res.data;
      }
      return null;
    }
  });

  const { data: shipmentContainer } = useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.shipmentContainer,
      shipmentId ? parseInt(shipmentId as string) : null,
      containerId ? parseInt(containerId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentContainer(':id', { id: containerId, shipmentId })
      });
      return res.data;
    },
    enabled: !!containerId && !!shipmentId
  });

  const { data: consolContainer } = useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.consolContainer,
      id ? parseInt(id as string) : null,
      containerId ? parseInt(containerId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consolContainer(':id', {
          id: containerId,
          consolId: id
        }),
        body: {
          includeFields: ['shipmentContainer']
        }
      });
      return res.data;
    },
    enabled: !!id && !!containerId && !shipmentId
  });

  return {
    consolNo: consol?.consolNumber ?? '',
    shipmentNo: shipment?.shipmentNumber ?? '',
    consolContainerNo: consolContainer?.containerNumber ?? '',
    shipmentContainerNo:
      shipmentContainer?.containerConsol?.containerNumber ?? containerId ?? '',
    shipment: {
      shipmentStatus: shipment?.shipmentStatus,
      bookingClient: shipment?.bookingClient
    }
  };
};

export const useCUDTransport = (webTable: WebTable) => {
  return useCUDMutationEnhancer<IModifyConsolTransport>({
    endPoint: END_POINTS.CONSOL.GET_CONSOL_TRANSPORT,
    webTable: webTable
  });
};

export const useFetchConsolStatistics = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.consolSummary, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':id/summary', { id })
      });

      let val = formatSummary(res.data);

      return val || null;
    },
    enabled: !!id
  });
};

export const useConsolCheckThreshold = (consolId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.consolCheckThreshold, consolId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consol(':consolId/check-threshold', { consolId })
      });

      return res.data || null;
    },
    enabled: !!consolId
  });
};

export const useCUDNotesToClient = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.notesToClient(),
    webTable: WebTable.NOTES_TO_CLIENT
  });
};

export const useFetchContainerTypeSizeWip = ({
  consolId,
  shipmentId,
  serviceId
}: {
  consolId?: string;
  shipmentId?: string;
  serviceId?: string;
}) => {
  const id = consolId || shipmentId || serviceId;
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.containerTypeSizeWip,
      id ? parseInt(id as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.container('container-type-size'),
        body: {
          consolId,
          shipmentId,
          serviceId
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!id
  });
};
