import { cloneDeep, get, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { RouteObject } from 'react-router-dom';

import { LogReportSource } from '@constants';
import { IAdminRoute } from 'routes/helpers';
import { TableUtils } from 'utils';

import { useUser } from '../auth';

export const getCachedTableConfigs = () => {
  const cacheTableConfigs = localStorage.getItem('tableConfigs');
  return cacheTableConfigs ? JSON.parse(cacheTableConfigs) : undefined;
};

export const cacheColumnOrder = (name: string, columnOrder: number[]) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      columnOrder
    }
  };
  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const cacheViewColumns = (
  name: string,
  columnName: string,
  action: string
) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      viewColumns: {
        ...(tableConfigs?.[name]?.viewColumns || {}),
        [columnName]: action === 'remove' ? true : undefined
      }
    }
  };

  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const getColumnOrderFromCache = (
  name: string,
  length: number
): number[] | undefined => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  const { columnOrder } = tableConfigs[name];
  if (columnOrder?.length === length) {
    return columnOrder;
  } else {
    // reset cache if add or delete in columns
    let newColumnOrder = [];
    for (let i = 0; i < length; i++) {
      newColumnOrder.push(i);
    }
    cacheColumnOrder(name, newColumnOrder);
    return newColumnOrder;
  }
};

export const getViewColumnsFromCache = (name: string) => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  return tableConfigs[name]?.viewColumns;
};

export const reorderColumnsBasedOnCache = (
  name: string,
  columns: any[]
): any[] => {
  // const columnOrder = getColumnOrderFromCache(name, columns.length);
  const viewColumns = getViewColumnsFromCache(name);

  if (viewColumns) {
    columns = columns.map(i => {
      if (viewColumns[i.name]) {
        i.options = { ...(i.options || {}), display: false };
      } else {
        i.options = { ...(i.options || {}), display: true };
      }
      return i;
    });
  }

  return columns;

  // if (columnOrder?.length === columns.length) {
  //   return columnOrder.map(i => columns[i]);
  // } else {
  //   return columns;
  // }
};

export const normalizeFinalColumns = (
  columns: any[],
  mappedFields?: {
    [key: string]: string;
  }
) => {
  return columns.map(i => ({
    ...i,
    mappedName: get(mappedFields, i.name, i.name),
    options: ['createdAt', 'updatedAt'].includes(i.name)
      ? { ...TableUtils.options.date(), ...i.options }
      : ['createdUsername', 'updatedUsername'].includes(i.name)
      ? { ...TableUtils.options.baseXLg, ...i.options }
      : // : ['remark', 'description'].includes(i.name)
        // ? { ...TableUtils.options.withEllipsis, ...i.options }
        { ...TableUtils.options.base, ...i.options },
    allowedToSort: i.options?.sort ?? true
  }));
};

export const useHasPermission = (p?: string) => {
  const { userPers, isAdmin, isBranchAdmin } = useUser();

  const _isAdmin = isAdmin || isBranchAdmin;

  return p ? _isAdmin || userPers?.[p] : _isAdmin;
};

const ADMIN_SPECIAL_MENUS = ['Administration', 'System Settings'];

export const setupAdminRoutes = (
  d?: IAdminRoute[],
  userData?: any
): RouteObject[] => {
  const { isAdmin, isBranchAdmin, userPers } = userData || {};

  if (!d) {
    return [];
  }

  if (isAdmin || isBranchAdmin) {
    return d as RouteObject[];
  }

  const arr = cloneDeep(
    d.filter(i => !i.name || (i.name && !ADMIN_SPECIAL_MENUS.includes(i.name)))
  );

  const data = arr.filter(i => {
    const hasPer = (i.code ?? []).some((x: string) => !!userPers?.[x]);

    if (i.code && !hasPer) {
      return false;
    }

    if (!isEmpty(i.children)) {
      i.children = setupAdminRoutes(i.children, userData);
    }

    return true;
  });

  return data as RouteObject[];
};

export const useListLogs = (
  permissions: any,
  conditions: { label: string; code: string }[],
  tabs: any,
  resPer: any
) => {
  const tabCodes = tabs.flatMap((tab: any) => tab.code || []);
  const objTabCodes = Object.fromEntries(
    tabCodes.map((key: any) => [key, true])
  );
  const objStaffPermission = useMemo(() => {
    if (!permissions) return {};
    const flatPermissions = Object.fromEntries(
      (permissions?.permissionGroup ?? [])
        .flatMap((group: any) => group.permissionSubGroup)
        .flatMap((subGroup: any) =>
          subGroup.permissionSubSubGroup.map((permission: any) => [
            permission,
            subGroup.controlBy
          ])
        )
    );

    return flatPermissions;
  }, [permissions]);

  if (permissions?.permissionGroup) {
    return conditions.reduce((acc, { label, code }) => {
      let isTabCodeMatches = objTabCodes[code];
      if (!isTabCodeMatches) return acc;

      const { permissionsInGroup, accessLevel } = getAccessLevelFromPermissions(
        objStaffPermission,
        code
      );

      if (resPer?.[code] && permissionsInGroup.length > 0) {
        acc[label] = accessLevel;
      }

      return acc;
    }, {} as { [key: string]: string });
  }
};

export const getAccessLevelFromPermissions = (
  objStaffPermission: any,
  code: string
): { permissionsInGroup: string[]; accessLevel: string } => {
  let permissionsInGroup: string[] = [];
  let accessLevel = 'ALL';

  if (code.endsWith('_LIST') || code.endsWith('_DETAIL')) {
    const detailCode = code.endsWith('_LIST')
      ? code.replace('_LIST', '_DETAIL')
      : code;

    if (objStaffPermission[detailCode]) {
      permissionsInGroup.push(objStaffPermission[detailCode]);
      accessLevel = objStaffPermission[detailCode];
    }
  }
  return { permissionsInGroup, accessLevel };
};

export const useJsonListLogs = (
  conditions: any[],
  tabs: any[],
  source?: LogReportSource
) => {
  const { userPers, isAdmin, isBranchAdmin } = useUser();

  // const { data: permissions } = useFetchPermissionForLogList();
  const permissions: any[] = [];

  const listLogs = useListLogs(permissions, conditions, tabs, userPers);

  const jsonListLogs =
    isAdmin || isBranchAdmin ? source || '' : JSON.stringify(listLogs);

  return jsonListLogs;
};
