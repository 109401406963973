export default {
  delete: 'Are you sure want to delete this {{nameScreen}}?',
  delete_global: 'Are you sure want to delete this {{name}}?',
  delete_global_plural: 'Are you sure want to delete these {{name}}(s/es)?',
  delete_client: 'Are you sure want to delete this client?',
  delete_note: 'Are you sure want to delete this note?',
  delete_note_plural: 'Are you sure want to delete these notes?',
  delete_plural: 'Are you sure want to delete these clients?',
  delete_address: 'Are you sure want to delete this address?',
  delete_address_plural: 'Are you sure want to delete these addresses?',
  delete_contact: 'Are you sure want to delete this contact?',
  delete_contact_plural: 'Are you sure want to delete these contacts?',
  delete_related_party: 'Are you sure want to delete this related party?',
  delete_related_party_plural:
    'Are you sure want to delete these related parties?',
  delete_consol: 'Are you sure want to delete this booking?',
  delete_consol_plural: 'Are you sure want to delete these bookings?',
  duplicate_consol:
    'Are you sure want to duplicate this consol? The status of new consol is DRAFT',
  duplicate_shipment:
    'Are you sure to duplicate this shipment? The status of new shipment is DRAFT.',
  duplicate_consol_plural:
    'Are you sure want to duplicate these consols? The status of new consols is DRAFT',
  copy_branch_consol:
    'Are you sure to copy this consol to another branch? The status of new consol is DRAFT',
  cancel_consol:
    'Are you sure to cancel this booking? This action cannot be undone',
  delete_routing: 'Are you sure to delete this transhipment trip?',
  cancel_shipment:
    'Are you sure to cancel this shipment? This action cannot be undone',
  detach_shipment:
    'Are you sure to detach this shipment from this consolidation?',
  duplicate_container:
    'Are you sure want to duplicate this container? The status of new container is DRAFT',
  duplicate_container_plural:
    'Are you sure want to duplicate these containers? The status of new containers is DRAFT',
  cancel_container:
    'Are you sure to cancel this container? This action cannot be undone',

  warning_shipment:
    'The A/R credit application of this booking client has not been approved. Are you sure to continue?',
  warning_shipment_account_receivable:
    'The A/R credit application of this Account Receivable has not been approved. Are you sure to continue?',

  duplicate_job_order:
    'Are you sure want to duplicate this job order? The status of new job order is DRAFT',

  err_attach_shipment: 'You cannot attach shipments to this consolidation.',
  warning_client:
    'Another client is already using this name. Are you sure to create/update client with the same name?',

  err_ref_dang: 'There must be at least 1 row with field {{refDang}}',
  can_not_add_penalty: 'Can not add Penalty.',
  duplicate_sub_charge_code: 'Duplicate Sub Charge Code',
  warning_sub_total:
    'Warning: Sub total is smaller than min selling price. Are you sure to continue?',
  update_charge_code_list:
    'Charge code list will be cleared. Are you sure to update?',
  warning_change_invoice_type:
    'Please save {{type}} Invoice before add new charge code',
  one_record_sub_total:
    'Charge codes have Sub Total = 0 is not be added to credit note. Are you sure to continue?',
  deduplicated_wip: 'Deduplicated Wip',
  damage_quantity: 'Less than or equal to Outer Quantity - Outturn Quantity',
  service_delete_routing: 'Are you sure to delete this routing?',
  service_delete_transport_service:
    'Are you sure to delete this transport service?',
  service_delete_handling_service:
    'Are you sure to delete this handling service?',
  service_delete_customs_service:
    'Are you sure to delete this customs service?',

  warning_shipment_threshold:
    'Warning: Estimated profit of this shipment is lower than expected threshold',

  warning_consol_threshold1:
    'Warning: Estimated profit of this consolidation is lower than expected threshold.',
  warning_consol_threshold2:
    'Warning: Estimated profit of shipments {{list}} in this consolidation are lower than expected threshold.',

  warning_service_threshold:
    'Warning: Estimated profit of this service is lower than expected threshold',

  no_duplicate_client: 'Cannot choose two different clients',

  selected_not_changed:
    'Adjustment, PaymentAdvice, ReceivableAdvice can not be changed',
  delete_ar_invoice: 'Are you sure want to delete this invoice?',
  delete_payment_advice_invoice:
    'Are you sure want to delete this payment advice?',
  delete_receivable_advice_invoice:
    'Are you sure want to delete this receivable advice?',
  unreconciled: 'Do you want to undo reconcile?',
  unit_price_is_less_than_min_selling_price:
    'Unit price is less than min selling price.',
  service_delete_commercial_invoice:
    'Are you sure to delete this commercial invoice?',
  message_equal_quantity: 'Sum quantity and total quantity must be equal',
  message_number: 'This is type Number',
  all_charge_codes_will_be_deleted: 'All charge codes will be deleted',
  all_invoice_will_be_deleted: 'All invoices will be deleted',
  warning_profit_below_minium: 'Warning profit below minium',
  ref_number: 'Ref. Number',
  err_misa:
    'Sync Invoice To Misa failed. The Invoice Status must be Approved, Issued or Complete',
  err_not_selected_booking_client: 'The booking client has not been selected',
  do_you_want_to_change_status_to_active:
    'Do You Want To Change Status To Active',
  do_you_want_to_change_status_to_cancel:
    'Do You Want To Change Status To Cancel',
  do_you_want_to_change_status_invoice_to_issue:
    'Do You Want To Change Status Invoice To Issue',
  all_statement_will_be_deleted: 'All statements will be deleted',
  do_you_want_to_create_soa_invoice: 'Do You Want Create SOA Invoice',
  message_warning_cancel_invoice:
    'Are You Sure You Want To Cancel This Invoice',
  normal_balance: 'Normal Balance',
  chart_of_account: 'Chart Of Account must be selected',
  do_you_want_to_generate_statement_invoice:
    'Do You Want to Generate Statement Invoice',
  statement_invoice_must_not_be_empty: 'Statement Invoice Must Not Be Empty',
  are_you_cancel_job_order: 'Are You Cancel Job Order',
  send_review_request: 'Send Review Request?',
  no_job: 'There are no person in charge to submit',
  some_fields_are_empty_please_check_again:
    'Some fields are empty, please check again',
  resolve_issue: 'Do you want to resolve issue',
  do_not_select_all:
    'Note: Do not select all, there must be at least 1 row left',
  warning_attach_etd:
    'Warning! Consol and shipment POL ETDs differ. Proceeding will update shipment ETD to match consol ETD',
  least_one_shipment: 'You need to select at least 1 shipment',
  err_new_client:
    'Error: Unable to create a new client with the provided information. A client with these details already exists',
  created_date_less_than_equal_current_date:
    'creation date must be less than or equal to current date',
  pic_export_import_shipment:
    'Warning: Shipment fees detected; no IM/EX PIC assigned. Please recheck',
  pic_transport_customs_shipment:
    'Warning: Customs/Transport fees detected; no Logistics PIC assigned. Please recheck.',
  cannot_vietnamese_characters:
    'ContractNo cannot contain some special characters',
  required_fields_are_missing: 'Cannot save: Required fields are missing.'
};
