import { IModifyConsolPenalty, IModifyPackingDetails } from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import {
  ENDPOINTS,
  OriginDestinationType,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager, { END_POINTS } from '@services';
import { Prototype } from 'core';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchShipmentContainerList = (shipmentId?: string | number) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.listShipmentContainer, shipmentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentContainer('', { shipmentId }),
        body: {
          includeFields: [
            'pol',
            'pod',
            'containerConsol',
            'containerTypeSize',
            'shipment'
          ]
        }
      });
      return res.data?.data ?? [];
    },
    enabled: !!shipmentId
  });
};

export const useFetchShipmentService = (
  shipmentId?: string | number,
  transportServiceType?: OriginDestinationType,
  serviceId?: string | number
) => {
  const pathDetails = serviceId ? ':serviceId' : '';
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.shipmentService,
      shipmentId,
      transportServiceType,
      serviceId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentService(pathDetails, { shipmentId, serviceId }),
        body: {
          includeFields: ['bookingClient'],
          transportServiceType
        }
      });
      return res.data?.data ?? null;
    },
    enabled: !!shipmentId && !!transportServiceType,
    cacheTime: 0
  });
};

export const useFetchShipmentContainer = (
  id?: string | number | null,
  shipmentId?: string | number
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.shipmentContainer,
      id ? parseInt(id as string) : null,
      shipmentId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentContainer(':id', { id, shipmentId })
      });
      return Prototype.response.normalizeData(
        res.data,
        'containerConsol.containerNumber'
      );
    },
    enabled: !!id && !!shipmentId
  });
};

export const useFetchConsolContainerList = (
  consolId?: string | number | null
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.listConsolContainer, consolId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consolContainer('', { consolId }),
        body: {
          includeFields: [
            'pol',
            'pod',
            'containerTypeSize',
            'polCarrierPolicy',
            'containerInShipment',
            'podCarrierPolicy',
            'shipmentContainer'
          ]
        }
      });
      return res.data?.data ?? null;
    },
    enabled: !!consolId
  });
};

export const useFetchConsolContainer = (
  id?: string | number | null,
  consolId?: string | number | null,
  enabled: boolean = true
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.consolContainer,
      id ? parseInt(id as string) : null,
      consolId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.consolContainer(':id', { id, consolId }),
        body: {
          includeFields: ['shipmentContainer', 'pol', 'pod']
        }
      });
      return Prototype.response.normalizeData(res.data, 'containerNumber');
    },
    enabled: !!id && !!consolId && enabled
  });
};

export const useFetchConsolOrShipmentContainer = ({
  screenType,
  id,
  consolIdOrshipmentId,
  enabled
}: {
  screenType: 'shipment' | 'consol';
  id?: string | number;
  consolIdOrshipmentId?: string | number;
  enabled?: boolean;
}) => {
  const url =
    screenType === 'shipment'
      ? ENDPOINTS.shipmentContainer(':id', {
          id,
          shipmentId: consolIdOrshipmentId
        })
      : ENDPOINTS.consolContainer(':id', {
          id,
          consolId: consolIdOrshipmentId
        });
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.consolContainer,
      id ? parseInt(id as string) : null,
      consolIdOrshipmentId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: url,
        body: {
          includeFields: ['shipmentContainer', 'pol', 'pod']
        }
      });
      return Prototype.response.normalizeData(res.data, 'containerNumber');
    },
    enabled: !!id && !!consolIdOrshipmentId && enabled
  });
};

export const useCUDContainer = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: END_POINTS.CONSOL.GET_SHIPMENT_CONTAINER_LIST,
    webTable: WebTable.SHIPMENT_CONTAINER,
    dependentWebTable: [WebTable.CONTAINER_PACKAGE_LIST],
    queryKey: QUERY_KEYS.shipmentContainer
  });
};

export const useCUDConsolContainer = (
  id?: string | number,
  consolId?: string | number
) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.consolContainer('', { id, consolId }),
    webTable: WebTable.CONSOL_CONTAINER,
    dependentWebTable: [WebTable.CONTAINER_PACKAGE_LIST],
    queryKey: QUERY_KEYS.consolContainer
  });
};

export const useFetchContainerDate = (id?: number | string | null) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.containerConsolDate, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(`${END_POINTS.CONSOL.GET_DATE_CONSOL_CONTAINER}`, {
          containerConsolId: id
        })
      });
      return res.data ?? null;
    },
    enabled: !!id
  });
};

export const useUpdateContainerDate = (id?: number | string | null) => {
  const queryClient = useQueryClient();
  const mutation = useMutationEnhancer<any, any>({
    mutationFn: async body => {
      const res = await APIManager.request({
        url: generatePath(`${END_POINTS.CONSOL.GET_DATE_CONSOL_CONTAINER}`, {
          containerConsolId: id
        }),
        method: 'PUT',
        showToast: true,
        body
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          { queryKey: [QUERY_KEYS.containerConsolDate], type: 'active' },
          data
        );
      }
    },
    invalidateQueryKeys: [[QUERY_KEYS.consolContainer]]
  });

  return {
    updateMutation: mutation.mutate,
    isLoading: mutation.isLoading
  };
};

export const useCUDPackingDetails = (containerId?: number | string) => {
  return useCUDMutationEnhancer<IModifyPackingDetails>({
    mutationKeys: [[QUERY_KEYS.shipmentContainer]],
    endPoint: ENDPOINTS.container(':id/package', { id: containerId ?? '' }),
    webTable: WebTable.CONTAINER_PACKAGE_LIST
  });
};

export const useMutateFetchContainerPenaltyDetails = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.containerPenalty(
          'container-consol/:containerId/:id',
          data
        )
      });

      return res.data;
    }
  });
};

export const useCUDPenaltyDetails = (containerId?: number | string) => {
  return useCUDMutationEnhancer<IModifyConsolPenalty>({
    endPoint: ENDPOINTS.containerPenalty('container-consol/:containerId', {
      containerId: containerId ?? ''
    }),
    webTable: WebTable.CONTAINER_PENALTY_LIST
  });
};
