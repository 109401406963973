import { IModifyBranchSetting } from '@request-dto';
import { isEmpty } from 'lodash';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../../core';

export const useFetchBranchSettings = (
  branchId?: number | string,
  onSuccess?: (d: any) => void
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.branchSetting, branchId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.branchSetting(),
        body: {
          branchId,
          includeFields: ['autoExchangeTime', 'autoExchangeErrorNotify']
        }
      });

      if (!isEmpty(res.data)) {
        return {
          ...res.data,
          currency: res.data.primaryCurrency
        };
      }

      return res.data;
    },
    onSuccess: d => {
      onSuccess?.(d);
    },
    enabled: !!branchId
  });
};

export const useCUDBranchSettings = () => {
  return useCUDMutationEnhancer<IModifyBranchSetting>({
    endPoint: ENDPOINTS.branchSetting()
  });
};

export const useCUDTermsOfCondition = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.termsOfConditions(),
    webTable: WebTable.TERMS_OF_CONDITION
  });
};
