import { get } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { growthBook } from '@constants';
import { useMount } from 'uikit-common';

import { useUser } from '../auth';

export const useSyncGrowthbook = () => {
  const { account } = useUser();
  const isMounted = useRef(false);
  const [isMaintained, setIsMaintained] = useState(false);

  const effect = useCallback(async () => {
    try {
      if (!isMounted.current) {
        isMounted.current = true;
      }

      console.log('GROWTHBOOK INIT');

      const res = await growthBook.init({
        skipCache: true,
        timeout: 2000,
        streaming: true
      });

      if (!res.success) {
        console.log('GROWTHBOOK INIT FAILED', res.source);
        return;
      }

      console.log('GROWTHBOOK INIT SUCCESSFULLY');
    } catch (error) {
      console.log('ERROR WHEN GROWTHBOOK INIT');
      console.warn(error);
    }
  }, []);

  useMount(() => {
    effect();
  });

  useEffect(() => {
    console.log('GROWTHBOOK SET ATTRIBUTES');
    growthBook.setAttributes({
      id: get(account, 'id'),
      email: get(account, 'email')
      // company: get(account, 'companyId')
    });
  }, [account]);

  return { isMaintained };
};
