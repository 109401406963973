import { ColTypeDef, ValueFormatterParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { DATE_FORMAT, DATE_FORMAT_SHORT } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

const dateTimeFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT);
};

const dateFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT_SHORT);
};

const dateTimeTzFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toTz(v)?.format(DATE_FORMAT);
};

const dateTzFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toTz(v)?.format(DATE_FORMAT_SHORT);
};

const numberFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return isNumber(v) ? Prototype.number.formatNumber(v) : '';
};

const numberMaxPrecisionFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return isNumber(v)
    ? Prototype.number.formatNumber(v, { maxPrecision: true, pad: false })
    : '';
};

const numberWoPadFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return isNumber(v) ? Prototype.number.formatNumber(v, { pad: false }) : '';
};

const integerFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return isNumber(v) ? Prototype.number.formatNumber(v, { precision: 0 }) : '';
};

const optionFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v ? trans(`option.${v.toLowerCase()}`).toUpperCase() : '';
};

const transFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v ? trans(v.toLowerCase()).toUpperCase() : '';
};

const codeFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.code ?? '';
};

const nameFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.name ?? '';
};

const nameDPFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.displayName ?? '';
};

const taxFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.defaultRate ?? 0;
};

const comparator = (key: string) => (a: any, b: any) => {
  const keyA = a?.[key];
  const keyB = b?.[key];

  if (!keyA) {
    return !keyB ? 0 : -1;
  }
  if (!keyB) {
    return 1;
  }

  if (keyA < keyB) {
    return -1;
  } else if (keyA > keyB) {
    return 1;
  }

  return 0;
};

const chargeCodeValueFormatter = ({ value }: ValueFormatterParams<any>) => {
  return value ? `${value?.code} - ${value?.name}` : '';
};

export const useColTypes = <T>() => {
  return useMemo(() => {
    return {
      vnDate: {
        valueFormatter: dateFormatter
      },
      vnDateTime: {
        valueFormatter: dateTimeFormatter
      },
      tzDate: {
        valueFormatter: dateTzFormatter
      },
      tzDateTime: {
        valueFormatter: dateTimeTzFormatter
      },
      trans: {
        valueFormatter: transFormatter
      },
      number: {
        valueFormatter: numberFormatter
      },
      numberAlignRight: {
        valueFormatter: numberFormatter,
        cellClass: 'text-right'
      },
      exchangeRate: {
        valueFormatter: numberMaxPrecisionFormatter,
        cellEditorParams: {
          maxPrecision: 12
        },
        cellClass: 'text-right'
      },
      numberWoPad: {
        valueFormatter: numberWoPadFormatter,
        minWidth: 110,
        initialWidth: 110
      },
      integer: {
        valueFormatter: integerFormatter,
        cellEditorParams: {
          maxPrecision: 0
        }
      },
      name: {
        minWidth: 200,
        valueFormatter: nameFormatter,
        comparator: comparator('name')
      },
      nameDP: {
        minWidth: 180,
        valueFormatter: nameDPFormatter,
        comparator: comparator('displayName')
      },
      code: {
        valueFormatter: codeFormatter,
        comparator: comparator('code')
      },
      tax: {
        valueFormatter: taxFormatter,
        comparator: comparator('defaultRate')
      },
      note: {
        cellClass: 'line-clamp-2'
      },
      codeName: {
        valueFormatter: chargeCodeValueFormatter
      }
    } as { [key: string]: ColTypeDef<T> };
  }, []);
};
