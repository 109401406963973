import { IReadUnreadNotificationParams } from '@request-dto';

import { ENDPOINTS, QUERY_KEYS, NotificationStatus } from '@constants';
import APIManager from '@services';
import { useMutationEnhancer, useQueryEnhancer } from 'hooks/core';

interface IIparams {
  receiveStaffId?: string | number;
  readStatus?: NotificationStatus;
}

export const useFetchNotificationLog = (params: IIparams) => {
  return useQueryEnhancer<any[]>({
    queryKey: [
      QUERY_KEYS.notificationLog,
      params?.receiveStaffId,
      params?.readStatus
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification(),
        body: params
      });

      return res.data?.data ?? [];
    },
    enabled: !!params?.receiveStaffId
  });
};

export const useCUDNotificationLog = () => {
  return useMutationEnhancer<
    boolean | undefined,
    IReadUnreadNotificationParams
  >({
    mutationFn: async ({ type, params }) => {
      let url = '';
      switch (type) {
        case 'read':
          url = ENDPOINTS.notification(':id/read', {
            id: params?.id
          });
          break;
        case 'un-read':
          url = ENDPOINTS.notification('un-read');
          break;
        case 'read-all':
          url = ENDPOINTS.notification('read-all');
          break;
      }

      const res = await APIManager.request({
        url,
        method: 'POST',
        body: params
      });

      return res.success;
    },
    invalidateQueryKeys: [[QUERY_KEYS.notificationLog]]
  });
};
