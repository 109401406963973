import { IDraftVatInvoice, ITemplate } from '@invoice';
import { NoteOptions } from '@ui';
import { kebabCase } from 'lodash';

import { ENDPOINTS, QUERY_KEYS, SourceType } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchEmailList = (options: {
  source: SourceType;
  queryKey: string;
  parentId?: number | string;
}) => {
  const { source, parentId, queryKey } = options;
  return useQueryEnhancer<any[]>({
    queryKey: [queryKey, parentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.email('', {
          source: source.toLowerCase(),
          id: parentId
        })
      });
      return res.data?.data ?? [];
    },
    enabled: !!parentId
  });
};

export const useCUDEmail = (options: NoteOptions) => {
  const { source, parentId, ...rest } = options;
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.email('', {
      source: kebabCase(source),
      id: parentId
    }),
    ...rest
  });
};

export const useRenderTemplate = () => {
  return useMutationEnhancer<
    BlobPart,
    {
      template?: ITemplate;
      draftVatInvoice?: IDraftVatInvoice;
      renderFileExtension: 'PDF' | 'DOCX';
    }
  >({
    mutationFn: async ({ draftVatInvoice, template, renderFileExtension }) => {
      let url = ENDPOINTS.template('render');
      let method: 'POST' | 'GET' = 'POST';
      if (draftVatInvoice) {
        url = ENDPOINTS.arInvoiceDraftVat(draftVatInvoice.screen);
        method = 'GET';
      }
      const res = await APIManager.request({
        url,
        method,
        body: {
          ...template,
          ...draftVatInvoice,
          renderFileExtension
        },
        configs: {
          responseType: 'blob'
        }
      });
      return res.data;
    },
    invalidateQueryKeys: [
      [QUERY_KEYS.arInvoice],
      [QUERY_KEYS.statementInvoice]
    ],
    onSuccess: (data, { renderFileExtension }) => {
      const type =
        renderFileExtension === 'PDF'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      if (data) {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type
          })
        );
        window.open(url);
      }
    }
  });
};
