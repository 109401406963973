import Avatar from '@mui/material/Avatar';
import React, { memo, useMemo } from 'react';

import KImage from './Base';

import KTouchable from '../Container/Touchable';
import KView from '../Container/View';
import { KAvatarProps } from '../types';

const KAvatar = (props: KAvatarProps) => {
  const {
    size,
    onPress,
    source,
    alt,
    variant = 'circular',
    children,
    width,
    ...rest
  } = props;

  const mSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return 24;
      case 'sm':
        return 32;
      case 'lg':
        return 48;
      case 'xlg':
        return 56;
      case '2xlg':
        return 64;
      case '3xlg':
        return 96;
      case '4xlg':
        return 112;
      default:
        return 40;
    }
  }, [size]);

  const commonStyle = useMemo(() => {
    const style = { width: width || mSize, height: width || mSize };
    return style;
  }, [mSize, width]);

  const Wrapper = onPress ? KTouchable : KView;

  return (
    <Wrapper onPress={onPress} br="round" {...rest}>
      <Avatar src={source} alt={alt} variant={variant} style={commonStyle}>
        {children || (
          <KImage
            source={'/default_avatar.png'}
            width={width || mSize}
            height={width || mSize}
            dp="flex"
            center
          />
        )}
      </Avatar>
    </Wrapper>
  );
};

KAvatar.displayName = 'KAvatar';

export default memo(KAvatar);
