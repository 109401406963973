import loadable from '@loadable/component';
import { kebabCase } from 'lodash';
import pMinDelay from 'p-min-delay';
import React from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';

import { AdviceType, GroupAccounting, SRMChargeCodeSource } from '@constants';
import AccountingLayout from 'pages/Accounting/DetailsLayout';

import { IAdminRoute } from './helpers';

const Dashboard = loadable(() =>
  pMinDelay(import('pages/Accounting/DashBoard'), 200)
);

const SalesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/DetailsLayout'), 200)
);

const SalesArInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/ArInvoice'), 200)
);

const ArInvoiceNoJobDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/ArInvoiceDetailNoJob'), 200)
);

const SalesReceivableAdvice = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/ReceivableAdvice'), 200)
);

const SalesHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/LogWrapper'), 200)
);

const PurchasesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/DetailsLayout'), 200)
);

const PurchasesApInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/ApInvoice'), 200)
);

const ApInvoiceNoJobDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/ApInvoiceDetailNoJob'), 200)
);

const PurchasesPaymentAdvice = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/PaymentAdvice'), 200)
);

const PurchasesHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/LogWrapper'), 200)
);

const StatementLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/DetailsLayout'), 200)
);

const StatementOfAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementOfAccount'), 200)
);

const StatementOfAccountDetails = loadable(() =>
  pMinDelay(
    import('pages/Accounting/Statement/StatementOfAccount/Details'),
    200
  )
);

const StatementPayment = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment'), 200)
);

const StatementPaymentDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment/Details'), 200)
);

const StatementInvoices = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices'), 200)
);

const StatementInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices/Details'), 200)
);

const StatementHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/LogWrapper'), 200)
);

const MoreLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/More/DetailsLayout'), 200)
);

const MoreChartOfAccounts = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ChartOfAccounts'), 200)
);

const MoreManageLinkedAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ManageLinkedAccount'), 200)
);

const MoreTaxCodes = loadable(() =>
  pMinDelay(import('pages/Accounting/More/TaxCode'), 200)
);

const MoreHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/More/LogWrapper'), 200)
);

const BankingLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/DetailsLayout'), 200)
);

const BankingBankAccounts = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/BankAccounts'), 200)
);

const BankingBankTransactions = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/BankTransactions'), 200)
);

const BankingSpendReceiveMoney = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/SpendReceiveMoney'), 200)
);

const BankingSpendReceiveMoneyDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/SpendReceiveMoney/Details'), 200)
);

const BankingHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Banking/LogWrapper'), 200)
);

const AccountingAdviceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Advice/Details'), 200)
);

const Report = loadable(() =>
  pMinDelay(import('pages/Accounting/Report'), 200)
);

const accountingRoutes: IAdminRoute = {
  name: 'Accounting',
  path: 'accounting',
  menu: [
    GroupAccounting.ACCOUNTING_DASHBOARD_DETAIL,
    GroupAccounting.ACCOUNTING_AR_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE_LIST,
    GroupAccounting.ACCOUNTING_AP_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_PAYMENT_ADVICE_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_OF_ACCOUNT_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_PAYMENT_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST,
    GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST,
    GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST
  ],
  element: <AccountingLayout />,
  icon: 'calculate',
  noChildren: true,
  children: [
    {
      parentKey: 'accounting',
      name: 'Dashboard',
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      parentKey: 'accounting',
      name: 'Sales',
      path: 'sales',
      element: <SalesLayout />,
      children: [
        {
          index: true,
          element: <SalesArInvoice />
        },
        {
          path: 'new',
          element: <ArInvoiceNoJobDetails key="new" />
        },
        {
          path: ':arInvoiceId',
          element: <ArInvoiceNoJobDetails key=":arInvoiceId" />
        },
        {
          path: 'receivable-advice',
          children: [
            {
              index: true,
              element: <SalesReceivableAdvice />
            },
            {
              path: ':receivableAdviceId',
              element: (
                <AccountingAdviceDetails
                  key=":receivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            },
            {
              path: 'new',
              element: (
                <AccountingAdviceDetails
                  key="newReceivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            }
          ]
        },
        // {
        //   path: 'ar-client-summary',
        //   code: [GroupAccounting.ACCOUNTING_AR_INVOICE_SUMMARY],
        //   element: <ArClientSummary />
        // }
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_SALES_LOG_LIST],
          element: <SalesHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Purchases',
      path: 'purchases',
      element: <PurchasesLayout />,
      children: [
        {
          index: true,
          element: <PurchasesApInvoice />
        },
        {
          path: 'new',
          element: <ApInvoiceNoJobDetails key="new" />
        },
        {
          path: ':apInvoiceId',
          element: <ApInvoiceNoJobDetails key=":apInvoiceId" />
        },
        {
          path: 'payment-advice',
          children: [
            {
              index: true,
              element: <PurchasesPaymentAdvice />
            },
            {
              path: ':paymentAdviceId',
              element: (
                <AccountingAdviceDetails
                  key="paymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            },
            {
              path: 'new',
              element: (
                <AccountingAdviceDetails
                  key="newPaymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            }
          ]
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_PURCHASES_LOG_LIST],
          element: <PurchasesHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Statement',
      path: 'statement',
      element: <StatementLayout />,
      children: [
        {
          index: true,
          element: <StatementOfAccount />
        },
        {
          path: 'new',
          element: <StatementOfAccountDetails key="new" />
        },
        {
          path: ':statementOfAccountId',
          element: <StatementOfAccountDetails key=":statementOfAccountId" />
        },
        {
          path: 'statement-payment',
          children: [
            {
              index: true,
              element: <StatementPayment />
            },
            {
              path: 'new',
              element: <StatementPaymentDetails key="new" />
            },
            {
              path: ':paymentId',
              element: <StatementPaymentDetails key=":paymentId" />
            }
          ]
        },
        {
          path: 'statement-invoice',
          children: [
            {
              index: true,
              element: <StatementInvoices />
            },
            {
              path: ':statementInvoiceId',
              element: <StatementInvoiceDetails key=":statementInvoiceId" />
            }
          ]
        },
        {
          path: 'statement-history',
          code: [GroupAccounting.ACCOUNTING_STATEMENT_LOG_LIST],
          element: <StatementHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'More',
      path: 'more',
      code: [
        GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_LINKED_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_TAX_CODE_LIST
      ],
      element: <MoreLayout />,
      children: [
        // {
        //   index: true,
        //   element: <div>General Journals</div>
        // },
        {
          path: 'chart-of-accounts',
          code: [GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT_DETAIL],
          element: <MoreChartOfAccounts />
        },
        {
          path: 'manage-linked-account',
          code: [GroupAccounting.ACCOUNTING_LINKED_ACCOUNT_LIST],
          element: <MoreManageLinkedAccount />
        },
        {
          path: 'tax-codes',
          code: [GroupAccounting.ACCOUNTING_TAX_CODE_LIST],
          element: <MoreTaxCodes />
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_MORE_LOG_LIST],
          element: <MoreHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Banking',
      path: 'banking',
      code: [
        GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST,
        GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST
      ],
      element: <BankingLayout />,
      children: [
        {
          index: true,
          code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST],
          element: <BankingBankAccounts />
        },
        {
          path: 'bank-transaction',
          code: [GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST],
          element: <BankingBankTransactions />
        },
        {
          path: 'money',
          code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST],
          children: [
            {
              index: true,
              element: <BankingSpendReceiveMoney />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST]
            },
            {
              path: 'spend/:paymentSource/new',
              element: <BankingSpendReceiveMoneyDetails key="spendNew" />,
              loader: ({ params }: LoaderFunctionArgs) => {
                const isFromWip =
                  params.paymentSource ===
                  kebabCase(SRMChargeCodeSource.FromWip);

                return { isSpend: true, isFromWip };
              },
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'spend/:paymentSource/:moneyId',
              element: <BankingSpendReceiveMoneyDetails key=":spendMoneyId" />,
              loader: ({ params }: LoaderFunctionArgs) => {
                const isFromWip =
                  params.paymentSource ===
                  kebabCase(SRMChargeCodeSource.FromWip);

                return { isSpend: true, isFromWip };
              },
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'receive/:paymentSource/new',
              element: <BankingSpendReceiveMoneyDetails key="receiveNew" />,
              loader: ({ params }: LoaderFunctionArgs) => {
                const isFromWip =
                  params.paymentSource ===
                  kebabCase(SRMChargeCodeSource.FromWip);

                return { isSpend: false, isFromWip };
              },
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'receive/:paymentSource/:moneyId',
              element: (
                <BankingSpendReceiveMoneyDetails key=":receiveMoneyId" />
              ),
              loader: ({ params }: LoaderFunctionArgs) => {
                const isFromWip =
                  params.paymentSource ===
                  kebabCase(SRMChargeCodeSource.FromWip);

                return { isSpend: false, isFromWip };
              },
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            }
          ]
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_BANKING_LOG_LIST],
          element: <BankingHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Report',
      path: 'report',
      element: <Report />
    }
  ]
};

export default accountingRoutes;
