import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { KTabInstance } from 'uikit';

import { useUser } from '../auth';

interface IUseUITabsWithCallbackFbParams {
  defFb?: string;
  tabIndex?: number;
}

export const useUITabsWithCallback = (
  cb: () => any[],
  fb?: IUseUITabsWithCallbackFbParams
) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { userPers, isAdmin, isBranchAdmin } = useUser();

  const _tabs = useMemo(() => {
    if (isAdmin || isBranchAdmin) {
      return cb();
    } else {
      return cb().filter(i => {
        const hasPer = (i.code ?? []).some((x: string) => {
          return !!userPers?.[x];
        });
        return !!i.code ? hasPer : true;
      });
    }
  }, [cb, isAdmin, isBranchAdmin, userPers]);

  const { defFb, tabIndex = 0 } = fb || {};

  const _tabFb = useMemo(() => _tabs[tabIndex], [_tabs, tabIndex]);
  useEffect(() => {
    if (_tabFb && defFb && pathname === defFb) {
      navigate(_tabFb.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tabFb?.path, defFb, navigate, pathname]);

  return _tabs;
};

export const useUITabItemsWithCallback = (
  keys?: Record<string, string | string[]>
) => {
  if (isEmpty(keys)) {
    return undefined;
  }

  const res: Record<string, boolean> = {};

  const { userPers, isAdmin, isBranchAdmin } = useUser();

  Object.keys(keys).forEach(i => {
    if (isAdmin || isBranchAdmin) {
      res[i] = true;
    } else {
      const arr = typeof keys[i] === 'string' ? [keys[i]] : keys[i];
      // @ts-ignore
      res[i] = arr.some((a: string) => {
        return !!userPers?.[a];
      });
    }
  });

  return res;
};

export const useNavigateDefaultTab = (
  tabsRef: React.RefObject<KTabInstance>,
  tabs: any[],
  sIndex: number
) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const _path = pathname.split('/').slice(sIndex)?.[0] ?? '';
    const index = tabs.findIndex(i => i.path === _path);
    if (index !== -1) {
      tabsRef.current?.onChange?.(index, true);
    } else {
      !!tabs?.length ? navigate(tabs[0]?.path) : navigate('/admin');
    }
  }, [navigate, pathname, sIndex, tabs, tabsRef]);
};

export const useChangeTabItemByPath = (
  ref: React.RefObject<KTabInstance>,
  tabs: any[],
  sIndex: number
) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const arr = pathname.split('/').slice(sIndex)[0] ?? '';
    const index = tabs.findIndex(i => i.path === arr);
    if (index !== -1) {
      ref.current?.onChange?.(index, true);
    }
  }, [pathname, tabs, ref, sIndex]);
};
