import { IModifyConsolTransport } from '@request-dto';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import {
  ARInvoiceStatus,
  LegRoutingStatus,
  WebTable,
  APInvoiceStatus,
  generateOptions,
  SyncToMisa,
  ShipmentStatus,
  ConsolStatus
} from '@constants';
import Branch from 'containers/Branch';
import { checkDueDate } from 'containers/Invoice/helpers';
import { Prototype } from 'core';
import { useCUDConsol, useCUDTransport, useDeleteOneHelper } from 'hooks';
import trans from 'translation';
import { KButton, KChip, KColors, KContainer, KInput } from 'uikit';
import {
  UIUtils,
  mappedConsolStatusData,
  mappedInvoiceStatus,
  mappedPaymentStatusData,
  mappedShipmentStatusData
} from 'utils';

export interface IParamsConsolTransport extends IModifyConsolTransport {
  sp: any;
  spAgent: any;
  pickup: any;
  delivery: any;
  containerTypeSize: any;
  containerNumber: any;
  firstSealNumber: any;
  secondSealNumber: any;
  consolId: number | string;
}

const CONSOL_ACTION = {
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
  CANCEL: 'CANCEL',
  COPY: 'COPY'
};

interface IOptions {
  ids: number[];
}

interface IParams {
  key: keyof typeof CONSOL_ACTION;
  data: any[];
}

export const useConsolActions = () => {
  const {
    deleteMutation: { mutate: deleteMutation },
    duplicateMutation: { mutate: duplicateMutation },
    cancelMutation: { mutate: cancelMutation },
    isLoading
  } = useCUDConsol();

  const options = useCallback(
    (key: keyof typeof CONSOL_ACTION) => {
      let msgKey = '',
        cb;
      switch (key) {
        case CONSOL_ACTION.DELETE:
          msgKey = 'message.delete_consol';
          cb = ({ ids }: IOptions) => deleteMutation(ids);
          break;

        case CONSOL_ACTION.DUPLICATE:
          msgKey = 'message.duplicate_consol';
          cb = (mParams: IOptions) => duplicateMutation(mParams);
          break;

        case CONSOL_ACTION.CANCEL:
          msgKey = 'message.cancel_consol';
          cb = ({ ids }: IOptions) => cancelMutation({ id: ids[0] });
          break;

        case CONSOL_ACTION.COPY:
          msgKey = 'message.copy_branch_consol';
          cb = ({ ids }: IOptions) =>
            UIUtils.popup.open({
              title: trans('choose_branch_to_copy'),
              maxWidth: 'md',
              content: dismiss => (
                <Branch.Grid
                  dismiss={dismiss}
                  feature="manual"
                  onSubmit={branch =>
                    duplicateMutation({ ids, branchId: branch.id })
                  }
                />
              )
            });
          break;

        default:
          break;
      }

      return { msgKey, cb };
    },
    [cancelMutation, deleteMutation, duplicateMutation]
  );

  const onAlert = useCallback(
    (mParams: IParams) => {
      const { key, data } = mParams;
      const { msgKey, cb } = options(key);
      const message = trans(msgKey, {
        count: data.length
      });

      const ids = data.map(i => i.id);

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('ok'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => cb?.({ ids })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [options]
  );

  return { onAlert, isLoading };
};

export const useStatusView = (item?: any, isCs?: boolean) => {
  const value = item?.consolStatus || item?.shipmentStatus;
  const _value: ShipmentStatus | ConsolStatus = item?.isBookingCancel
    ? ShipmentStatus.Cancel
    : value;
  const props = useMemo(
    () =>
      isCs
        ? mappedConsolStatusData()[_value]
        : mappedShipmentStatusData()[_value],
    [isCs, _value]
  );

  const status = useMemo(
    () => (
      <KChip
        textTransform="uppercase"
        typo="TextXMdMedium"
        padding="0.5rem"
        marginH="3.5rem"
        {...props}
      />
    ),
    [props]
  );

  // const bookingCancel = useMemo(() => {
  //   if (item?.isBookingCancel) {
  //     return (
  //       <KChip
  //         background={KColors.hexToRgba(KColors.secondary.normal, 0.31)}
  //         brC={KColors.transparent}
  //         label={trans('booking_cancel')}
  //         textTransform={'uppercase'}
  //         color={KColors.secondary.normal}
  //         typo="TextXMdMedium"
  //         padding="0.5rem"
  //       />
  //     );
  //   }

  //   return null;
  // }, [item?.isBookingCancel]);

  if (!item) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {status}
      {/* {bookingCancel} */}
    </KContainer.View>
  );
};
/**
 *
 * @param dueDate // ngày cho trước nhỏ hơn ngày hiện tại
 * @returns
 */
// export const checkDueDate = (dueDate: any) => {
//   const currentDate = Prototype.date.now().startOf('day');
//   const _dueDate = Prototype.date
//     .toMoment(dueDate, 'YYYY-MM-DD')
//     ?.startOf('day');

//   const isCompareDate = _dueDate?.isBefore(currentDate); // ngày cho trước nhỏ hơn ngày hiện tại
//   return isCompareDate;
// };
export const useStatusInvoice = (
  item?: any,
  isOverdue?: boolean,
  typeInvoice?: 'ap' | 'ar'
) => {
  if (!item?.id) {
    return null;
  }

  const commonChip = (status: any) => {
    if (!status) return null;
    return (
      <KChip
        textTransform="uppercase"
        typo="TextXMdMedium"
        padding="0.5rem"
        marginH="1rem"
        {...status}
      />
    );
  };

  const valueInvoiceStatus =
    typeInvoice === 'ap'
      ? item?.apInvoiceStatus?.code
      : item?.arInvoiceStatus?.code;

  const isCompareDate = checkDueDate(item.dueDate);

  const isOverDue =
    isCompareDate && valueInvoiceStatus !== APInvoiceStatus.COMPLETE;
  const invoiceStatus =
    mappedInvoiceStatus()[isOverDue ? 'OVERDUE' : valueInvoiceStatus];
  const paymentStatus = mappedPaymentStatusData()[item?.paymentStatus];

  const _totalAmount = {
    color: KColors.palette.blue.w700,
    background: KColors.palette.blue.w50,
    brC: KColors.palette.blue.w50,
    label: `${trans('TOTAL')} (${
      item?.paymentCurrency?.code
    }) : ${Prototype.number.formatNumber(item?.totalAmount)}`
  };
  const isHiddenPayment =
    valueInvoiceStatus === ARInvoiceStatus.COMPLETE ||
    valueInvoiceStatus === ARInvoiceStatus.ISSUED ||
    valueInvoiceStatus === APInvoiceStatus.APPROVED ||
    valueInvoiceStatus === APInvoiceStatus.COMPLETE;

  return (
    <KContainer.View justifyContent="space-between" row width="100%">
      <KContainer.View row alignItems>
        {commonChip(_totalAmount)}
        {commonChip(invoiceStatus)}
        {isHiddenPayment && commonChip(paymentStatus)}
      </KContainer.View>
    </KContainer.View>
  );
};

export const useDeleteTransport = (webTable: WebTable) => {
  const {
    deleteOneMutation: { mutate, isLoading: deleteOneLoading, ...rest }
  } = useCUDTransport(webTable);

  const onDelete = useDeleteOneHelper({
    name: trans('transport'),
    mutate
  });

  return { onDelete, deleteOneLoading, ...rest };
};

export const checkDeleteTransport = (
  consolTransportStatus: LegRoutingStatus,
  isLast: boolean,
  isActiveStatus: boolean
) => {
  const inComplete = consolTransportStatus === LegRoutingStatus.Complete;
  return !inComplete && isLast && isActiveStatus;
};

export const SyncMisa = () => {
  return (
    <KContainer.View
      row
      gap={'1rem'}
      minW={'250px'}
      alignItems="baseline"
      paddingT={'0.75rem'}
    >
      <Controller
        name="misa"
        render={({ field }) => {
          return (
            <KInput.TextField
              marginT={'0rem'}
              {...field}
              label={trans('misa_database')}
              options={generateOptions(SyncToMisa)}
            />
          );
        }}
      />
      <KButton.Solid
        textColor={KColors.white}
        background={'#707277'}
        brC={'#707277'}
        title={trans('Sync')}
        padding="0.5rem"
        height={'33px'}
        onPress={() => console.log('Sync')}
      />
    </KContainer.View>
  );
};
