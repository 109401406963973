import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import RelatedPartyForm from 'pages/Client/RelatedParty/Form';
import DepartmentForm from 'pages/Library/Category/Administration/Department/Form';
import JobTitleForm from 'pages/Library/Category/Administration/JobTitle/Form';
import ClientCategoryForm from 'pages/Library/Category/Client/Category/Form';
import ClientStageForm from 'pages/Library/Category/Client/Stage/Form';
import ClientTypeForm from 'pages/Library/Category/Client/Type/Form';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

interface IProps {
  item?: any;
  clientId?: string | number;
  onDelete?: (listId: number[]) => void;
  tabName: string;
}

export const useDeleteHelper = (options: { name: string; mutate: any }) => {
  const { name, mutate } = options;

  const onDelete = useCallback(
    (data: any[], onSuccess?: () => void) => {
      const ids = data.map(i => i.id);

      const message = trans('message.delete_global', {
        count: data.length,
        name: trans(name).toLowerCase()
      });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids, { onSuccess })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [name, mutate]
  );

  return onDelete;
};

export const useDeleteOneHelper = (options: { name: string; mutate: any }) => {
  const { name, mutate } = options;

  const onDelete = useCallback(
    (id: number | string, onSuccess?: () => void) => {
      const message = trans('message.delete_global', {
        count: 1,
        name: trans(name).toLowerCase()
      });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate({ id: id }, { onSuccess })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [name, mutate]
  );

  return onDelete;
};

export const onShowDepartment = (v?: any) => {
  UIUtils.popup.open({
    title: trans('department'),
    maxWidth: 'md',
    content: () => <DepartmentForm item={v} />
  });
};

export const onShowJobTitle = (v?: any) => {
  UIUtils.popup.open({
    title: trans('job_title'),
    maxWidth: 'md',
    content: () => <JobTitleForm item={v} />
  });
};

export const onShowClientCategory = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_category'),
    maxWidth: 'md',
    content: () => <ClientCategoryForm item={v} />
  });
};

export const onShowClientStage = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_stage'),
    maxWidth: 'md',
    content: () => <ClientStageForm item={v} />
  });
};

export const onShowAddress = (v?: any) => {
  window.open(`/admin/client/${v?.clientId}`, '_blank');
};

export const onShowClient = (v?: any) => {
  window.open(`/admin/client/${v?.id ?? 'new'}`, '_blank');
};

export const onShowClientType = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_type'),
    maxWidth: 'md',
    content: () => <ClientTypeForm item={v} />
  });
};

export const onShowRelatedParty = (Props: IProps) => {
  const { item, clientId, onDelete, tabName } = Props;
  UIUtils.popup.open({
    title: trans('related_party'),
    maxWidth: 'md',
    content: () => (
      <RelatedPartyForm
        item={item}
        clientId={clientId}
        onDelete={onDelete}
        tabName={tabName}
      />
    )
  });
};

export const onErrorSubmit = (e: any, onError?: any) => {
  if (!isEmpty(e)) {
    const [firstKeyErr, firstValueErr] = Object.entries(e)[0] as [string, any];

    let constructedKey = firstKeyErr;

    if (Array.isArray(firstValueErr) && firstValueErr.length > 0) {
      // Kiểm tra xem cóp phải array hay khôg
      const endIndex = firstValueErr.length - 1; // Lấy dòng cuối cùng của array lỗi (Không lấy dòng đầu tiên vì dòng đầu tiền sau khi người dùng chỉnh sửa data vào thì lần submit tiếp theo dòng đầu là empty)

      const nestedKey = Object.keys(firstValueErr[endIndex])?.[0];

      constructedKey = nestedKey
        ? `${firstKeyErr}.${endIndex}.${nestedKey}`
        : `${firstKeyErr}.${endIndex}`;
    }

    const inputElement = document.querySelector(
      `input[name="${constructedKey}"]`
    );
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      toast.error(trans('message.required_fields_are_missing'));
    }
  }

  if (typeof onError === 'function') {
    onError?.();
  }
};
