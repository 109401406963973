import { isEmpty } from 'lodash';
import { generatePath } from 'react-router-dom';

import {
  InvoiceCategory,
  ENDPOINTS,
  QUERY_KEYS,
  OriginDestinationType,
  WebTable,
  tableRef,
  PaymentType
} from '@constants';
import APIManager, { END_POINTS } from '@services';
import { UIUtils } from 'utils';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

interface ReqWip {
  formData: FormData;
  shouldValidate: boolean;
}

export const useImportWip = (category = InvoiceCategory.Accounting) => {
  return useMutationEnhancer<any, ReqWip>({
    mutationFn: async req => {
      const { formData } = req;
      const res = await APIManager.request({
        url: ENDPOINTS.wip(category, 'import-excel'),
        method: 'POST',
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.data;
    },
    onSuccess: (_, req) => {
      const { shouldValidate } = req;
      if (!shouldValidate) {
        tableRef?.[WebTable.WIP]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useValidateImportArChargeCode = (
  arInvoiceId: string | number,
  category = InvoiceCategory.Accounting
) => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(
          category,
          ':arInvoiceId/ar-charge-code/import-validate',
          { arInvoiceId }
        ),
        method: 'POST',
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    }
  });
};

export const useImportArChargeCode = (
  arInvoiceId: string | number,
  category = InvoiceCategory.Accounting
) => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(
          category,
          ':arInvoiceId/ar-charge-code/import-excel',
          { arInvoiceId }
        ),
        method: 'POST',
        body: formData,
        showToast: true,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    },
    onSuccess: () => {
      UIUtils.popup.dismiss();
    }
  });
};

export const useFetchListForTransport = (
  consolId?: string | number,
  type?: OriginDestinationType
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.listForTransport],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_LIST_FOR_TRANSPORT, {
          consolId
        }),
        body: {
          consolId,
          type
        }
      });

      return res.data?.[0] || null;
    },
    enabled: !!consolId && !!type
  });
};

export const useFetchAccountingStatistics = (
  mParams: any,
  secondCurrencyId?: number | string
) => {
  const { category, ...rest } = mParams;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.accountingStatistics, secondCurrencyId, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statistics(category),
        body: {
          secondCurrencyId,
          ...rest
        }
      });

      return res.data;
    },
    keepPreviousData: false,
    enabled: !!mParams.consolId || !!mParams.shipmentId || !!mParams.serviceId
  });
};

export const useCUDStatementPayment = () => {
  return usePageCUDMutationEnhancer({
    mutationKeys: [[QUERY_KEYS.statementPayment]],
    endPoint: ENDPOINTS.statementPayment(),
    webTable: WebTable.ACCOUNTING_STATEMENT_PAYMENT,
    queryKey: QUERY_KEYS.statementPayment
  });
};

export const useFetchStatementPayment = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementPayment, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementPayment('/:id', { id })
      });

      if (!isEmpty(res.data)) {
        const isDebit = res.data.statementType === PaymentType.DEBIT;

        return {
          ...res.data,
          details: (res.data.details ?? []).map((i: any) => {
            const { apInvoiceId, arInvoiceId } = i;
            const sign =
              (isDebit && !!apInvoiceId) || (!isDebit && !!arInvoiceId)
                ? -1
                : 1;
            return {
              ...i,
              totalAmountDP: i.totalAmount * sign,
              paymentTotalAmountDP: i.paymentTotalAmount * sign,
              paymentBalanceDP: i.paymentBalance * sign,
              amountDP: i.amount * sign
            };
          })
        };
      }

      return res.data;
    },
    enabled: !!id
  });
};
