import React, { memo, useCallback, useState } from 'react';

import { WebTable } from '@constants';
import { BottomActions } from 'components';
import AgTable from 'containers/AgTable';
import { Prototype } from 'core';
import { useRenderTemplate, useUser } from 'hooks';
import useAgTable from 'hooks/core/useAgTable';
import { colDefsStatementInvoices } from 'pages/Accounting/Statement/StatementInvoices/Details/WipList';
import trans from 'translation';
import { KGrid, KInput, KListItem } from 'uikit';

import { IPrintVatInvoice } from './helpers';

const PrintVatInvoice = ({
  invoiceId,
  chargeCodes,
  currency,
  code,
  hasDraftVat,
  screen
}: IPrintVatInvoice) => {
  const { user } = useUser();

  const [isHasDraftVat, setIsHasDraftVat] = useState(hasDraftVat);

  const table = useAgTable({
    name: WebTable.LOCAL_DRAFT_VAT_INVOICE,
    data: chargeCodes,
    colDefs:
      screen === 'ar-invoice'
        ? colDefs({ currency })
        : colDefsStatementInvoices({ isAr: true }),
    onAddParams: {
      hidden: true
    }
  });
  const { isLoading, mutate } = useRenderTemplate();

  const onFormValid = useCallback(() => {
    const rowSelecteds = table.onGetSelectedData?.() ?? [];
    mutate({
      renderFileExtension: 'PDF',
      draftVatInvoice: {
        invoiceId,
        chargeCodeIds: rowSelecteds.map((i: any) => i.id),
        hasDraftVat: !!isHasDraftVat,
        screen
      }
    });
  }, [invoiceId, isHasDraftVat, mutate, screen, table]);

  return (
    <>
      <KGrid.Container marginBottom={'1.5rem'}>
        <KGrid.Item xs={2}>
          <KInput.TextField
            value={code}
            label={trans('invoice')}
            required
            disabled
          />
        </KGrid.Item>
        <KGrid.Item xs={2}>
          <KInput.TextField
            label={trans('print_by')}
            disabled
            value={user?.account.displayName}
          />
        </KGrid.Item>
        <KGrid.Item xs={2}>
          <KInput.TextField
            name="print_at"
            label={trans('print_at')}
            value={Prototype.date.formatDT(Prototype.date.now() as any, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>
      <AgTable {...table} showSearch isLoading={isLoading} />
      <KGrid.Container
        gap={'0.75rem'}
        justifyContent={'space-between'}
        style={{ marginTop: '0.75rem' }}
      >
        <KListItem.Checkbox
          name="hasDraftVat"
          label={trans('save_draft_vat_invoice')}
          size={25}
          typo="TextMdNormal"
          checked={isHasDraftVat}
          onChange={setIsHasDraftVat}
          containerStyle={{
            marginLeft: '10px'
          }}
        />
        <BottomActions
          btnProps={[
            {
              title: trans('print_pdf'),
              icon: 'Print',
              onPress: onFormValid,
              isLoading: isLoading,
              disabled: isLoading || !table?.onGetSelectedData?.()?.length
            }
          ]}
        />
      </KGrid.Container>
    </>
  );
};

export default memo(PrintVatInvoice);

const colDefs = ({ currency }: { currency: any }) => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      pinned: 'left',
      filter: false,
      sortable: false,
      lockVisible: true,
      maxWidth: 51,
      colId: 'isChecked'
    },
    {
      headerName: trans('charge_code'),
      field: 'chargeCode.code'
    },
    {
      headerName: trans('description'),
      field: 'description',
      minWidth: 180
    },
    {
      headerName: trans('currency'),
      field: 'currency.code',
      minWidth: 110,
      initialWidth: 110
    },
    {
      headerName: trans('unit'),
      field: 'chargeCodeUnit',
      minWidth: 110,
      initialWidth: 110,
      type: 'code'
    },
    {
      headerName: trans('unit_price'),
      field: 'unitPrice',
      type: 'numberAlignRight'
    },
    {
      headerName: trans('quantity'),
      field: 'quantity',
      type: 'numberWoPad'
    },
    {
      headerName: trans('sub_total'),
      field: 'subTotal',
      width: 190,
      type: 'numberAlignRight'
    },
    {
      headerName: trans('chargeable_ex_rate'),
      field: 'chargeableExchangeRate',
      width: 178,
      minWidth: 160,
      cellClass: 'text-right',
      type: 'numberAlignRight'
    },
    {
      headerName: trans('with_unit.payment_sub_total', {
        unit: currency?.code
      }),
      field: 'paymentSubTotal',
      minWidth: 170,
      type: 'numberAlignRight'
    },
    {
      headerName: trans('with_unit.tax'),
      field: 'taxType',
      type: 'tax'
    },
    {
      headerName: trans('with_unit.payment_tax_amount', {
        unit: currency?.code
      }),
      width: 216,
      field: 'paymentTaxAmount',
      type: 'numberAlignRight'
    },
    {
      headerName: trans('with_unit.payment_total_amount', {
        unit: currency?.code
      }),
      field: 'paymentTotalAmount',
      width: 190,
      type: 'numberAlignRight'
    }
  ];
};
