/* eslint-disable max-lines */
import { UseDataTableColumn, UseDataTableResult } from '@hooks-dto';
import Tooltip from '@mui/material/Tooltip';
import { IItemProps } from '@ui';
import { set } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  APInvoiceStatus,
  ARInvoiceStatus,
  AirContainerMode,
  AirOceanType,
  ArApType,
  CHARGE_CODE_INCLUDE_FIELDS,
  CHARGE_CODE_MAPPED_FIELDS,
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  INVOICE_INCLUDE_FIELDS,
  INVOICE_MAPPED_FIELDS,
  InvoiceCategory,
  InvoiceType,
  OceanContainerMode,
  PaymentStatus,
  TableName,
  UIDataType,
  WebTable
} from '@constants';
import { Prototype } from 'core';
import { useDataTable, useGlobalTable } from 'hooks';
import { onShowChargeCode } from 'pages/Library/Category/helpers';
import trans from 'translation';
import { KChip, KColors, KContainer, KLabel } from 'uikit';
import {
  TableUtils,
  UIUtils,
  mappedInvoiceStatus,
  mappedPaymentRequestStatus,
  mappedPaymentStatusData,
  mappedSyncStatus,
  mappedVATStatus
} from 'utils';

const NOW = Prototype.date.now();

export const BOL_UNIT = {
  code: 'BOL',
  name: trans('bill_of_lading')?.toUpperCase()
};

export const DEF_WIP_SUB_CHARGE_CODE = {
  subChargeCode: undefined,
  currency: undefined,
  ratio: 0,
  subTotal: 0
};

const onGetIsOcean = (isCs: boolean, code?: string) => {
  if (!code) {
    return false;
  }
  return code.includes(isCs ? 'CO' : 'SO');
};

const onGetIsExpired = (v: any) => {
  const d = Prototype.date.toMoment(v, 'YYYY-MM-DD')?.startOf('day');

  return d?.isBefore(NOW);
};

interface IOnGenInvoicePath {
  item?: any;
  category?: UIDataType;
  arApType?: ArApType;
  isGlobal?: boolean;
  isNew?: boolean;
  isWithoutInvoice?: boolean;
}

export const checkDueDate = (dueDate: any) => {
  const currentDate = Prototype.date.now().startOf('day');
  const _dueDate = Prototype.date
    .toMoment(dueDate, 'YYYY-MM-DD')
    ?.startOf('day');

  const isCompareDate = _dueDate?.isBefore(currentDate); // ngày cho trước nhỏ hơn ngày hiện tại
  return isCompareDate;
};

export const onGenInvoicePath = ({
  item,
  category = UIDataType.Cs,
  arApType = ArApType.Ar,
  isGlobal = false,
  isNew = false,
  isWithoutInvoice = false
}: IOnGenInvoicePath) => {
  const {
    id,

    shipmentId: smId,
    consolId: csId,
    serviceId: svId,

    consolNumber: csNo,
    shipmentNumber: smNo,
    billingCategory
  } = item || {};

  if (billingCategory === InvoiceCategory.Other) {
    if (arApType === ArApType.Ar) {
      return `${item?.id}`;
    }

    if (arApType === ArApType.Ap) {
      return `${item?.id}`;
    }
  }

  const data = {
    isOcean: true,
    withCs: false,
    withSm: false,
    withSv: false
  };
  //url_link
  switch (category) {
    case UIDataType.Cs:
      set(data, 'isOcean', onGetIsOcean(true, csNo));
      set(data, 'withCs', true);
      break;

    case UIDataType.Sm:
      set(data, 'isOcean', onGetIsOcean(false, smNo));
      set(data, 'withSm', true);
      set(data, 'withCs', !!csId);
      break;

    case UIDataType.Sv:
      set(data, 'withSv', true);
      break;

    default:
      set(data, 'isOcean', onGetIsOcean(!!csId, csNo || smNo));
      set(data, 'withSm', !!smId);
      set(data, 'withCs', !!csId);
      set(data, 'withSv', !!svId);
      break;
  }

  const PREFIX = '/admin';
  const SUFFIX = isWithoutInvoice ? '' : `/${arApType.toLowerCase()}/${id}`;
  const SUFFIX_NEW = `${arApType.toLowerCase()}/new`;
  const PREFIX_BODY = data.withSv
    ? ''
    : `/${data.isOcean ? 'ocean-freight' : 'air-freight'}`;
  const SUFFIX_BODY = isWithoutInvoice
    ? ''
    : data.withSv || data.withSm
    ? '/billing'
    : '/accounting';

  const body = data.withSv
    ? `service/${svId}`
    : data.withSm
    ? data.withCs
      ? `consolidation/${csId}/shipment/${smId}`
      : `shipment/${smId}`
    : `consolidation/${csId}`;

  return isGlobal
    ? `${PREFIX}${PREFIX_BODY}/${body}${SUFFIX_BODY}${SUFFIX}`
    : isNew
    ? `./${SUFFIX_NEW}`
    : `.${SUFFIX}`;
};

const onApThresholdStatus = (v: APInvoiceStatus) => {
  return ![
    APInvoiceStatus.COMPLETE,
    APInvoiceStatus.CANCEL,
    APInvoiceStatus.APPROVED
  ].includes(v);
};

const onGetValidInvoice = (category = UIDataType.Cs, item: any) => {
  switch (category) {
    case UIDataType.Cs:
      return item?.billingCategory === InvoiceCategory.Accounting.toUpperCase();

    case UIDataType.Sm:
      return item?.billingCategory === InvoiceCategory.Billing.toUpperCase();

    case UIDataType.Sv:
      return item?.billingCategory === InvoiceCategory.Service.toUpperCase();
  }
};

export const useInvoiceList = (
  category = UIDataType.Cs,
  arApType = ArApType.Ar,
  isGlobal = false,
  mParams?: any
) => {
  const { threshold, ..._otherParams } = mParams || {};

  const isAr = arApType === ArApType.Ar;

  const isCs = category === UIDataType.Cs;
  const isSm = category === UIDataType.Sm;
  const isSv = category === UIDataType.Sv;

  const navigate = useNavigate();

  const columnsFunc = useCallback(
    (data: any[]): UseDataTableColumn[] => {
      return [
        {
          label: trans(' '),
          name: 'apInvoiceStatus.code',
          options: {
            ...TableUtils.options.baseSm,
            ...TableUtils.options.toggle(!isAr && !isGlobal),
            customBodyRender: (v: APInvoiceStatus) => {
              const isLowerThreshold =
                onApThresholdStatus(v) && threshold?.isLowerThreshold;

              const isLowerSubThreshold =
                onApThresholdStatus(v) && threshold?.shipmentNumbers?.length;

              if (isLowerThreshold || isLowerSubThreshold) {
                return (
                  <Tooltip
                    title={
                      <KLabel.Error
                        withIcon
                        colorIcon={KColors.warning.normal}
                        alignItemsWrap="center"
                      >
                        {isLowerThreshold && (
                          <KLabel.Paragraph color={KColors.white}>
                            {trans('message_lower_threshold', {
                              name: isSv
                                ? trans('service')
                                : isCs
                                ? trans('consolidation')
                                : trans('shipment')
                            })}
                          </KLabel.Paragraph>
                        )}

                        {isLowerSubThreshold && (
                          <KLabel.Paragraph
                            marginT={'0.5rem'}
                            color={KColors.white}
                          >
                            {trans('message_lower_sub_threshold', {
                              data: threshold?.shipmentNumbers?.join(', ')
                            })}
                          </KLabel.Paragraph>
                        )}
                      </KLabel.Error>
                    }
                  >
                    <div>
                      <KLabel.Error
                        withIcon
                        colorIcon={KColors.warning.normal}
                      />
                    </div>
                  </Tooltip>
                );
              }

              return null;
            },
            sort: false,
            viewColumns: false
          }
        },
        {
          label: trans(isAr ? 'issued_date' : 'approved_date'),
          name: 'issuedDate',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('invoice_no'),
          name: 'code',
          options: {
            ...TableUtils.options.baseXLg,
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const path = onGenInvoicePath({
                item,
                category,
                arApType,
                isGlobal
              });
              InvoiceCategory;
              const enabled = onGetValidInvoice(category, item);

              const isOther = item?.billingCategory === InvoiceCategory.Other;

              return (
                <KLabel.Text
                  onPress={
                    !isGlobal && !enabled
                      ? undefined
                      : () => {
                          isGlobal && !isOther
                            ? window.open(path)
                            : navigate(path);
                        }
                  }
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('invoice_type'),
          name: 'invoiceType',
          options: TableUtils.options.chip({
            props: { width: 100 },
            label: (v?: string) => (v ? trans(`option.${v.toLowerCase()}`) : '')
          })
        },
        {
          label: trans(isAr ? 'ref_number' : 'ap_client_inv_no'),
          name: isAr ? 'refArInvoiceNo' : 'refApInvoiceNo'
        },
        {
          label: trans('ap_client_inv_date'),
          name: 'refApInvoiceDate',
          options: {
            ...TableUtils.options.date(DATE_FORMAT_SHORT),
            ...TableUtils.options.toggle(!isAr)
          }
        },
        {
          label: trans(isAr ? 'ar_code' : 'ap_code'),
          name: isAr ? 'accountReceivable.code' : 'accountPayable.code'
        },
        {
          label: trans(isAr ? 'ar_name' : 'ap_name'),
          name: isAr ? 'accountReceivable.name' : 'accountPayable.name',
          options: TableUtils.options.base3XLg
        },
        {
          label: trans('mbl_mawb_no'),
          name: 'oblNo',
          options: {
            sort: false
          }
        },
        {
          label: trans('hbl_hawb_no'),
          name: 'hblNo',
          options: {
            sort: false
          }
        },
        {
          label: trans('currency'),
          name: 'paymentCurrency.code'
        },
        {
          label: trans('payment_request'),
          name: 'paymentRequestStatus',
          options: {
            ...TableUtils.options.chips({
              uppercase: true,
              width: 90,
              mapLabelData: mappedPaymentRequestStatus()
            }),
            ...TableUtils.options.toggle(!isAr)
          }
        },
        {
          label: trans('payment_no'),
          name: 'paymentNumbers',
          options: TableUtils.options.withMoreIcon({
            onNormalizeData: rowData => {
              const item = data?.[rowData.rowIndex];
              if (item?.paymentNumbers) {
                return item.paymentNumbers?.match(/[^, ]+/g);
              }

              return [];
            },
            kind: 'warning'
          })
        },
        {
          label: trans('consol_sub_total'),
          name: 'consolSubTotal',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.toggle(isSm),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans('shipment_sub_total'),
          name: 'shipmentSubTotal',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.toggle(isSm),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans(!isSm ? 'sub_total' : 'amount_before_tax'),
          name: 'subTotal',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.toggle(!isSm || isGlobal),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans('tax_amount'),
          name: 'taxAmount',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans('vat_invoice'),
          name: 'vatInvoiceNo',
          options: TableUtils.options.toggle(isAr)
        },
        {
          label: trans('vat_request'),
          name: 'vatInvoiceStatus',
          options: {
            ...TableUtils.options.chips({
              uppercase: true,
              width: 90,
              mapLabelData: mappedVATStatus()
            }),
            ...TableUtils.options.toggle(isAr)
          }
        },
        {
          label: trans('total_amount'),
          name: 'totalAmount',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans(isAr ? 'collect' : 'paid'),
          name: isAr ? 'collect' : 'paid',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans('balance'),
          name: 'balance',
          options: {
            ...TableUtils.options.number(),
            ...TableUtils.options.align('right')
          }
        },
        {
          label: trans('due_date'),
          name: 'dueDate',
          options: {
            customBodyRender: (v: string, rowData?: any) => {
              const item = data?.[rowData.rowIndex];

              const isExpired = onGetIsExpired(v);

              const isDone =
                [ARInvoiceStatus.COMPLETE, ARInvoiceStatus.CANCEL].includes(
                  item?.arInvoiceStatus?.code
                ) ||
                [APInvoiceStatus.COMPLETE, APInvoiceStatus.CANCEL].includes(
                  item?.apInvoiceStatus?.code
                );

              if (v) {
                return (
                  <>
                    <KLabel.Paragraph>
                      {Prototype.date.formatD(v)}
                    </KLabel.Paragraph>

                    {isExpired && !isDone && (
                      <KLabel.Error italic>{trans('expired')}</KLabel.Error>
                    )}
                  </>
                );
              }

              return null;
            }
          }
        },
        {
          label: trans('last_payment'),
          name: 'lastPaymentDate',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('complete_date'),
          name: 'completeDate',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('invoice_status'),
          name: isAr ? 'arInvoiceStatus.code' : 'apInvoiceStatus.code',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 140,
            mapLabelData: mappedInvoiceStatus()
          })
        },
        {
          label: trans('payment_status'),
          name: 'paymentStatus',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const isCompareDate = checkDueDate(item?.dueDate);
              const invoiceStatusCode =
                item?.arInvoiceStatus?.code || item?.apInvoiceStatus?.code;
              if (invoiceStatusCode && isCompareDate) {
                v =
                  invoiceStatusCode !== APInvoiceStatus.CANCEL &&
                  v !== PaymentStatus.PAID
                    ? 'OVERDUE'
                    : v;
              }
              return (
                <KChip
                  textTransform={'uppercase'}
                  width={110}
                  {...(mappedPaymentStatusData() as any)[v]}
                />
              );
            }
          }
        },
        {
          label: trans('consol_no'),
          name: 'consolNumber',
          options: {
            ...TableUtils.options.toggle(isGlobal),
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const path = onGenInvoicePath({
                item,
                category: UIDataType.Cs,
                arApType,
                isGlobal: true,
                isWithoutInvoice: true
              });

              return (
                <KLabel.Text
                  dp="block"
                  width={100}
                  onPress={() => window.open(path)}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('shipment_no'),
          name: 'shipmentNumber',
          options: {
            ...TableUtils.options.toggle(isCs || isGlobal),
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const path = onGenInvoicePath({
                item,
                category: UIDataType.Sm,
                arApType,
                isGlobal: true,
                isWithoutInvoice: true
              });

              return (
                <KLabel.Text
                  dp="block"
                  width={100}
                  onPress={() => window.open(path)}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('service_no'),
          name: 'serviceNumber',
          options: {
            ...TableUtils.options.baseXLg,
            ...TableUtils.options.toggle(isGlobal),
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              const path = onGenInvoicePath({
                item,
                category: UIDataType.Sv,
                arApType,
                isGlobal: true,
                isWithoutInvoice: true
              });

              return (
                <KLabel.Text onPress={() => window.open(path)}>{v}</KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('sync_by'),
          name: 'syncBy.displayName'
        },
        {
          label: trans('sync_date'),
          name: 'syncDate',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('sync_status'),
          name: 'syncStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 100,
            mapLabelData: mappedSyncStatus()
          })
        },
        {
          label: trans('has_draft_vat'),
          name: 'hasDraftVat',
          options: TableUtils.options.iconCheck()
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        }
      ];
    },
    [
      arApType,
      category,
      isAr,
      isCs,
      isGlobal,
      isSm,
      isSv,
      navigate,
      threshold?.isLowerThreshold,
      threshold?.shipmentNumbers
    ]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: INVOICE_INCLUDE_FIELDS,
      ..._otherParams
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(_otherParams)]);

  const mappedFields = INVOICE_MAPPED_FIELDS;

  const name = useMemo(
    () =>
      isAr
        ? isGlobal
          ? WebTable.ACCOUNTING_AR_INVOICES
          : isCs
          ? WebTable.CONSOL_AR_INVOICES
          : isSm
          ? WebTable.SHIPMENT_AR_INVOICES
          : WebTable.SERVICE_AR_INVOICES
        : isGlobal
        ? WebTable.ACCOUNTING_AP_INVOICES
        : isCs
        ? WebTable.CONSOL_AP_INVOICES
        : isSm
        ? WebTable.SHIPMENT_AP_INVOICES
        : WebTable.SERVICE_AP_INVOICES,
    [isAr, isCs, isGlobal, isSm]
  );

  const tableName = useMemo(
    () => (isAr ? TableName.AR_INVOICE : TableName.AP_INVOICE),
    [isAr]
  );

  const apiURL = useMemo(
    () =>
      isAr
        ? isGlobal
          ? ENDPOINTS.arInvoice2()
          : ENDPOINTS.arInvoice(
              isSv
                ? InvoiceCategory.Service
                : isCs
                ? InvoiceCategory.Accounting
                : InvoiceCategory.Billing
            )
        : isGlobal
        ? ENDPOINTS.apInvoice2()
        : ENDPOINTS.apInvoice(
            isSv
              ? InvoiceCategory.Service
              : isCs
              ? InvoiceCategory.Accounting
              : InvoiceCategory.Billing
          ),
    [isAr, isCs, isGlobal, isSv]
  );

  const table: UseDataTableResult = useDataTable({
    name,
    tableName,
    columnsFunc,
    apiURL,
    mappedFields,
    otherParams,
    ..._otherParams
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    const path = onGenInvoicePath({ category, arApType, isNew: true });
    return navigate(path);
  }, [arApType, category, navigate]);

  return { table, onAdd };
};

export interface IOnShowWip extends IItemProps {
  category?: UIDataType;
  initParams: any;
  airOceanType?: AirOceanType;
  containerMode?: AirContainerMode | OceanContainerMode;
}

export const useChargeCodeList = (category = UIDataType.Cs, mParams?: any) => {
  const { ..._otherParams } = mParams || {};

  const isCs = category === UIDataType.Cs;
  const isSm = category === UIDataType.Sm;

  const columnsFunc = useCallback(
    (data: any[]): UseDataTableColumn[] => {
      return [
        {
          label: trans('charge_code'),
          name: 'chargeCode',
          options: {
            customBodyRender: (v: any) => {
              if (v?.code) {
                return (
                  <KLabel.Text onPress={() => onShowChargeCode(v)}>
                    {v?.code}
                  </KLabel.Text>
                );
              }

              return undefined;
            }
          }
        },
        {
          label: trans('description'),
          name: 'chargeCode.description'
        },
        {
          label: trans('from'),
          name: 'chargeCodeFrom.code'
        },
        {
          label: trans('to'),
          name: 'chargeCodeTo.code'
        },
        {
          label: trans('container_size'),
          name: 'containerTypeSize.name'
        },
        {
          label: trans('service_type'),
          name: 'serviceType'
        },
        {
          label: trans('ap_invoice_no'),
          name: 'apInvoiceCodes',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: item => {
              UIUtils.popup.open({
                title: trans('ap_invoice_no'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {item?.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      const isCreditNote =
                        i?.invoiceType === InvoiceType.CREDIT_NOTE;

                      return (
                        <KContainer.View
                          key={`ap-invoice-list-popup-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={
                            isCreditNote
                              ? KColors.warning.normal
                              : KColors.primary.normal
                          }
                          marginT={marginT}
                        >
                          <KLabel.Text
                            typo="TextMdNormal"
                            color={
                              isCreditNote
                                ? KColors.warning.normal
                                : KColors.primary.normal
                            }
                          >
                            {i?.code}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx, rowIdx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              const item = data?.[rowIdx];
              const isCreditNote = i?.invoiceType === InvoiceType.CREDIT_NOTE;

              return (
                <KContainer.View
                  key={`${item?.id}-ap-invoice-list-${idx}`}
                  minW={160}
                  height={20}
                  marginL={marginL}
                  dp="flex"
                  center
                  background={isCreditNote ? undefined : '#ACDCD7'}
                  br="2x"
                  brW={1}
                  brC={
                    isCreditNote
                      ? KColors.warning.normal
                      : KColors.primary.normal
                  }
                >
                  <KLabel.Text
                    color={
                      isCreditNote
                        ? KColors.warning.normal
                        : KColors.primary.normal
                    }
                  >
                    {i?.code}
                  </KLabel.Text>
                </KContainer.View>
              );
            }
          })
        },
        {
          label: trans('ar_invoice_no'),
          name: 'arInvoiceCodes',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: item => {
              UIUtils.popup.open({
                title: trans('ar_invoice_no'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {item?.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      const isCreditNote =
                        i?.invoiceType === InvoiceType.CREDIT_NOTE;

                      return (
                        <KContainer.View
                          key={`ar-invoice-list-popup-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={
                            isCreditNote
                              ? KColors.warning.normal
                              : KColors.primary.normal
                          }
                          marginT={marginT}
                        >
                          <KLabel.Text
                            typo="TextMdNormal"
                            color={
                              isCreditNote
                                ? KColors.warning.normal
                                : KColors.primary.normal
                            }
                          >
                            {i?.code}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx, rowIdx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              const item = data?.[rowIdx];
              const isCreditNote = i?.invoiceType === InvoiceType.CREDIT_NOTE;

              return (
                <KContainer.View
                  key={`${item?.id}-ar-invoice-list-${idx}`}
                  minW={160}
                  height={20}
                  marginL={marginL}
                  dp="flex"
                  center
                  background={isCreditNote ? undefined : '#ACDCD7'}
                  br="2x"
                  brW={1}
                  brC={
                    isCreditNote
                      ? KColors.warning.normal
                      : KColors.primary.normal
                  }
                >
                  <KLabel.Text
                    color={
                      isCreditNote
                        ? KColors.warning.normal
                        : KColors.primary.normal
                    }
                  >
                    {i?.code}
                  </KLabel.Text>
                </KContainer.View>
              );
            }
          })
        },
        {
          label: trans('payment_status'),
          name: 'apPaymentStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 110,
            css: { textAlign: 'center' },
            mapLabelData: mappedPaymentStatusData()
          })
        },
        {
          label: trans('collect_status'),
          name: 'arPaymentStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 110,
            css: { textAlign: 'center' },
            mapLabelData: mappedPaymentStatusData()
          })
        },
        {
          label: trans('cost_sub_total'),
          name: 'apSubTotalFormat'
        },
        {
          label: trans('cost_tax'),
          name: 'apTaxAmountFormat'
        },
        {
          label: trans('cost_total'),
          name: 'apTotalAmountFormat'
        },
        {
          label: trans('cost_paid'),
          name: 'apPaidFormat',
          options: TableUtils.options.toggle(!isCs)
        },
        {
          label: trans('cost_balance'),
          name: 'apBalanceFormat',
          options: TableUtils.options.toggle(!isCs)
        },
        {
          label: trans('revenue_sub_total'),
          name: 'arSubTotalFormat'
        },
        {
          label: trans('revenue_tax'),
          name: 'arTaxAmountFormat'
        },
        {
          label: trans('revenue_total'),
          name: 'arTotalAmountFormat'
        },
        {
          label: trans('revenue_collect'),
          name: 'arCollectFormat',
          options: TableUtils.options.toggle(!isCs)
        },
        {
          label: trans('revenue_balance'),
          name: 'arBalanceFormat',
          options: TableUtils.options.toggle(!isCs)
        },
        {
          label: trans('profit_before_tax'),
          name: 'profitBeforeTaxFormat'
        },
        {
          label: trans('profit_after_tax'),
          name: 'profitAfterTaxFormat'
        }
      ].map(i => ({
        ...i,
        options: {
          ...i.options,
          sort: i.name === 'chargeCode' ? true : false
        }
      }));
    },
    [isCs]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: CHARGE_CODE_INCLUDE_FIELDS,
      ..._otherParams
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(_otherParams)]);

  const mappedFields = CHARGE_CODE_MAPPED_FIELDS;

  const name = isCs
    ? WebTable.CONSOL_CHARGE_CODE_LIST
    : isSm
    ? WebTable.SHIPMENT_CHARGE_CODE_LIST
    : WebTable.SERVICE_CHARGE_CODE_LIST;

  const apiURL = useMemo(
    () =>
      isCs
        ? ENDPOINTS.accountingChargeCodeList()
        : isSm
        ? ENDPOINTS.billingChargeCodeList()
        : ENDPOINTS.serviceChargeCodeList(),
    [isCs, isSm]
  );

  const table = useDataTable({
    name,
    columnsFunc,
    apiURL,
    mappedFields,
    otherParams,
    showFilter: false,
    ..._otherParams,
    otherOptions: {
      tableBodyMaxHeight: '50vh',
      selectableRows: 'none',
      // sort: false,
      ..._otherParams?.otherOptions
    }
  });

  useGlobalTable(table);

  return { table };
};

const useIsOverdue = (dueDate: any, status: any) => {
  const isBefore = checkDueDate(dueDate);

  const isOverdue =
    isBefore &&
    [APInvoiceStatus.APPROVED, ARInvoiceStatus.ISSUED].includes(status);

  return isOverdue;
};

export const useInvoiceStatusTSX = (item?: any, arApType = ArApType.Ar) => {
  if (!item?.id) {
    return null;
  }

  const {
    apInvoiceStatus,
    arInvoiceStatus,
    paymentStatus,
    vatInvoiceStatus,
    paymentRequestStatus
  } = item || {};

  const invoiceStatus = (
    arApType === ArApType.Ap ? apInvoiceStatus : arInvoiceStatus
  )?.code;

  const vatStatus = vatInvoiceStatus; // ar

  const requestStatus = paymentRequestStatus; // ap

  const commonChip = (data: any) => {
    if (!data) return null;

    return (
      <KChip
        textTransform="uppercase"
        typo="TextXMdMedium"
        padding="0.5rem"
        marginL="1.5rem"
        {...data}
      />
    );
  };

  const isOverdue = useIsOverdue(item.dueDate, invoiceStatus);

  const invoiceStatusData = (mappedInvoiceStatus() as any)[
    isOverdue ? 'OVERDUE' : invoiceStatus
  ];

  const vatStatusData = mappedVATStatus()[vatStatus];

  const requestStatusData = mappedPaymentRequestStatus()[requestStatus];

  const paymentStatusData = (mappedPaymentStatusData() as any)[paymentStatus];

  const totalAmountData = {
    color: KColors.palette.blue.w700,
    background: KColors.palette.blue.w50,
    brC: KColors.palette.blue.w50,
    label: `${trans('total')} (${
      item?.paymentCurrency?.code
    }) : ${Prototype.number.formatNumber(item?.totalAmount)}`
  };

  // const isHiddenPayment =
  //   invoiceStatus === ARInvoiceStatus.COMPLETE ||
  //   invoiceStatus === ARInvoiceStatus.CANCEL;

  const isShowPayment =
    invoiceStatus === ARInvoiceStatus.ISSUED ||
    invoiceStatus === APInvoiceStatus.APPROVED;

  return (
    <KContainer.View row alignItems>
      {commonChip(totalAmountData)}

      {commonChip(invoiceStatusData)}

      {isShowPayment && commonChip(paymentStatusData)}

      {commonChip(vatStatusData)}

      {commonChip(requestStatusData)}
    </KContainer.View>
  );
};
