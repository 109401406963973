import { IModifyClientSalePerson, ICommissionParams } from '@request-dto';

import {
  WebTable,
  QUERY_KEYS,
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX
} from '@constants';
import APIManager from '@services';
import { sortSelected } from 'utils/common';

import { useUser } from '../auth';
import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export interface IItemSalePerson {
  staffId: number | null;
  client?: string;
  salePerson?: string;
  excludeClientSalePersonId?: number;
  title: string;
}

export interface IParamsChargeCode {
  chargeCodeSubCategoryId?: number;
  excludeClientSalePersonId?: number;
}

export const useCUDClientSalePerson = (
  id: string,
  setItem?: (v?: any) => void
) => {
  return useCUDMutationEnhancer<IModifyClientSalePerson>({
    endPoint: ENDPOINTS.restrictedClient(':id/sale-person', { id }),
    webTable: WebTable.CLIENT_SALE_PERSON,
    onSuccess: () => {
      setItem?.(null);
    }
  });
};

export const useFetchSalePerson = (clientId?: string, arr?: number[]) => {
  const { branch } = useUser();

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.salePerson, branch?.id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.branch(':id/staff-branch', { id: branch?.id }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: ['department', 'jobTitle', 'staff'],
          excludeClientId: clientId
        }
      });

      if (!arr) return res.data?.data;

      const convertId = (res.data?.data || []).map((x: any) => {
        return { ...x, id: x?.staffId };
      });

      const arrSorted = sortSelected(convertId, arr);

      return arrSorted;
    }
  });
};

export const useFetchChargeCode = (body: IParamsChargeCode) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.chargeCodeList, JSON.stringify(body)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.chargeCode(),
        body: { ...body, includeFields: ['chargeCodeUnit'] }
      });

      return res.data?.data ?? [];
    }
  });
};

export const useFetchChargeCodeDetails = (chargeCodeId: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.chargeCodeDetails, chargeCodeId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.chargeCode(`${chargeCodeId}`)
      });

      return res.data;
    },
    enabled: !!chargeCodeId
  });
};

export const useCUDClientSaleCommission = (id?: string | number) => {
  return useCUDMutationEnhancer<ICommissionParams>({
    endPoint: ENDPOINTS.restrictedClient(':id/sale-commission', { id }),
    webTable: WebTable.CLIENT_SALE_COMMISSION
  });
};
