import { useUser } from './auth';
import { useFetchBranchSettings } from './ssLibrary/setting';

export * from './core';
export * from './auth';
export * from './ui';
export * from './role';
export * from './staff';
export * from './ssLibrary';
export * from './client';
export * from './consol';
export * from './library';
export * from './note';
export * from './company';
export * from './edoc';
export * from './email';
export * from './alert';
export * from './accounting';
export * from './service';

export const useFetchSettings = (onSuccess?: (d: any) => void) => {
  const { branch } = useUser();

  return useFetchBranchSettings(branch?.id, onSuccess);
};
