import Box from '@mui/material/Box';
import { CSSObject, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { memo, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { ENDPOINTS, EventName, updateAuthorizationToken } from '@constants';
import { drawerWidth, drawerWidthMini } from 'assets/theme/components/sidebar';
import { Portal } from 'containers/Portal';
import { usePreferences } from 'context';
import AppEvent from 'core/event';
import AppEventSource from 'core/eventSource';
import { useAppVersionCheck, useSignOut, useUser } from 'hooks';
import { useMount } from 'uikit-common';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

import Navbar from './Navbar';

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `${drawerWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `calc(100% - ${drawerWidthMini}px)`,
  marginLeft: `${drawerWidthMini}px`
});

const AdminLayout = () => {
  const theme = useTheme();

  const signOut = useSignOut();

  const prefs = usePreferences();
  const { state } = prefs;

  const { user } = useUser();
  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  useAppVersionCheck();

  useMount(() => {
    AppEvent.subscribe(EventName.LOGOUT, () => {
      signOut.mutate();
    });
  });

  useEffect(() => {
    if (!user) {
      updateAuthorizationToken('');
      StorageEnhance.delete(STORAGE_KEYS.user);

      navigate('/auth/sign-in');
    } else if (user) {
      StorageEnhance.set(STORAGE_KEYS.user, user);
      updateAuthorizationToken(user.token);
    }
  }, [navigate, user]);

  useEffect(() => {
    if (!!user) {
      const subscribeURL = ENDPOINTS.notification('subscribe-count-unread');

      AppEventSource.init(subscribeURL);

      AppEventSource.onMessage(subscribeURL, d => {
        if (d.type === EventName.COUNT_UNREAD) {
          setCount(d.data);
        }
      });
    }

    return () => {
      AppEventSource.disconnect();
    };
  }, [user]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Navbar count={count} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            ...(!state.miniDrawer
              ? { ...openedMixin(theme) }
              : { ...closedMixin(theme) }),
            paddingTop: '1px'
          }}
        >
          <Toolbar />

          <Outlet />
        </Box>
      </Box>

      <Portal />
    </>
  );
};

export default memo(AdminLayout);
