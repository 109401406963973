import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import Currency from './Currency';
import Dropzone from './Dropzone';
import Editor from './Editor';
import Password from './Password';
import Search from './Search';
import TextEditor from './TextEditor';
import TextField from './TextField';

export default class KInput {
  static TextField = TextField;

  static Password = Password;

  static Autocomplete = Autocomplete;

  static Search = Search;

  static Currency = Currency;

  static Dropzone = Dropzone;

  static Avatar = Avatar;

  static TextEditor = TextEditor;

  static Editor = Editor;
}
