import React from 'react';
import { Link } from 'react-router-dom';

import { KColors, KLabel, KTextProps } from 'uikit';

interface WithLinkProps {
  to?: string;
}

const withLink = <T extends WithLinkProps>(Component: any) => {
  return (props: T) => {
    const { to, ...rest } = props;

    if (to) {
      return (
        <Link to={to} style={{ cursor: 'pointer' }}>
          <Component {...(rest as T)} color={KColors.primary.normal} />
        </Link>
      );
    }

    return <Component {...(rest as T)} />;
  };
};

export const WrappedLink = withLink<KTextProps>(KLabel.Text);

export default withLink;
