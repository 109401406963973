import { IUseAgTableResult } from '@hooks-dto';
import React, { useCallback } from 'react';

import { ArApType } from '@constants';
import { Prototype } from 'core';
import { wrapWithAlertCb } from 'hofs';
import { useAppEvent } from 'hooks/core/useAppEvent';
import trans from 'translation';
import { UIUtils } from 'utils';

import FromWip, { IProps as IFromWipProps } from './FromWip';
import PrintVatInvoice from './PrintVatInvoice';

export type IPrintVatInvoice = {
  invoiceId: string;
  chargeCodes: any[];
  currency: any;
  code: string;
  hasDraftVat?: boolean;
  screen: 'statement-invoice' | 'ar-invoice';
};
export interface IArWipProps {
  mParams?: any;
}

export interface IInvoiceWipProps extends IArWipProps {
  arApType?: ArApType;
}

export const onFromWip = (p: IFromWipProps) => {
  UIUtils.popup.open({
    title: trans('from_wip'),
    maxWidth: false,
    content: () => {
      return <FromWip {...p} />;
    }
  });
};

interface IOnAfterValueChangedParams {
  subTotal?: number;
  exRate?: number;
  taxAmount?: number;
  currency?: any;
}

export const onAfterValueChanged = ({
  exRate,
  subTotal: _subTotal,
  taxAmount: _taxAmount,
  currency
}: IOnAfterValueChangedParams) => {
  const _isVND = currency?.code === 'VND';

  const subTotal = Prototype.number.roundVND(_isVND)(
    Number(_subTotal) * Number(exRate) || 0
  );
  const taxAmount = Prototype.number.roundVND(_isVND)(
    Number(_taxAmount) * Number(exRate) || 0
  );
  const total = Prototype.number.roundVND(_isVND)(subTotal + taxAmount);

  return { subTotal, taxAmount, total };
};

export const useApInvoiceWipEvent = (
  table?: IUseAgTableResult<any>,
  currency?: any
) => {
  const cbCOA = useCallback(
    ({ detail: d }: { detail: any }) => {
      if (d && Array.isArray(d) && d.length > 1) {
        const costAccount = d[0]?.costAccount;
        const newData = d.map((i: any) => ({
          ...i,
          costAccount: costAccount ?? null
        }));

        wrapWithAlertCb({
          msg: trans('Do you want to apply first Cost Account to all rows?'),
          cb: () => {
            table?.gridRef.current?.api.forEachNodeAfterFilterAndSort(
              (node, idx) => {
                if (idx > 0) {
                  node.setData(newData[idx]);
                  table?.gridRef.current?.api.applyTransaction(newData[idx]);
                }
              }
            );
          }
        });
      }
    },
    [table?.gridRef]
  );

  useAppEvent('AP_INVOICE_WIP_APPLY_ALL_COA', cbCOA as any);

  const cbExRate = useCallback(
    ({ detail: d }: { detail: any }) => {
      if (d && Array.isArray(d) && d.length > 1) {
        const exRate = d[0]?.exchangeRate;

        const newData = d.map((i: any) => {
          const { subTotal, taxAmount, total } = onAfterValueChanged({
            exRate,
            currency,
            subTotal: i.subTotal,
            taxAmount: i.taxAmount
          });

          return {
            ...i,
            exchangeRate: exRate,
            paymentSubTotal: subTotal,
            paymentTaxAmount: taxAmount,
            paymentTotalAmount: total
          };
        });

        wrapWithAlertCb({
          msg: trans('Do you want to apply first Ex. Rate to all rows?'),
          cb: () => {
            table?.gridRef.current?.api.forEachNodeAfterFilterAndSort(
              (node, idx) => {
                if (idx > 0) {
                  node.setData(newData[idx]);
                  table?.gridRef.current?.api.applyTransaction(newData[idx]);
                }
              }
            );
          }
        });
      }
    },
    [currency, table?.gridRef]
  );

  useAppEvent('AP_INVOICE_WIP_APPLY_ALL_EX_RATE', cbExRate as any);
};

export const useArInvoiceWipEvent = (
  table?: IUseAgTableResult<any>,
  currency?: any
) => {
  const cbCOA = useCallback(
    ({ detail: d }: { detail: any }) => {
      if (d && Array.isArray(d) && d.length > 1) {
        const revenueAccount = d[0]?.revenueAccount;
        const newData = d.map((i: any) => ({
          ...i,
          revenueAccount: revenueAccount ?? null
        }));

        wrapWithAlertCb({
          msg: trans('Do you want to apply first Revenue Account to all rows?'),
          cb: () => {
            table?.gridRef.current?.api.forEachNodeAfterFilterAndSort(
              (node, idx) => {
                if (idx > 0) {
                  node.setData(newData[idx]);
                  table?.gridRef.current?.api.applyTransaction(newData[idx]);
                }
              }
            );
          }
        });
      }
    },
    [table?.gridRef]
  );

  useAppEvent('AR_INVOICE_WIP_APPLY_ALL_COA', cbCOA as any);

  const cbExRate = useCallback(
    ({ detail: d }: { detail: any }) => {
      if (d && Array.isArray(d) && d.length > 1) {
        const chargeableExRate = d[0]?.chargeableExchangeRate;
        const newData = d.map((i: any) => {
          const { subTotal, taxAmount, total } = onAfterValueChanged({
            exRate: chargeableExRate,
            currency,
            subTotal: i.subTotal,
            taxAmount: i.taxAmount
          });

          return {
            ...i,
            exchangeRate: d[0]?.exchangeRate,
            uplift: d[0]?.uplift,
            chargeableExchangeRate: chargeableExRate,
            paymentSubTotal: subTotal,
            paymentTaxAmount: taxAmount,
            paymentTotalAmount: total
          };
        });

        wrapWithAlertCb({
          msg: trans(
            'Do you want to apply first Chargeable Ex. Rate to all rows?'
          ),
          cb: () => {
            table?.gridRef.current?.api.forEachNodeAfterFilterAndSort(
              (node, idx) => {
                if (idx > 0) {
                  node.setData(newData[idx]);
                  table?.gridRef.current?.api.applyTransaction(newData[idx]);
                }
              }
            );
          }
        });
      }
    },
    [currency, table?.gridRef]
  );

  useAppEvent('AR_INVOICE_WIP_APPLY_ALL_EX_RATE', cbExRate as any);
};

export const onPrintVatInvoice = (p: IPrintVatInvoice) => {
  UIUtils.popup.open({
    title: trans('print_draft_vat_invoice'),
    maxWidth: false,
    content: () => {
      return <PrintVatInvoice {...p} />;
    }
  });
};
