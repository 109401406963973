import { CustomCellEditorProps } from 'ag-grid-react';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';

import { ENDPOINTS } from '@constants';
import { onShowClient } from 'hooks';
import { KInput } from 'uikit';

export enum ApiEditorKind {
  CLIENT = 'client',
  CHARGE_CODE = 'charge_code',
  CURRENCY = 'currency',
  UNIT = 'unit',
  CONTAINER_SIZE = 'container_size',
  TAX_TYPE = 'tax_type',
  PERMISSION_STATUS = 'permission_status',
  CHART_OF_ACCOUNT = 'chart_of_account'
}

interface IApiEditorParams {
  kind: ApiEditorKind;
  apiParams?: any;
  addNewKey?: string;
  getOptionLabel?: (o: any) => string;
  onChange?: (v: any, onValueChange: any) => void;
}

const INPUT_KIND: Record<
  ApiEditorKind,
  {
    apiURL?: string;
    hasEdit?: boolean;
    hasAddNew?: boolean;
    onEdit?: any;
    onAddNew?: any;
    addNewKey?: string;
    apiParams?: any;
    isInitFetch?: boolean;
    getOptionLabel?: (o: any) => string;
    options?: any[];
  }
> = {
  [ApiEditorKind.CLIENT]: {
    apiURL: ENDPOINTS.client(),
    hasEdit: true,
    onEdit: onShowClient,
    hasAddNew: true,
    onAddNew: onShowClient,
    isInitFetch: false
  },
  [ApiEditorKind.CHARGE_CODE]: {
    apiURL: ENDPOINTS.chargeCode(),
    getOptionLabel: (o: any) => (o?.code ? [o.code, o.name].join(' - ') : ''),
    isInitFetch: false
  },
  [ApiEditorKind.CURRENCY]: {
    apiURL: ENDPOINTS.currency(),
    getOptionLabel: (o: any) => o?.code ?? ''
  },
  [ApiEditorKind.UNIT]: {
    apiURL: ENDPOINTS.chargeCodeUnit(),
    getOptionLabel: (o: any) => o?.code ?? ''
  },
  [ApiEditorKind.CONTAINER_SIZE]: {
    apiURL: ENDPOINTS.containerTypeSize(),
    getOptionLabel: (o: any) => o?.code ?? ''
  },
  [ApiEditorKind.TAX_TYPE]: {
    apiURL: ENDPOINTS.taxType(),
    getOptionLabel: (o: any) => o?.code ?? ''
  },
  [ApiEditorKind.CHART_OF_ACCOUNT]: {
    apiURL: ENDPOINTS.chartOfAccount(),
    getOptionLabel: (o: any) => o?.displayName ?? ''
  },
  [ApiEditorKind.PERMISSION_STATUS]: {
    // apiURL: ENDPOINTS.taxType(),
  }
};

const ApiEditor = forwardRef<any, CustomCellEditorProps<any>>((props, ref) => {
  const { colDef, value, onValueChange, stopEditing, node } = props;

  const { kind, apiParams, onChange, ...rest }: IApiEditorParams =
    colDef.cellEditorParams || {};

  const inputParams = INPUT_KIND[kind];

  const mOptions = useMemo(() => {
    if (kind === ApiEditorKind.PERMISSION_STATUS) {
      const opts = node.data?.extraStatus ?? [];
      return {
        options: opts,
        disabled: !node.data.isChecked || opts.length === 0
      };
    }
    return undefined;
  }, [kind, node]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [returnOption, setReturnOption] = useState<any>(undefined);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
    getValue() {
      return props.value; // Directly using the prop value for simplicity
    },
    isCancelAfterEnd() {
      return false;
    }
  }));

  const _onChange = useCallback(
    (v: any) => {
      if (kind === ApiEditorKind.TAX_TYPE) {
        onValueChange(
          v ? { ...v, rate: (v.defaultRate ?? 0) / 100 } : undefined
        );
      } else if (kind === ApiEditorKind.CHARGE_CODE) {
        if (onChange) {
          onChange(v, onValueChange);
        } else {
          onValueChange(v);
        }
      } else {
        onValueChange(v);
      }
    },
    [kind, onChange, onValueChange]
  );

  const onBlur = useCallback(() => {
    stopEditing();
  }, [stopEditing]);

  const onHighlightChange = useCallback((_: any, option: any) => {
    setReturnOption(option);
  }, []);

  useEffect(() => {
    // Automatically focus the input when the component mounts
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        _onChange(returnOption);
      }
    });
  }, [_onChange, returnOption]);

  return (
    <KInput.Autocomplete
      ref={inputRef}
      fullWidth
      value={value}
      onChange={_onChange}
      onBlur={onBlur}
      // autoFocus
      {...mOptions}
      {...inputParams}
      {...rest}
      apiParams={{
        ...inputParams?.apiParams,
        ...apiParams
      }}
      onHighlightChange={onHighlightChange}
    />
  );
});

export default ApiEditor;
