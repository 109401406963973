import { BaseResponse } from '@dto';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  tableRef,
  WebTable
} from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';
import { wrapWithAlert } from 'hofs';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';
import { formatSummary } from 'utils/common';

import {
  useQueryEnhancer,
  usePageCUDMutationEnhancer,
  useMutationEnhancer
} from '../core';

export const useFetchShipment = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipment, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(':id', { id })
      });

      return Prototype.response.normalizeData(res.data, 'shipmentNumber');
    },
    enabled: !!id
  });
};

export const useCUDShipment = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.shipment(),
    webTable: WebTable.CONSOL_SHIPMENT,
    queryKey: QUERY_KEYS.shipment,
    dependentWebTable: [WebTable.CONSOL_SHIPMENT, WebTable.AIR_CONSOL_SHIPMENT]
  });
};

export const useCUDConsolShipment = ({
  shipmentId
}: {
  shipmentId?: string | number;
}) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.consol('shipment/:shipmentId', {
      shipmentId
    })
  });
};

const MUTATION_MAPPED_DATA = {
  queryKey: QUERY_KEYS.shipment,
  webTables: [WebTable.CONSOL_SHIPMENT, WebTable.AIR_CONSOL_SHIPMENT]
};

interface IUseActionMutationEnhancerParams {
  suffix?: string;
  method?: any;
  cb?: (d: any) => void;
  returnKey?: keyof BaseResponse<any>;
}

const useActionMutationEnhancer = (d: IUseActionMutationEnhancerParams) => {
  const { suffix, method = 'POST', cb, returnKey } = d;

  const queryClient = useQueryClient();

  const mData = MUTATION_MAPPED_DATA;

  return useMutationEnhancer<boolean | undefined>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(`${suffix}`, data),
        method: method || 'POST',
        showToast: true,
        body: data
      });

      return returnKey ? res[returnKey] : res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        mData.webTables?.forEach(i => {
          tableRef?.[i]?.init?.();
        });
        if (mData.queryKey) {
          queryClient.invalidateQueries([mData.queryKey], {
            type: 'active'
          });
        }
        cb?.(data);
      }
    }
  });
};

export const useCUDActionShipment = (
  id?: string | number,
  isOcean?: boolean
) => {
  const navigate = useNavigate();

  const mutationEnhancer = (d: any) => useActionMutationEnhancer(d);

  const [duplicateMutation, detachMutation, cancelMutation] = [
    {
      suffix: 'duplicate',
      returnKey: 'data',
      cb: (d: any[]) => {
        if (!!id && d?.[0]?.id) {
          navigate(`../${d[0].id}`, { replace: true });
        }
      }
    },
    {
      suffix: 'detach',
      cb: (d: boolean) => {
        if (!!id && d) {
          navigate(
            `/admin/${
              isOcean ? 'ocean-freight' : 'air-freight'
            }/shipment/${id}`,
            { replace: true }
          );
        }
      }
    },
    {
      suffix: ':id/cancel-booking',
      method: 'PUT'
    }
  ].map(i => mutationEnhancer(i));

  return {
    duplicateMutation,
    detachMutation,
    cancelMutation,
    isLoading:
      duplicateMutation.isLoading ||
      detachMutation.isLoading ||
      cancelMutation.isLoading
  };
};

export const useFetchShipmentStatistics = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipmentSummary, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(':id/summary', { id })
      });

      let val = formatSummary(res.data);

      return val || null;
    },
    enabled: !!id
  });
};

type IShipmentAction =
  | 'NEW'
  | 'DUPLICATE'
  | 'DETACH'
  | 'CANCEL'
  | 'CREATE_CONSOL';

interface IUseShipmentActions {
  id?: string | number;
  isOcean?: boolean;
  isWithoutConsol?: boolean;
  isCancelled?: boolean;
  isJobOrderId?: boolean;
}

const MAPPED_SHIPMENT_ACTION: Record<IShipmentAction, any> = {
  ['DUPLICATE']: {
    title: 'duplicate',
    msgKey: 'message.duplicate_shipment',
    icon: 'ContentCopyOutlined'
  },
  ['DETACH']: {
    title: 'detach',
    msgKey: 'message.detach_shipment',
    icon: 'ContentCopyOutlined'
  },
  ['CANCEL']: {
    title: 'cancel',
    msgKey: 'message.cancel_shipment',
    icon: 'CancelOutlined',
    color: KColors.secondary.normal
  },
  ['NEW']: {
    title: 'new',
    icon: 'AddBox'
  },
  ['CREATE_CONSOL']: {
    title: 'Create Consol',
    icon: 'AddBox'
  }
};

export const useShipmentActions = (options?: IUseShipmentActions) => {
  const {
    id,
    isOcean = true,
    isWithoutConsol = false,
    isCancelled = false,
    isJobOrderId = false
  } = options || {};

  const navigate = useNavigate();

  const {
    duplicateMutation: { mutate: duplicateMutation },
    detachMutation: { mutate: detachMutation },
    cancelMutation: { mutate: cancelMutation },
    isLoading
  } = useCUDActionShipment(id, isOcean);

  const {
    createMutation: { mutate: createConsolShipment },
    isLoading: consolShipmentLoading
  } = useCUDConsolShipment({ shipmentId: id });

  const setAction = useCallback(
    (action: IShipmentAction) => {
      let onPress: (d?: any) => void;

      switch (action) {
        case 'DUPLICATE':
          onPress = (_ids?: number[]) =>
            duplicateMutation({ ids: _ids ?? [id] });
          break;

        case 'DETACH':
          onPress = (_ids?: number[]) => detachMutation({ ids: _ids ?? [id] });
          break;

        case 'CANCEL':
          onPress = (_id?: number) => cancelMutation({ id: _id ?? id });
          break;

        case 'CANCEL':
          onPress = (_id?: number) => cancelMutation({ id: _id ?? id });
          break;
        case 'CREATE_CONSOL':
          onPress = () => createConsolShipment();
          break;
        default:
          onPress = () => navigate('../new');
          break;
      }

      const {
        title,
        msgKey,
        icon,
        color = KColors.primary.normal
      } = MAPPED_SHIPMENT_ACTION[action] || {};

      return {
        key: action,
        title: trans(title),
        icon: {
          name: icon,
          color: color
        },
        onPress: msgKey
          ? wrapWithAlert({ cb: onPress, msg: trans(msgKey, { count: 1 }) })
          : onPress
      };
    },
    [
      cancelMutation,
      createConsolShipment,
      detachMutation,
      duplicateMutation,
      id,
      navigate
    ]
  );

  const actionKeys = useMemo(() => {
    const arr: IShipmentAction[] = [];

    if (!isWithoutConsol) {
      arr.push('NEW', 'DUPLICATE', 'DETACH');
    }

    if (!isCancelled) {
      arr.push('CANCEL');
    }

    if (isJobOrderId && !isCancelled) {
      arr.unshift('CREATE_CONSOL');
    }
    return arr;
  }, [isCancelled, isWithoutConsol, isJobOrderId]);

  const actions = useMemo(() => {
    return actionKeys.map(i => setAction(i));
  }, [actionKeys, setAction]);

  return { actions, isLoading: consolShipmentLoading || isLoading };
};

export const useFetchAttachableShipmentList = (consolId?: number | string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.attachShipment, consolId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment('list-for-attach'),
        body: {
          consolId,
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!consolId
  });
};

export const useAttachShipmentList = () => {
  return useMutationEnhancer<boolean | undefined, any>({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment('attach'),
        method: 'POST',
        body: mParams,
        showToast: true
      });

      return res.success;
    },
    onSuccess: d => {
      if (d) {
        [WebTable.CONSOL_SHIPMENT, WebTable.AIR_CONSOL_SHIPMENT].map(i => {
          tableRef[i]?.init();
        });

        UIUtils.popup.dismiss();
      }
    }
  });
};
