import { useNavigate } from 'react-router-dom';

import {
  ArApType,
  SRMSource,
  ENDPOINTS,
  QUERY_KEYS,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchSpendReceiveMoney = ({
  source,
  id
}: {
  source: SRMSource;
  id?: string;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          source,
          path: ':id',
          query: { id }
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

const SOURCE_PARAMS = {
  [SRMSource.Spend]: {
    webTable: WebTable.SPEND_MONEY,
    queyKey: QUERY_KEYS.spendBankingPayment,
    arApType: ArApType.Ap
  },
  [SRMSource.Receive]: {
    webTable: WebTable.RECEIVE_MONEY,
    queyKey: QUERY_KEYS.receiveBankingPayment,
    arApType: ArApType.Ar
  }
};

export const useCUDBankingPayment = ({
  source,
  isFromWip
}: {
  source: SRMSource;
  isFromWip: boolean;
}) => {
  const mParams = SOURCE_PARAMS[source] || {};
  const endPoint = isFromWip
    ? ENDPOINTS.bankingPayment({
        source,
        path: '/from-wip'
      })
    : ENDPOINTS.bankingPayment({
        source
      });
  return usePageCUDMutationEnhancer({
    mutationKeys: [[mParams.queyKey], [QUERY_KEYS.spendReceiveMoney]],
    endPoint,
    webTable: mParams.webTable,
    queryKey: mParams.queyKey
  });
};

interface IUseSplitSpendReceiveMoney {
  id: string | number;
  firstDetailIds: number[];
}

export const useSplitSpendReceiveMoney = () => {
  const navigate = useNavigate();

  return useMutationEnhancer<any, IUseSplitSpendReceiveMoney>({
    mutationFn: async mParams => {
      const { id, ...rest } = mParams;
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          source: SRMSource.Spend,
          path: '/:id/split',
          query: { id }
        }),
        method: 'POST',
        body: rest
      });

      return res.data;
    },
    onSuccess: d => {
      if (d?.id) {
        tableRef[WebTable.SPEND_MONEY]?.init?.();
        UIUtils.popup.dismiss();
        navigate(window.location.pathname.replace(/\/\d+$/, `/${d.id}`));
      }
    }
  });
};
