import React, { memo, useState } from 'react';

import { NotificationStatus } from '@constants';
import { useUser } from 'hooks';
import {
  useCUDNotificationLog,
  useFetchNotificationLog
} from 'hooks/notification';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel, KLoading } from 'uikit';

import Item from './Item';

const primaryColor = KColors.primary.normal;

const styleBtn = {
  background: primaryColor,
  textColor: KColors.white
};

const PopperNotification = () => {
  const { account } = useUser();

  const [readStatus, setReadStatus] = useState<NotificationStatus | undefined>(
    undefined
  );

  const { data: notificationList = [], isLoading } = useFetchNotificationLog({
    receiveStaffId: account?.id,
    readStatus
  });

  const { mutate, isLoading: modifyIsLoading } = useCUDNotificationLog();

  return (
    <KContainer.View height={'100%'}>
      {(isLoading || modifyIsLoading) && <KLoading />}

      <KContainer.View row justifyContent="space-between">
        <KLabel.Text typo="TextLgMedium">{trans('notifications')}</KLabel.Text>

        <KButton.Text
          title={trans('mark_all_as_read')}
          onPress={() => {
            mutate({ type: 'read-all' });
          }}
        />
      </KContainer.View>

      <KContainer.View
        row
        justifyContent="space-between"
        brBW={1}
        brBC="#e0e0e0"
      >
        <KContainer.View marginV={'0.5rem'}>
          <KButton.Outline
            width={'7.5rem'}
            weight="medium"
            size="sm"
            marginR={'1rem'}
            title={trans('all')}
            onPress={() => {
              setReadStatus(undefined);
            }}
            {...(readStatus === undefined && styleBtn)}
          />
          <KButton.Outline
            width={'7.5rem'}
            size="sm"
            title={trans('un_read')}
            onPress={() => {
              setReadStatus(NotificationStatus.UNREAD);
            }}
            {...(readStatus === NotificationStatus.UNREAD && styleBtn)}
          />
        </KContainer.View>

        {/* <KContainer.View>
          <KLabel.Text color={primaryColor}>{trans('view_all')}</KLabel.Text>
        </KContainer.View> */}
      </KContainer.View>

      <KContainer.View overflowY="auto" height="calc(100% - 62px)">
        {notificationList.map(i => {
          return <Item key={i?.id} item={i} />;
        })}
      </KContainer.View>
    </KContainer.View>
  );
};
export default memo(PopperNotification);
