import { ColDef } from 'ag-grid-community';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle
} from 'react';

import { WebTable } from '@constants';
import AgTable from 'containers/AgTable';
import ApiEditor, { ApiEditorKind } from 'containers/WIP/components/ApiEditor';
import { IWipListInstance } from 'containers/WIP/helpers';
import { wrapWithAlertCb } from 'hofs';
import useAgTable from 'hooks/core/useAgTable';
import { useAppEvent } from 'hooks/core/useAppEvent';
import trans from 'translation';
import AgUtils from 'utils/ag';

export const colDefsStatementInvoices = ({
  isAr,
  hideCheckboxSelection
}: {
  isAr: boolean;
  hideCheckboxSelection?: boolean;
}): ColDef<any>[] => {
  return [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      pinned: 'left',
      filter: false,
      sortable: false,
      lockVisible: true,
      maxWidth: 51,
      colId: 'isChecked',
      hide: hideCheckboxSelection
    },
    {
      headerName: trans('charge_code'),
      field: 'chargeCode',
      type: 'code',
      pinned: 'left',
      lockVisible: true
    },
    {
      headerName: trans('description'),
      field: 'chargeCode.description',
      pinned: 'left',
      lockPinned: true,
      minWidth: 180
    },
    {
      headerName: trans(isAr ? 'revenue_account' : 'cost_account'),
      field: 'revenueAccount',
      editable: true,
      cellEditor: ApiEditor,
      cellEditorParams: {
        kind: ApiEditorKind.CHART_OF_ACCOUNT
      },
      type: 'nameDP',
      ...AgUtils.options.customHeader({
        required: true,
        btn: {
          event: 'STATEMENT_INVOICE_WIP_APPLY_ALL'
        }
      })
    },
    {
      headerName: trans('sub_total'),
      field: 'subTotal',
      type: 'numberAlignRight'
    },
    {
      headerName: trans('with_unit.tax'),
      field: 'taxType',
      type: 'tax'
    },
    {
      headerName: trans('tax_amount'),
      field: 'taxAmount',
      type: 'numberAlignRight'
    },
    {
      headerName: trans('total_amount'),
      field: 'totalAmount',
      type: 'numberAlignRight'
    }
  ];
};

const WipList = forwardRef<
  IWipListInstance,
  {
    details: any[];
    isAr: boolean;
  }
>(({ details, isAr }, ref) => {
  const table = useAgTable({
    name: WebTable.LOCAL_STATEMENT_INVOICE_WIP,
    data: details,
    colDefs: colDefsStatementInvoices({ isAr, hideCheckboxSelection: true }),
    onAddParams: {
      hidden: true
    }
  });

  const cb = useCallback(
    ({ detail: d }: { detail: any }) => {
      if (d && Array.isArray(d) && d.length > 1) {
        const revenueAccount = d[0]?.revenueAccount;
        const newData = d.map((i: any) => ({
          ...i,
          revenueAccount: revenueAccount ?? null
        }));

        wrapWithAlertCb({
          msg: trans(
            `Do you want to apply first ${
              isAr ? 'Revenue Account' : 'Cost Account'
            } to all rows?`
          ),
          cb: () => {
            table?.gridRef.current?.api.forEachNodeAfterFilterAndSort(
              (node, idx) => {
                if (idx > 0) {
                  node.setData(newData[idx]);
                }
              }
            );
          }
        });
      }
    },
    [isAr, table?.gridRef]
  );

  useAppEvent('STATEMENT_INVOICE_WIP_APPLY_ALL', cb as any);

  useImperativeHandle(ref, () => ({
    onGetData: table.onGetData
  }));

  return <AgTable {...table} showSearch singleClickEdit />;
});

export default memo(WipList);
